import TDSA from './pages/Home'
import AboutUs from './pages/AboutUs'
import DirectSelling from './pages/DirectSelling'
import Membership from './pages/Question'
import ContactUs from './pages/ContactUs'
import Weblinks from './pages/Weblinks'
import Enroll from './pages/Enroll'
import AboutDirectSelling from './pages/AboutDirectSelling'
import CodeAdministrator from './pages/CodeAdministrator'
import Complaint from './pages/Complaint'
import EthiceToolkit from './pages/EthiceToolkit'
import SiteMap from './pages/SiteMap'
import FAQs from './pages/FAQs'
import Leadership from './pages/Leadership'
import Admin from './pages/Admin'
import Login from './pages/Admin/Login'
import ComparisonTable from './pages/DirectSelling/comparisonTable'
import ChangePassword from './pages/Admin/ChangePassword'
import Image from './pages/Admin/Image'
import UploadPDF from './pages/Admin/UploadPDF'
import Page from './pages/Admin/Page'

export const routes = [
    {
        path: '/',
        element: <AboutUs />
    },
    {
        path: '/global-code-of-ethic',
        element: <TDSA />
    },
    {
        path: '/direct-selling',
        element: <DirectSelling />
    },
    {
        path: '/member',
        element: <Membership />
    },
    {
        path: '/contact-us',
        element: <ContactUs />
    },
    {
        path: '/web-links',
        element: <Weblinks />
    },
    {
        path: '/membership',
        element: <Enroll />
    },
    {
        path: '/about-direct-selling',
        element: <AboutDirectSelling />
    },
    {
        path: '/code-administrator',
        element: <CodeAdministrator />
    },
    {
        path: '/complaint',
        element: <Complaint />
    },
    {
        path: '/ethicstoolkit',
        element: <EthiceToolkit />
    },
    {
        path: '/site-map',
        element: <SiteMap />
    },
    {
        path: '/faq',
        element: <FAQs />
    },
    {
        path: '/former-presidents',
        element: <Leadership />
    },
    {
        path: '/admin',
        element: <Admin />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/pyramid-scheme',
        element: <ComparisonTable />
    },
    {
        path: '/admin/change-password',
        element: <ChangePassword />
    },
    {
        path: '/admin/image',
        element: <Image />
    },
    {
        path: '/admin/pdf',
        element: <UploadPDF />
    },
    {
        path: '/admin/page',
        element: <Page />
    }
]
