const ButtonFull = ({children, onClick}) => {
    return (
        <>
            <button className="bg-[#3375E5] hover:bg-[#174493] rounded-full py-2 px-7 text-[18px] text-white" onClick={onClick}>
                {children}
            </button>
        </>
    );
};
export default ButtonFull;
