import * as Styled from './styled'
import AboutDirect_2 from '../../assets/images/about_direct_learnmore_2.png'
import TDSA_Pyramid_EN from '../../assets/images/TDSA_Pyramid_EN.png'
import { LanguageContext } from '../../contexts/LanguageContext'
import { useContext } from 'react'

const AboutLeanmoreImageDesktop = [
    {
        image_th: AboutDirect_2,
        image_en: TDSA_Pyramid_EN
    }
]

const AboutDirectSelling = () => {
    const { language } = useContext(LanguageContext)

    return (
        <>
            <Styled.Wrapper>
                <div className="">
                    <div className="grid grid-cols-1 gap-16">
                        {AboutLeanmoreImageDesktop.map((item, index) => (
                            <div key={index}>
                                <img src={language === 'th' ? item.image_th : item.image_en} alt={'AboutDirectSelling'} width="100%" height="100%" />
                            </div>
                        ))}
                    </div>
                </div>
            </Styled.Wrapper>
        </>
    )
}
export default AboutDirectSelling
