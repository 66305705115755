import { Box, Typography } from '@mui/material'
import * as Styled from './styled'
import IconDirectSelling from '../../assets/svg/icon/icon_direct_selling.svg'
import { FilterImage } from 'utils/helper'
import IconSellingWhite from '../../assets/svg/icon/icon_selling_white.svg'
import { useContext } from 'react'
import { LanguageContext } from 'contexts/LanguageContext'
import ButtonFull from '../../components/common/ButtonFull'
import { Translate } from '../../utils/helper'

const Act = () => {
    const { language } = useContext(LanguageContext)

    const Direct_Sales_and_Direct_Marketing_2545_EN = FilterImage('pdf_direct_marketing_2545_en')
    const Direct_Sales_and_Direct_Marketing_2545_TH = FilterImage('pdf_direct_marketing_2545_th')

    const Direct_Sales_and_Direct_Marketing_2550_EN = FilterImage('pdf_direct_marketing_2550_en')
    const Direct_Sales_and_Direct_Marketing_2550_TH = FilterImage('pdf_direct_marketing_2550_th')

    const Direct_Sales_and_Direct_Marketing_2560_EN = FilterImage('pdf_direct_marketing_2560_en')
    const Direct_Sales_and_Direct_Marketing_2560_TH = FilterImage('pdf_direct_marketing_2560_th')

    const Direct_Sales_and_Direct_Marketing_2561_EN = FilterImage('pdf_collateral_2561_en')
    const Direct_Sales_and_Direct_Marketing_2561_TH = FilterImage('pdf_collateral_2561_th')

    const Considered_not_being_direct_Marketing_2561_EN = FilterImage('pdf_considered_2561_en')
    const Considered_not_being_direct_Marketing_2561_TH = FilterImage('pdf_considered_2561_th')

    const directSelling = [
        {
            icon: IconDirectSelling,
            keyName: 'direct_selling_act_card1',
            path: '',
            link_EN: Direct_Sales_and_Direct_Marketing_2545_EN,
            link_TH: Direct_Sales_and_Direct_Marketing_2545_TH,
            name_EN: 'Direct_Sales_and_Direct_Marketing_2545_EN',
            name_TH: 'Direct_Sales_and_Direct_Marketing_2545_TH'
        },
        {
            icon: IconDirectSelling,
            keyName: 'direct_selling_act_card2',
            path: '',
            link_EN: Direct_Sales_and_Direct_Marketing_2550_EN,
            link_TH: Direct_Sales_and_Direct_Marketing_2550_TH,
            name_EN: 'Direct_Sales_and_Direct_Marketing_2550_EN',
            name_TH: 'Direct_Sales_and_Direct_Marketing_2550_TH'
        },
        {
            icon: IconDirectSelling,
            keyName: 'direct_selling_act_card3',
            path: '',
            link_EN: Direct_Sales_and_Direct_Marketing_2560_EN,
            link_TH: Direct_Sales_and_Direct_Marketing_2560_TH,
            name_EN: 'Direct_Sales_and_Direct_Marketing_2560_EN',
            name_TH: 'Direct_Sales_and_Direct_Marketing_2560_TH'
        },
        {
            icon: IconDirectSelling,
            keyName: 'direct_selling_act_card4',
            path: '',
            link_EN: Direct_Sales_and_Direct_Marketing_2561_EN,
            link_TH: Direct_Sales_and_Direct_Marketing_2561_TH,
            name_EN: 'Direct_Sales_and_Direct_Marketing_2561_EN',
            name_TH: 'Direct_Sales_and_Direct_Marketing_2561_TH'
        },
        {
            icon: IconDirectSelling,
            keyName: 'direct_selling_act_card5',
            path: '',
            link_EN: Considered_not_being_direct_Marketing_2561_EN,
            link_TH: Considered_not_being_direct_Marketing_2561_TH,
            name_EN: 'Considered_not_being_direct_Marketing_2561_EN',
            name_TH: 'Considered_not_being_direct_Marketing_2561_TH'
        }
    ]

    return (
        <Styled.BackgroundWrapper>
            <Box className="container mx-auto px-8 md:px-7 text-center">
                <div className="flex justify-center mb-6">
                    <img src={IconSellingWhite} alt={IconSellingWhite} />
                </div>
                <Box mb="15px">
                    <Typography variant="title" color={'#ffffff'}>
                        {Translate('direct_selling_act_title2')}
                    </Typography>
                </Box>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 mt-12">
                    {directSelling.map((item, index) => (
                        <div className="bg-white rounded" key={index}>
                            <div className="py-5 flex justify-center">
                                <img src={item.icon} alt={item.icon + index} />
                            </div>
                            <div className="bg-[#E8F0FE] h-[160px] text-[18px] text-[#384250]">
                                <div className="flex items-center h-full px-5">{Translate(item.keyName)}</div>
                            </div>
                            <div className="py-8">
                                <a href={language === 'th' ? item.link_TH : item.link_EN} alt={language === 'th' ? item.name_TH : item.name_EN} target="_blank" rel="noopener noreferrer">
                                    <ButtonFull>{Translate('click')}</ButtonFull>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </Box>
        </Styled.BackgroundWrapper>
    )
}

export default Act
