import * as Styled from './styled'
import IconComplaint from '../../assets/svg/icon/complaint.svg'
import map from '../../assets/svg/icon/map.svg'
import mail from '../../assets/svg/icon/mail.svg'
import { Translate } from '../../utils/helper'

const Complaint = () => {
    return (
        <div className="bg-white">
            <Styled.BannerWrapper />

            <div className="container mx-auto px-7 py-10 md:py-20 text-center">
                <div className="flex justify-center mb-6">
                    <img src={IconComplaint} alt={IconComplaint} />
                </div>

                <div
                    className="text-[48px] mb-4 sm:mb-6 px-10 font-medium"
                    dangerouslySetInnerHTML={{
                        __html: Translate('complaint')
                    }}
                />

                <div className="text-[24px] text-[#384250] mb-[60px]">{Translate('complaint_title')}</div>
                <div className="text-[24px] text-[#384250] text-left">
                    <Styled.Detail
                        dangerouslySetInnerHTML={{
                            __html: Translate('complaint_detail_1')
                        }}
                    />
                </div>
            </div>
            <Styled.BackgroundWrapper className="px-6">
                <div className="bg-white rounded px-5 sm:px-10 py-10 sm:w-[440px]">
                    <div className="text-[32px] sm:text-[36px] mb-4">{Translate('complaint_contact_title')}</div>

                    <div className="flex mb-3">
                        <div className="w-8 pt-1">
                            <img src={map} alt={map} />
                        </div>
                        <div
                            className="ml-1 w-[70%] text-[18px] text-[#384250] whitespace-nowrap"
                            dangerouslySetInnerHTML={{
                                __html: Translate('tdsa_address')
                            }}
                        />
                    </div>
                    <div className="flex mb-3">
                        <div className="w-8 pt-1">
                            <img src={mail} alt={mail} />
                        </div>
                        <a href={`mailto:${Translate('tdsa_email')}`} className="ml-1 text-[18px] text-[#3375E5]">
                            {Translate('tdsa_email')}
                        </a>
                    </div>
                    <div className="text-[32px] sm:text-[36px] mb-4">{Translate('complaint_executive')}</div>
                    <div className="flex">
                        <div className="w-8 pt-1">
                            <img src={mail} alt={mail} />
                        </div>
                        <a href={`mailto:${Translate('code_admin_email')}`} className="ml-1 text-[18px] text-[#3375E5]">
                            {Translate('code_admin_email')}
                        </a>
                    </div>
                </div>
            </Styled.BackgroundWrapper>
        </div>
    )
}
export default Complaint
