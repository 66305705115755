import { TemplateConfigs } from 'templates/TemplateConfigs'
import { Translate } from 'utils/helper'
import { Box, ThemeProvider, Typography, Stack } from '@mui/material'
import AboutUsImage from '../../assets/images/about_us_img.png'
import * as Styled from './styled'

const Policy = () => {
    const Wrapper = {
        margin: '0 auto',
        padding: '80px 28px',

        '@media (max-width: 767px)': {
            padding: '40px 32px'
        }
    }

    const BoxTitle = {
        border: '15px solid #97BFF6',
        borderRadius: '0.5rem',
        textAlign: 'center',
        padding: '32px 16px',
        marginBottom: '60px'
    }

    const BoxContent = {
        textAlign: 'center',
        gap: '15px',

        '@media (max-width: 767px)': {
            textAlign: 'left'
        }
    }

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <Box className="grid grid-cols-1 xl:grid-cols-2">
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        background: '#E5EBF1'
                    }}>
                    <Styled.Detail className="xl:w-6/7 py-7 xl:py-0 text-center">
                        <Stack gap="30px" padding="0px 10px">
                            <Typography variant="title" fontWeight={400}>
                                {Translate('about_us_policy_tdsa')}
                            </Typography>

                            <Typography variant="label" color="#707783">
                                {Translate('manager_period')}
                            </Typography>
                        </Stack>
                    </Styled.Detail>
                </Box>

                <img src={AboutUsImage} alt={AboutUsImage} className="w-full" />
            </Box>

            <Box sx={Wrapper} className="container">
                <Box sx={BoxTitle}>
                    <Typography variant="title">{Translate('home_policy_title')}</Typography>
                </Box>

                <Stack sx={BoxContent}>
                    <Typography variant="label">{Translate('home_policy_1')}</Typography>
                    <Typography variant="label">{Translate('home_policy_2')}</Typography>
                    <Typography variant="label">{Translate('home_policy_3')}</Typography>
                    <Typography variant="label">{Translate('home_policy_4')}</Typography>
                </Stack>
            </Box>
        </ThemeProvider>
    )
}

export default Policy
