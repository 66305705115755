import { useState } from "react";
import * as Styled from "./styled";
import { Translate } from "../../utils/helper";
import Answer from "./answer";
import Answer2 from "./answer2";

const FAQs = () => {
    const [page, setPage] = useState(0);

    return (
        <div className="bg-white">
            <Styled.BannerWrapper>
                <div className="container mx-auto px-8 md:w-3/4 lg:w-2/4 mb-5 md:mb-12">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-[24px] text-[#384250] text-center">
                        <button
                            className={
                                page === 0
                                    ? "px-5 py-2 md:py-6 bg-[#3375E5] text-white rounded flex items-center justify-center cursor-pointer"
                                    : "px-5 py-2 md:py-6 bg-white  rounded flex items-center justify-center cursor-pointer hover:bg-[#3375E5] hover:text-white"
                            }
                            onClick={() => setPage(0)}
                        >
                            {Translate("ethics_toolkit_menu_5")}
                        </button>
                        <button
                            className={
                                page === 1
                                    ? "px-5 py-2 md:py-6 bg-[#3375E5] text-white rounded flex items-center justify-center cursor-pointer"
                                    : "px-5 py-2 md:py-6 bg-white  rounded flex items-center justify-center cursor-pointer hover:bg-[#3375E5] hover:text-white"
                            }
                            onClick={() => setPage(1)}
                        >
                            {Translate("ethics_toolkit_menu_6")}
                        </button>
                    </div>
                </div>
            </Styled.BannerWrapper>

            {page === 0 && (
                <>
                    <Answer2 />
                </>
            )}

            {page === 1 && (
                <>
                    <Answer />
                </>
            )}
        </div>
    );
};
export default FAQs;
