import { useContext } from 'react'
import { LanguageContext } from '../contexts/LanguageContext'
import { DictionaryContext } from '../contexts/DictionaryContext'
import { ImageContext } from '../contexts/ImageContext'

export function Translate(key) {
    const { language } = useContext(LanguageContext)
    const dictionary = useContext(DictionaryContext)

    const data = dictionary
        .filter(item => item.dictionaryKey === key)
        .map(filter => {
            return {
                lang: language === 'th' ? filter.th : filter.en
            }
        })
    const newData = data[0]?.lang

    return newData

    // const langData = dictionary.map(filter => {
    //     return language === 'th' ? filter.th[key] : filter.en[key]
    // })

    // return langData[0]
}

export function FilterImage(key) {
    const image = useContext(ImageContext)

    const data = image
        .filter(item => item.imageKey === key)
        .map(filter => {
            return {
                ...filter
            }
        })
    const newImage = data[0]?.src

    return newImage
}
