import { useContext } from 'react'
import * as Styled from './styled'
import Member1 from '../../assets/images/apply_new.png'
import ButtonFull from '../../components/common/ButtonFull'
import { Translate } from '../../utils/helper'
import { LanguageContext } from '../../contexts/LanguageContext'
import { FilterImage } from '../../utils/helper'
import { DownloadPDF } from '../../components/common/DownloadPDF'

const Enroll = () => {
    const { language } = useContext(LanguageContext)

    const ApplyTDSA_file = language === 'th' ? FilterImage('pdf_apply_tdsa_th') : FilterImage('pdf_apply_tdsa_en')
    const ApplyTDSA_name = language === 'th' ? 'pdf_apply_tdsa_th' : 'pdf_apply_tdsa_en'
    const TDSA_Constitution = language === 'th' ? FilterImage('pdf_constitution_th') : FilterImage('pdf_constitution_en')

    return (
        <div className="bg-white">
            <Styled.BannerWrapper />

            <div className="md:container md:mx-auto md:px-7 py-10 md:py-20">
                <div className="text-center px-5 md:px-0">
                    <div className="text-[48px] font-medium mb-6">{Translate('enroll')}</div>
                    <div className="text-[#3375E5] text-[24px] cursor-pointer">
                        <a href={TDSA_Constitution} target="_blank" rel="noopener noreferrer">
                            {Translate('enroll_rule_tdsa')}
                        </a>
                    </div>
                </div>

                <div className="grid grid-rows-6 md:grid-rows-1 md:grid-cols-7 my-12 md:my-20 relative">
                    <div className="row-start-2 md:row-start-0 row-span-5 md:row-span-0 md:col-start-3 md:col-span-5 bg-[#E8F0FE] rounded h-[466px]">
                        <div className="absolute top-0 md:top-auto md:left-0 md:w-[50%] flex items-center md:h-[466px] px-5 md:px-0 md:pt-12">
                            <img src={Member1} alt={Member1} />
                        </div>

                        <div className="absolute bottom-12 md:bottom-auto md:w-[50%] md:right-0 flex items-center md:h-[466px] px-5 xl:px-14">
                            <div className="text-center">
                                <Styled.Title1
                                    dangerouslySetInnerHTML={{
                                        __html: Translate('enroll_title1')
                                    }}
                                />
                                <div className="mt-[60px]">
                                    <ButtonFull onClick={() => DownloadPDF(ApplyTDSA_file, ApplyTDSA_name)}>{Translate('click_apply')}</ButtonFull>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="px-5 md:px-0">
                    <Styled.Title2
                        dangerouslySetInnerHTML={{
                            __html: Translate('enroll_title2')
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
export default Enroll
