import React, { useState, useEffect } from 'react'
import Service from '../../service'
import CardDictionary from '../../components/common/CardDictionary'
import Loading from '../../components/loading'
import MenuAdmin from '../../components/layouts/MenuAdmin'
import { Box } from '@mui/material'
import AdminModal from './adminModal'

const Admin = () => {
    const [dictionaryData, setDictionaryData] = useState([])
    const [modal, setModal] = useState(false)
    const [type, setType] = useState('')
    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(false)
    const [pageOptions, setPageOptions] = useState(false)

    const role = localStorage.getItem('TDSA_ROLE')
    const token = localStorage.getItem('TDSA_TOKEN')

    const fetchData = async tags => {
        setLoading(true)

        const newTags = tags.map(tag => `&keywords=${tag}`).join('')

        try {
            const { data } = await Service.getDictionaryAll(newTags)
            if (data) {
                const newData = data.items.map(item => ({
                    ...item,
                    dictionaries: item.dictionaries.map(dictionary => ({
                        ...dictionary,
                        language: dictionary.languageCode === 'en' ? 'English' : 'Thai'
                    }))
                }))
                setDictionaryData(newData)
            }
        } catch (error) {
            // Handle errors here
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(tags)
        handleGetPage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags])

    useEffect(() => {
        if (modal) {
            document.body.classList.add('overflowHidden')
        } else {
            document.body.classList.remove('overflowHidden')
        }
    }, [modal])

    const handleGetPage = async () => {
        try {
            let res = await Service.getPage(token)
            if (res) {
                setPageOptions(res.data.items)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleOpenModal = value => {
        setModal(true)
        setType(value)
    }

    const getDictionary = async () => {
        setLoading(true)
        try {
            let newTags = ''
            for (let index = 0; index < tags.length; index++) {
                newTags += `&keywords=${tags[index]}`
            }

            let { data } = await Service.getDictionaryAll(newTags)

            if (data) {
                const newData = data.items.map(data => {
                    return {
                        ...data,
                        dictionaries: data.dictionaries.map(item => {
                            return {
                                ...item,
                                language: item.languageCode === 'en' ? 'English' : 'Thai'
                            }
                        })
                    }
                })
                setDictionaryData(newData)
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const addTags = event => {
        if (event.key === 'Enter' && event.target.value !== '') {
            setTags([...tags, event.target.value])
            event.target.value = ''
        }
    }

    const removeTags = index => {
        setTags([...tags.filter(tag => tags.indexOf(tag) !== index)])
    }

    const HeaderStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px'
    }

    return (
        <>
            <div className="mt-[80px] md:mt-[85px]">
                <MenuAdmin />

                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {modal && <AdminModal setModal={setModal} pageOptions={pageOptions} getDictionary={getDictionary} type={type} handleGetPage={handleGetPage} />}

                        <div className="container mx-auto p-7">
                            <Box sx={HeaderStyle} className={` ${role === 'develop' ? 'justify-between ' : 'justify-center'}`}>
                                {role === 'develop' && (
                                    <Box>
                                        <button className="rounded-md py-3 px-10 text-center w-full bg-gray-200 hover:bg-gray-300" type="button" onClick={() => handleOpenModal('new')}>
                                            NEW
                                        </button>
                                    </Box>
                                )}
                                <input
                                    type="text"
                                    onKeyUp={event => addTags(event)}
                                    placeholder="Search..."
                                    className={`border border-[#dbe2e8] py-3 px-4 rounded-full focus:border-[#0da9ef] focus:outline-none ${role === 'develop' ? 'w-full md:w-2/5' : 'w-full md:w-2/5'}`}
                                />
                            </Box>

                            <div className="flex flex-wrap justify-center gap-2 items-center mt-5 text-lg">
                                {tags.length >= 1 && <div>Keyword :</div>}

                                <div className="flex gap-2">
                                    {tags.map((tag, index) => (
                                        <div key={index} className="border px-2 bg-[#f5f5f5] rounded-md flex items-center">
                                            <div className="mr-1">{tag}</div>
                                            <div>
                                                <i className="fa fa-times text-gray-600 text-[14px] cursor-pointer" onClick={() => removeTags(index)}></i>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {tags.length >= 1 && (
                                    <div className="underline cursor-pointer" onClick={() => setTags([])}>
                                        Clear All
                                    </div>
                                )}
                            </div>

                            <div className="flex flex-rows flex-wrap justify-center items-center gap-4 w-full mt-5">
                                {dictionaryData.map((items, index) => (
                                    <CardDictionary key={index} items={items} tags={tags} pageOptions={pageOptions} getDictionary={getDictionary} role={role} />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default Admin
