import { useEffect, useState } from 'react'
import Layout from './components/layouts'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { routes } from './routes.js'
import { LanguageContext } from './contexts/LanguageContext'
import { DictionaryContext } from './contexts/DictionaryContext'
import { ImageContext } from './contexts/ImageContext'
import Service from './service'
import { Tracking } from './utils/tracker'

const defaultLanguage = 'th'

function App() {
    const [language, setLanguage] = useState(() => {
        const langFromLocalStorage = window.localStorage.getItem('langTDSA')

        return langFromLocalStorage ? langFromLocalStorage : defaultLanguage
    })
    const [dictionary, setDictionary] = useState([])
    const [image, setImage] = useState([])

    useEffect(() => {
        Tracking()
        getDictionary()
        getImage()
    }, [])

    useEffect(() => {
        window.localStorage.setItem('langTDSA', language)
    }, [language])

    const routeComponents = routes.map(({ path, element }, key) => <Route exact path={path} element={element} key={key} />)

    const getDictionary = async () => {
        try {
            let { data } = await Service.getDictionaryPage()
            if (data) {
                const newData = data.items.map(item => {
                    return {
                        dictionaryKey: item.dictionaryKey,
                        en: item.dictionaries[0].content,
                        th: item.dictionaries[1].content
                    }
                })
                setDictionary(newData)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getImage = async () => {
        try {
            let { data } = await Service.getImage()
            if (data) {
                setImage(data.items)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            <DictionaryContext.Provider value={dictionary}>
                <ImageContext.Provider value={image}>
                    <BrowserRouter>
                        <Layout>
                            <Routes>{routeComponents}</Routes>
                        </Layout>
                    </BrowserRouter>
                </ImageContext.Provider>
            </DictionaryContext.Provider>
        </LanguageContext.Provider>
    )
}

export default App
