import styled from "styled-components";

export const BannerWrapper = styled.div`
    background-image: url("./img/TDSA_2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media (min-width: 1600px) {
        height: 760px;
    }

    @media (max-width: 834px) {
        background-image: url("./img/TDSA_2.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 370px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/TDSA_2.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 400px;
    }
`;

export const BackgroundWrapper = styled.div`
    background-image: url("./img/direct_selling_5_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    // height: 1270px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;

    @media (min-width: 1600px) {
        // height: 1380px;
    }

    @media (max-width: 834px) {
        // height: 1700px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/direct_selling_5_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        // height: 2573px;
    }
`;

export const BackgroundWrapper2 = styled.div`
    background-image: url("./img/bg_question_2_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 834px) {
        // height: 2300px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/bg_question_2_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        // height: 2400px;
    }
`;

export const BannerWrapperSkeleton = styled.div`
    width: 100%;
    height: 650px;
    background-color: rgb(203 213 225);
    background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 60%
        )
        rgb(203 213 225);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1.5s loading ease-in-out infinite;

    @keyframes loading {
        to {
            background-position-x: -20%;
        }
    }

    @media (min-width: 1600px) {
        width: 100%;
        height: 760px;
        background-color: rgb(203 213 225);
        background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%
            )
            rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }

    @media (max-width: 428px) {
        width: 100%;
        height: 400px;
        background-color: rgb(203 213 225);
        background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%
            )
            rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }
`;

export const Detail = styled.div`
    padding-left: 120px;

    @media (min-width: 1550px) {
        padding-left: 20%;
    }

    @media (min-width: 1700px) {
        padding-left: 26%;
    }

    @media (min-width: 1800px) {
        padding-left: 30%;
    }

    @media (min-width: 1900px) {
        padding-left: 33%;
    }

    @media (max-width: 428px) {
        padding-left: 28px;
        padding-right: 28px;
    }
`;

export const Image = styled.div`
    padding-right: 120px;

    @media (min-width: 1550px) {
        padding-right: 20%;
    }

    @media (min-width: 1700px) {
        padding-right: 26%;
    }

    @media (min-width: 1800px) {
        padding-right: 30%;
    }

    @media (min-width: 1900px) {
        padding-right: 33%;
    }

    @media (max-width: 428px) {
        padding-left: 28px;
        padding-right: 28px;
    }
`;

export const Content = styled.div`
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #384250;

    div {
        display: flex;
    }
    span {
        margin-right: 10px;
    }
`;

export const Content1 = styled.div`
    h1 {
        font-weight: 400;
        font-size: 32px;
        line-height: 44px;
        color: #000000;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #384250;
        margin-top: 30px;
    }
`;

export const PonziScheme = styled.div`
    h1 {
        font-size: 48px;
    }

    div {
        font-size: 32px;
    }

    @media (max-width: 428px) {
        div {
            font-size: 28px;
        }
    }
`;

export const PonziScheme2 = styled.div`
    h1 {
        font-size: 48px;
        line-height: 60px;
    }

    div {
        font-size: 32px;
    }

    @media (max-width: 428px) {
        div {
            font-size: 28px;
        }
    }
`;