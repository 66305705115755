import React, { useState, useEffect } from "react";
import Service from "../../../service";
import Loading from "../../../components/loading";
import CardImage from "../../../components/common/CardImage";
import MenuAdmin from "../../../components/layouts/MenuAdmin";
import ModalUpload from "../../../components/common/ModalUpload";

const UploadPDF = () => {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState([]);

    const role = localStorage.getItem("TDSA_ROLE");

    useEffect(() => {
        handleGetImage();
    }, []);

    const handleGetImage = async () => {
        setLoading(true);
        try {
            let res = await Service.getImage();
            if (res) {
                const filter = res.data.items.filter((i) =>
                    i.imageKey.includes("pdf")
                );
                const newData = filter.map((item) => {
                    return {
                        ...item,
                        type: "pdf",
                    };
                });
                setImage(newData);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <>
            <div className="mt-[80px] md:mt-[85px]">
                <MenuAdmin />

                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {modal && (
                            <ModalUpload
                                setModal={setModal}
                                handleGetImage={() => handleGetImage()}
                                action="create"
                                type="pdf"
                            />
                        )}

                        <div className="container mx-auto p-7">
                            <div className="flex items-center">
                                {role === "develop" && (
                                    <div>
                                        <button
                                            className="rounded-md py-3 px-10 text-center w-full bg-gray-200 hover:bg-gray-300"
                                            type="button"
                                            onClick={() => setModal(true)}
                                        >
                                            NEW PDF
                                        </button>
                                    </div>
                                )}
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-5 w-full mt-5">
                                {image.map((items, index) => (
                                    <CardImage
                                        items={items}
                                        key={index}
                                        role={role}
                                        handleGetImage={() => handleGetImage()}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
export default UploadPDF;
