import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const MenuAdmin = () => {
    let { pathname } = useLocation();
    let navigate = useNavigate();

    return (
        <div className="flex justify-center gap-6 bg-[#f5f5f5] py-10 text-[24px]">
            <div
                className={`cursor-pointer ${
                    pathname === "/admin" ? "underline" : ""
                }`}
                onClick={() => navigate("/admin")}
            >
                DICTIONARY
            </div>
            <div
                className={`cursor-pointer ${
                    pathname === "/admin/image" ? "underline" : ""
                }`}
                onClick={() => navigate("/admin/image")}
            >
                IMAGE
            </div>
            <div
                className={`cursor-pointer ${
                    pathname === "/admin/pdf" ? "underline" : ""
                }`}
                onClick={() => navigate("/admin/pdf")}
            >
                PDF
            </div>
        </div>
    );
};
export default MenuAdmin;
