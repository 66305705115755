import { Box } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Service from 'service'
import styled from 'styled-components'
import Swal from 'sweetalert2'

const AdminModal = ({ setModal, getDictionary, type, pageOptions, handleGetPageList }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        watch
    } = useForm()

    let navigate = useNavigate()

    const token = localStorage.getItem('TDSA_TOKEN')
    const watchFields = watch('dictionaryKey')
    const watchFields2 = watch('pageValue')

    const handleCreateDictionary = async data => {
        setIsLoading(true)
        try {
            let newData = {
                dictionaryKey: data.dictionaryKey.trim(),
                isArchive: 0,
                pageId: data.page,
                dictionaries: [
                    {
                        languageCode: 'en',
                        countryCode: 'US',
                        content: data.english
                    },

                    {
                        languageCode: 'th',
                        countryCode: 'TH',
                        content: data.thai
                    }
                ]
            }

            let resCreate = await Service.createDictionary(token, newData)
            if (resCreate) {
                setIsLoading(false)
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Successfully',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    handleCloseModal()
                    setTimeout(() => {
                        getDictionary()
                    }, 500)
                })
            }
        } catch (error) {
            setIsLoading(false)
            setError(error.response.data.message)

            if (error.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const handleCreatePage = async data => {
        setIsLoading(true)
        try {
            let newData = {
                pageValue: data.pageValue,
                pageName: data.pageName
            }

            let res = await Service.createPage(token, newData)
            if (res) {
                setIsLoading(false)
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Successfully',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    handleGetPageList()
                    handleCloseModal()
                })
            }
        } catch (error) {
            setIsLoading(false)
            setError(error.response.data.message)

            if (error.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const handleCloseModal = () => {
        reset()
        setModal(false)
        setError('')
    }

    return (
        <>
            {type === 'new' && (
                <div className="fixed top-0 left-0 right-0 flex justify-center items-center w-full overflow-x-hidden overflow-y-auto inset-0 h-full bg-black bg-opacity-60">
                    <div className="relative w-full h-auto max-w-md p-4">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button
                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                                onClick={handleCloseModal}>
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"></path>
                                </svg>
                            </button>
                            <div className="px-6 py-6 lg:px-8">
                                <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">New</h3>

                                <form onSubmit={handleSubmit(handleCreateDictionary)}>
                                    <Box sx={{ minHeight: '22rem', maxHeight: '30rem' }} className="overflow px-1">
                                        <div className="mb-5">
                                            <label htmlFor="dictionaryKey" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                Dictionary Key
                                            </label>
                                            <input
                                                type="text"
                                                name="dictionaryKey"
                                                id="dictionaryKey"
                                                className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-blue-500 block w-full p-2.5"
                                                {...register('dictionaryKey', {
                                                    required: true
                                                })}
                                            />
                                            {errors.dictionaryKey?.type === 'required' && <span className="text-red-500">Dictionary Key is required</span>}
                                        </div>

                                        <div className="mb-5">
                                            <label htmlFor="page" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                Page
                                            </label>
                                            {/* <input
                                                type="text"
                                                name="page"
                                                id="page"
                                                className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-blue-500 block w-full p-2.5"
                                                {...register('page', {
                                                    required: true
                                                })}
                                            /> */}
                                            <select disabled={false} className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-blue-500 block w-full p-2.5" {...register('page')}>
                                                {pageOptions.map((value, index) => (
                                                    <option value={value.id} key={index}>
                                                        {value.pageName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.page?.type === 'required' && <span className="text-red-500">Dictionary Key is required</span>}
                                        </div>

                                        <div className="mb-5">
                                            <label htmlFor="english" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                English
                                            </label>
                                            <TextInput
                                                type="text"
                                                name="english"
                                                id="english"
                                                rows="3"
                                                className="bg-gray-50 border-gray-300 text-gray-900 focus:outline-none focus:border-blue-500 block overflow"
                                                {...register('english', {
                                                    required: true
                                                })}
                                            />
                                        </div>

                                        <div className="">
                                            <label htmlFor="thai" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                Thai
                                            </label>
                                            <TextInput
                                                type="text"
                                                name="thai"
                                                id="thai"
                                                rows="3"
                                                className="bg-gray-50 border-gray-300 text-gray-900 focus:outline-none focus:border-blue-500 block overflow"
                                                {...register('thai', {
                                                    required: true
                                                })}
                                            />
                                        </div>
                                    </Box>

                                    {error && <div className="text-center text-red-600">{error}</div>}

                                    <ButtonCreate type="submit" className={`mt-5 px-5 ${watchFields ? 'bg-blue-700 hover:bg-blue-800' : 'bg-gray-500'}`} disabled={watchFields ? false : true}>
                                        {isLoading && <span className="lds-dual-ring mr-2"></span>}
                                        <span>CREATE</span>
                                    </ButtonCreate>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {type === 'page' && (
                <div className="fixed top-0 left-0 right-0 flex justify-center items-center w-full overflow-x-hidden overflow-y-auto inset-0 h-full bg-black bg-opacity-60">
                    <div className="relative w-full h-auto max-w-md p-4">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button
                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                                onClick={handleCloseModal}>
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"></path>
                                </svg>
                            </button>
                            <div className="px-6 py-6 lg:px-8">
                                <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">ADD PAGE</h3>

                                <form onSubmit={handleSubmit(handleCreatePage)}>
                                    <Box className="overflow px-1">
                                        <div className="mb-5">
                                            <label htmlFor="pageValue" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                Page Value
                                            </label>
                                            <input
                                                type="text"
                                                name="pageValue"
                                                id="pageValue"
                                                className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-blue-500 block w-full p-2.5"
                                                {...register('pageValue', {
                                                    required: true
                                                })}
                                            />
                                            {errors.pageValue?.type === 'required' && <span className="text-red-500">Dictionary Key is required</span>}
                                        </div>

                                        <div className="mb-5">
                                            <label htmlFor="pageName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                Page Name
                                            </label>
                                            <input
                                                type="text"
                                                name="pageName"
                                                id="pageName"
                                                className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-blue-500 block w-full p-2.5"
                                                {...register('pageName', {
                                                    required: true
                                                })}
                                            />
                                            {errors.pageName?.type === 'required' && <span className="text-red-500">Dictionary Key is required</span>}
                                        </div>
                                    </Box>

                                    {error && <div className="text-center text-red-600">{error}</div>}

                                    <ButtonCreate type="submit" className={`mt-5 px-5 ${watchFields2 ? 'bg-blue-700 hover:bg-blue-800' : 'bg-gray-500'}`} disabled={watchFields2 ? false : true}>
                                        {isLoading && <span className="lds-dual-ring mr-2"></span>}
                                        <span>CREATE</span>
                                    </ButtonCreate>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const ButtonCreate = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
    width: 100%;
    font-weight: 500;
    border-radius: 0.5rem;
    height: 2.5rem;
`

const TextInput = styled.textarea`
    text-align: center;
    border-width: 1px;
    border-radius: 0.5rem;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.625rem;
`

export default AdminModal
