import { Layout } from 'antd'
import styled from 'styled-components'

const { Sider, Footer } = Layout

export const SiderWrapper = styled(Sider)` 
        &.ant-layout-sider-collapsed {
            flex: 0 0 0px !important;
            max-width: 100% !important;
            min-width: 0px !important;
            width: ${props => (props.collapsed === true ? '100% !important' : '0')};
           
        }
        &.ant-layout-sider {
            // position: fixed;
            // max-width: 100%;
            // width: 100%;
            // height: 100%;
            // background-color: #97bff6;
            // z-index: 1;

            position: fixed;
            max-width: 100%;
            width: 0;
            height: 100%;
            background-color: #97bff6;
            z-index: ${props => (props.collapsed === true ? '1' : '-1')};
        }
    }
`

export const SiderWrapperAdmin = styled(Sider)`
    &.ant-layout-sider-collapsed {
        flex: 0 0 0px !important;
        max-width: 0px !important;
        min-width: 0px !important;
        width: 0px !important;
    }
    &.ant-layout-sider {
        position: fixed;
        max-width: 20%;
        width: 20%;
        height: 100%;
        background-color: #97bff6;
        z-index: 1;
    }

    @media (max-width: 834px) {
        &.ant-layout-sider-collapsed {
            flex: 0 0 0px !important;
            max-width: 0px !important;
            min-width: 0px !important;
            width: 0px !important;
        }
        &.ant-layout-sider {
            position: fixed;
            max-width: 100%;
            width: 100%;
            height: 100%;
            background-color: #97bff6;
            z-index: 1;
        }
    }

    @media (max-width: 428px) {
        &.ant-layout-sider-collapsed {
            flex: 0 0 0px !important;
            max-width: 0px !important;
            min-width: 0px !important;
            width: 0px !important;
        }
        &.ant-layout-sider {
            position: fixed;
            max-width: 50%;
            width: 50%;
            height: 100%;
            background-color: #97bff6;
            z-index: 1;
        }
    }
`

export const HeaderWrapper = styled.div`
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    color: #384250;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1;
    height: 100px;
    padding: 0px 60px;

    @media (max-width: 834px) {
        padding: 0px 30px;
        font-size: 14px;
        height: 75px;
    }

    @media (max-width: 428px) {
        z-index: ${props => (props.collapsed === 'true' ? '0' : '1')};
        height: 65px;
        padding: 0px 30px;
    }
`

export const HeaderWrapperAdmin = styled.div`
    position: fixed;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: #384250;
    background-color: rgba(255, 255, 255, 0.95);
    height: 85px;
    @media (max-width: 428px) {
        height: 80px;
    }
`

export const ImageLogo = styled.img`
    @media (max-width: 834px) {
        width: 75px;
        height: 40px;
    }

    @media (max-width: 428px) {
        width: 85px;
        height: 45px;
    }
`

export const ImageLogoWFDSA = styled.img`
    width: 60px;
    height: 60px;
    @media (max-width: 834px) {
        margin-right: 0px;
        width: 40px;
        height: 40px;
    }
`

export const ImageLogoFooter = styled.img`
    margin-right: 20px;
    @media (max-width: 428px) {
        margin-right: 10px;
        width: 85px;
        height: 45px;
    }
`

export const LogoLanguage = styled.img`
    @media (max-width: 428px) {
        margin-left: 0px;
        width: 38px;
    }
`

export const MenuNavbar = styled.div`
    transition: all 5s;
`

export const FooterWrapper = styled(Footer)`
    &.ant-layout-footer {
        padding: 0;
    }
`

export const FooterSectionOne = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-color: #eaeaea;
    height: 100px;
    padding: 0px 60px;

    .title-section-one {
        font-size: 18px;
        font-weight: 400;
    }
    .subtitle-section-one {
        font-size: 16px;
        font-weight: 300;
    }

    @media (max-width: 834px) {
        flex-direction: column;
        height: 180px;
        padding: 23px 20px;
    }
`

export const FooterSectionTwo = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-color: #3375e5;
    height: 120px;
    padding: 0px 60px;
    font-size: 20px;
    font-weight: 300;
    color: #ffffff;

    @media (max-width: 834px) {
        flex-direction: column;
        height: 190px;
        padding: 23px 20px;
    }
`

export const FooterSectionThree = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #384250;
    height: 55px;
    padding: 0px 60px;
    color: #d0d0cf;
    font-size: 16px;
    font-weight: 300;

    @media (max-width: 428px) {
        padding: 0px 20px;
        font-size: 12px;
    }
`

export const MenuFooterSectionOne = styled.div`
    display: flex;
    color: #3375e5;
    font-size: 18px;
    font-weight: 400;
    gap: 30px;

    @media (max-width: 834px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }
`

export const SearchInput = styled.input`
    width: 83%;
    height: 46px;
    border-radius: 12px;
    padding-left: 50px;
    font-size: 17px;
    :focus {
        outline: none;
    }
`

export const MenuMobile = styled.div`
    border-right: 0px;
    background-color: #c7dcfb;
    font-size: 22px;
    padding: 20px 0px;

    @media (max-height: 780px) {
        height: 461px;
    }
`

export const MenuMobilFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    height: 65px;
    padding: 0px 35px;
    width: 100%;
    font-size: 17px;
`
