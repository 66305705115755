import React, { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { ReactComponent as EditSvg } from '../../assets/svg/icon/edit-regular.svg'
import { ReactComponent as DeleteSvg } from '../../assets/svg/icon/trash-alt-regular.svg'
import Swal from 'sweetalert2'
import Service from '../../service'
import styled from 'styled-components'
import ModalUpload from './ModalUpload'
import { useMediaQuery } from 'react-responsive'

const CardImage = ({ items, role, handleGetImage }) => {
    const [modal, setModal] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)

    const token = localStorage.getItem('TDSA_TOKEN')

    const isMobile = useMediaQuery({ maxWidth: 767 })
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 })
    const isDesktop = useMediaQuery({ minWidth: 1024 })

    const onDocumentLoadSuccess = ({ numPage }) => {
        setPageNumber(numPage)
        setPageNumber(1)
    }

    const handleDelete = async () => {
        const confirmed = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete ${items.imageKey}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (confirmed.isConfirmed) {
            try {
                const res = await Service.deleteImage(token, items.imageKey)
                if (res) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    handleGetImage()
                }
            } catch (error) {
                console.error(error)
            }
        }
    }

    return (
        <div className="w-full text-sm rounded-md border">
            <div className="bg-[#f5f5f5] py-3 text-center px-4 rounded-t-md">{items.imageKey}</div>

            <Card className="border-b">
                {items.type === 'image' && (
                    <div>
                        <img src={items.src} alt={items.src} className="fix-image" />
                    </div>
                )}

                {items.type === 'pdf' && (
                    <div className="h-full flex items-center justify-center">
                        <a href={items.src} target="_blank" rel="noopener noreferrer">
                            <Document file={items.src} onLoadSuccess={onDocumentLoadSuccess}>
                                {isMobile && <PagePDF height={170} width={180} pageNumber={pageNumber} />}
                                {isTablet && <PagePDF height={140} width={140} pageNumber={pageNumber} />}
                                {isDesktop && <PagePDF height={260} width={150} pageNumber={pageNumber} />}
                            </Document>
                        </a>
                    </div>
                )}
            </Card>

            <div className={`flex px-3 py-5 ${role === 'develop' ? 'justify-between' : 'justify-center'}`}>
                <button className="border border-[#4dc6eb] hover:bg-[#4dc6eb] w-9 h-9 md:w-10 md:h-10 rounded-full focus:outline-none">
                    <IconButton className="text-[#4dc6eb] hover:text-[white]" onClick={() => setModal(true)}>
                        <EditSvg />
                    </IconButton>
                </button>

                {modal && <ModalUpload setModal={setModal} keyImage={items.imageKey} imageSrc={items.src} handleGetImage={() => handleGetImage()} action="edit" type={items.type} />}

                {role === 'develop' ? (
                    <button className="border border-[#eb584d] hover:bg-[#eb584d] w-9 h-9 md:w-10 md:h-10 rounded-full focus:outline-none" onClick={() => handleDelete()}>
                        <IconButton className="text-[#eb584d] hover:text-[white]">
                            <DeleteSvg />
                        </IconButton>
                    </button>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

const PagePDF = styled(Page)`
    position: static !important;
`

const IconButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

const Card = styled.div`
    background: #ffffff;
    height: 270px;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .fix-image {
        max-height: 250px;
    }

    @media ((min-width: 768px) and (max-width: 1023px)) {
        height: 200px;

        .fix-image {
            max-height: 180px;
        }
    }
`

export default CardImage
