import styled from 'styled-components'

export const BannerWrapper = styled.div`
    background-image: url('./img/banner_about_us.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media (min-width: 1600px) {
        height: 760px;
    }

    @media (max-width: 834px) {
        background-image: url('./img/banner_about_us.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 370px;
    }

    @media (max-width: 428px) {
        background-image: url('./img/banner_about_us_mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 400px;
    }
`

export const BannerWrapperSkeleton = styled.div`
    width: 100%;
    height: 650px;
    background-color: rgb(203 213 225);
    background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) rgb(203 213 225);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1.5s loading ease-in-out infinite;

    @keyframes loading {
        to {
            background-position-x: -20%;
        }
    }

    @media (min-width: 1600px) {
        width: 100%;
        height: 760px;
        background-color: rgb(203 213 225);
        background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }

    @media (max-width: 428px) {
        width: 100%;
        height: 400px;
        background-color: rgb(203 213 225);
        background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }
`

export const Detail = styled.div`
    padding-left: 120px;

    @media (min-width: 1550px) {
        padding-left: 20%;
    }

    @media (min-width: 1700px) {
        padding-left: 26%;
    }

    @media (min-width: 1800px) {
        padding-left: 30%;
    }

    @media (min-width: 1900px) {
        padding-left: 33%;
    }

    @media (max-width: 1023px) {
        padding-left: 28px;
        padding-right: 28px;
    }

    @media (max-width: 428px) {
        padding-left: 28px;
        padding-right: 28px;
    }
`

export const Image = styled.div`
    padding-right: 120px;

    @media (min-width: 1550px) {
        padding-right: 20%;
    }

    @media (min-width: 1700px) {
        padding-right: 26%;
    }

    @media (min-width: 1800px) {
        padding-right: 30%;
    }

    @media (min-width: 1900px) {
        padding-right: 33%;
    }

    @media (max-width: 428px) {
        padding-left: 28px;
        padding-right: 28px;
    }
`

export const historyDetail = styled.div`
    div {
        margin-bottom: 20px;
    }
`

export const Title1 = styled.div`
    h1 {
        font-size: 36px;
        font-weight: 600;
    }
    p {
        font-weight: 300;
        font-size: 36px;
    }
`

export const SubTitle1 = styled.div`
    display: flex;
    gap: 30px;
    flex-direction: column;
    h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: #384250;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #384250;
    }
`

export const HomeTitle1 = styled.div`
    display: flex;

    h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: #000000;
        margin-right: 15px;
    }

    span {
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #384250;
    }

    @media (max-width: 834px) {
        h1 {
            font-size: 20px;
            margin-right: 5px;
        }

        span {
            font-size: 20px;
        }
    }

    @media (max-width: 428px) {
        h1 {
            font-size: 17px;
            margin-right: 5px;
        }

        span {
            font-size: 17px;
        }
    }
`

export const Content = styled.div`
    h1 {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        width: 50%;
    }
    h2 {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        margin-bottom: 30px;
        width: 50%;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #707783;
        width: 50%;
    }

    div {
        margin-top: 30px;
    }

    div > p {
        width: 100%;
    }

    @media (max-width: 1023px) {
        h1 {
            width: 100%;
        }
        h2 {
            width: 100%;
        }
        p {
            width: 100%;
        }
    }

    @media (max-width: 428px) {
        h1 {
            width: 100%;
        }
        h2 {
            width: 100%;
        }
        p {
            width: 100%;
        }
    }
`

export const Vision = styled.div`
    text-align: center;
    margin-top: 70px;

    h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 33px;
        color: #000000;
        margin-bottom: 20px;
    }

    div {
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 20px;
        color: #384250;
    }

    @media (max-width: 834px) {
        h1 {
            font-size: 32px;
        }

        div {
            font-size: 24px;
        }
    }

    @media (max-width: 428px) {
        h1 {
            font-size: 28px;
        }

        div {
            font-size: 17px;
        }
    }
`

export const WFDSAdetail = styled.div`
    font-size: 18px;
    color: #384250;
    margin-bottom: 20px;

    div {
        margin-bottom: 20px;
    }
`

export const MayerMessage1 = styled.div`
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    width: 55%;
    margin-bottom: 25px;

    @media (max-width: 1023px) {
        width: 100%;
    }
`

export const MayerMessage2 = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #707783;
    width: 55%;
    margin-bottom: 25px;

    @media (max-width: 1023px) {
        width: 100%;
    }
`

export const MayerMessage3 = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #707783;
    width: 55%;
    margin-bottom: 25px;

    @media (max-width: 1023px) {
        width: 100%;
    }
`

export const MayerMessage4 = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #707783;
    width: 100%;
`
