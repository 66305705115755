import { useState, useEffect, useContext } from 'react'
import { Layout } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import './styles.css'
import BackToTop from '../../components/common/BackToTop'
import { LanguageContext } from '../../contexts/LanguageContext.js'
import Footer from './Footer'
import Header from './Header'
import Admin from './Admin'
import Navbar from './Navbar'

const { Content } = Layout

const Layouts = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false)
    const [login] = useState(false)

    const { language, setLanguage } = useContext(LanguageContext)
    const history = useLocation()
    const token = localStorage.getItem('TDSA_TOKEN')
    let navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [history])

    useEffect(() => {
        if (history.pathname.startsWith('/admin')) {
            if (!token) {
                navigate(`/login`)
            }
        }
    }, [history.pathname, login, navigate, token])

    useEffect(() => {
        if (collapsed) {
            document.body.classList.add('overflowHidden')
        } else {
            document.body.classList.remove('overflowHidden')
        }
    }, [collapsed])

    const toggle = () => {
        setCollapsed(!collapsed)
    }

    return (
        <>
            {history.pathname.startsWith('/admin') ? (
                <Admin children={children} />
            ) : history.pathname === '/login' ? (
                <div className="h-screen">{children}</div>
            ) : (
                <Layout>
                    <Navbar language={language} setLanguage={setLanguage} collapsed={collapsed} toggle={toggle} />

                    <Layout className="site-layout">
                        <Header language={language} setLanguage={setLanguage} collapsed={collapsed} toggle={toggle} />
                        <Content>{children}</Content>
                        <BackToTop />
                        <Footer />
                    </Layout>
                </Layout>
            )}
        </>
    )
}

export default Layouts
