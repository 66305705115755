import * as Styled from './styled'
import mail from '../../assets/svg/icon/mail.svg'
import map_2 from '../../assets/svg/icon/map_2.svg'
import phone_2 from '../../assets/svg/icon/phone_2.svg'
import fax from '../../assets/svg/icon/fax.svg'
import facebook from '../../assets/svg/icon/facebook.svg'
import google_map from '../../assets/svg/icon/google_map.svg'
import { Translate } from '../../utils/helper'
import imageTDSA1 from '../../assets/images/tdsa-office-01.jpg'
import imageTDSA2 from '../../assets/images/tdsa-office-02.jpg'

const ContactUs = () => {
    const contact = [
        {
            icon: map_2,
            content: Translate('tdsa_address')
        },
        {
            icon: phone_2,
            content: Translate('tdsa_phone')
        },
        {
            icon: fax,
            content: Translate('tdsa_fax')
        },
        {
            icon: facebook,
            content: Translate('tdsa_facebook'),
            link: true
        },
        {
            icon: google_map,
            content: Translate('tdsa_google_map'),
            link: true
        }
    ]

    return (
        <div className="bg-white">
            <Styled.BannerWrapper>
                <div className="w-[50%] object-cover">
                    <img src={imageTDSA1} alt="" width={'100%'} className="object-cover h-full" />
                </div>
                <div className="w-[50%] object-cover">
                    <img src={imageTDSA2} alt="" width={'100%'} className="object-cover h-full" />
                </div>
            </Styled.BannerWrapper>

            <div className="container mx-auto px-7 py-10 md:py-20">
                <div className="text-center">
                    <div className="text-[48px] mb-5">{Translate('contact_us')}</div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-5 pt-10">
                    <div className="md:col-span-2 py-7 px-3 xl:px-10">
                        <div className="text-[28px] md:text-[24px] xl:text-[28px] text-[#384250] mb-2">{Translate('tdsa_manager')}</div>
                        <div className="text-[18px] text-[#707783] mb-2">{Translate('association_manager')}</div>
                        <div className="flex mb-7">
                            <div className="pt-1">
                                <img src={mail} alt={mail} />
                            </div>

                            <a href={`mailto:${Translate('tdsa_email')}`} className="ml-4 text-[18px] text-[#3375E5]">
                                {Translate('tdsa_email')}
                            </a>
                        </div>
                        <div className="text-[28px] md:text-[24px] xl:text-[28px] text-[#384250] mb-2">{Translate('tdsa_coordinator')}</div>
                        <div className="text-[18px] text-[#707783]">{Translate('association_coordinator')}</div>
                    </div>
                    <div className="md:col-span-3 bg-[#E8F0FE] rounded py-7 px-5 xl:px-12">
                        <div className="text-[36px] text-[#384250] mb-4">{Translate('address')}</div>
                        {contact.map((item, index) => (
                            <div className="flex mb-5" key={index}>
                                <div className="pt-1">
                                    <img src={item.icon} alt={item.icon + index} />
                                </div>
                                {item.link ? (
                                    <>
                                        <a href={item.content} className="w-4/5 ml-4 text-[18px] break-words text-[#3375E5]">
                                            {item.content}
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="w-4/5 ml-4 text-[18px] break-words text-black"
                                            dangerouslySetInnerHTML={{
                                                __html: item.content
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactUs
