import styled from "styled-components";

export const Wrapper = styled.div`
    margin-top: 99px;
    background-color: white;

    @media (max-width: 834px) {
        margin-top: 76px;
    }

    @media (max-width: 428px) {
        margin-top: 66px;
    }
`;
