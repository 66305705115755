import { useRef, useEffect, useContext } from 'react'
import * as Styled from './styled'
import { Translate } from '../../utils/helper'
import BG_Question from '../../assets/images/bg_question.png'
import IconMan from '../../assets/svg/icon/icon_man.svg'
import IconMember from '../../assets/svg/icon/icon_member.svg'
import ButtonFull from '../../components/common/ButtonFull'
import { LanguageContext } from '../../contexts/LanguageContext'
import { useLocation } from 'react-router-dom'
import { FilterImage } from '../../utils/helper'
import { DownloadPDF } from '@Components/common/DownloadPDF'
import { Box, Stack, styled, ThemeProvider, Typography } from '@mui/material'
import { TemplateConfigs } from 'templates/TemplateConfigs'
import { MemberShip1, MemberShip2, MemberShip3, MemberShip7, MemberShip7Detail, MemberShip8 } from './listText'

const Question = () => {
    const { language } = useContext(LanguageContext)

    const path = useLocation()

    const member1 = useRef()
    const member2 = useRef()
    const member3 = useRef()
    const member4 = useRef()
    const member5 = useRef()
    const member6 = useRef()

    const ApplyTDSA_file = language === 'th' ? FilterImage('pdf_apply_tdsa_th') : FilterImage('pdf_apply_tdsa_en')
    const ApplyTDSA_name = language === 'th' ? 'pdf_apply_tdsa_th' : 'pdf_apply_tdsa_en'

    const TDSA_Constitution_file = language === 'th' ? FilterImage('pdf_constitution_th') : FilterImage('pdf_constitution_en')
    const TDSA_Constitution_name = language === 'th' ? 'pdf_constitution_th' : 'pdf_constitution_en'

    useEffect(() => {
        setTimeout(() => {
            if (path.hash === '#1') {
                member1.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#2') {
                member2.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#3') {
                member3.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#4') {
                member4.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#5') {
                member5.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#6') {
                member6.current.scrollIntoView({ behavior: 'smooth' })
            }
        }, 1000)
    }, [path.hash])

    useEffect(() => {
        document.documentElement.style.scrollBehavior = 'smooth'
        return () => {
            document.documentElement.style.scrollBehavior = 'auto'
        }
    }, [])

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <Box>
                <Styled.BannerWrapper />

                <Box bgcolor={'#F6F9FD'}>
                    <div className="container mx-auto px-7 py-10 md:py-20">
                        <Box
                            id="1"
                            ref={member1}
                            sx={{
                                scrollMarginTop: '7rem'
                            }}>
                            <Box className="text-center mb-7 md:mb-14">
                                <Typography variant="title">{Translate('question_qualifications_detail')}</Typography>
                            </Box>
                            <div className="mb-7 md:mb-20">
                                <BoxMember gap="15px">
                                    {MemberShip1.map((item, index) => (
                                        <Stack flexDirection={'row'} key={index} gap="5px">
                                            <Box>
                                                <Typography variant="caption">{index + 1}.</Typography>
                                            </Box>

                                            <Box>
                                                <Typography variant="caption">{Translate(item)}</Typography>
                                            </Box>
                                        </Stack>
                                    ))}
                                </BoxMember>
                            </div>
                        </Box>

                        <Box
                            className="mb-14"
                            id="2"
                            ref={member2}
                            sx={{
                                scrollMarginTop: '7rem'
                            }}>
                            <Box className="text-center mb-7 md:mb-14">
                                <Typography variant="title">{Translate('question_qualifications_title2')}</Typography>
                            </Box>

                            <BoxMember gap="15px">
                                <Typography variant="label">{Translate('membership_2_title')}</Typography>
                                {MemberShip2.map((item, index) => (
                                    <Stack flexDirection={'row'} key={index} gap="5px">
                                        <Box>
                                            <Typography variant="caption">{index + 1}.</Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="caption">{Translate(item)}</Typography>
                                        </Box>
                                    </Stack>
                                ))}
                            </BoxMember>
                        </Box>
                    </div>

                    <Box
                        className="bg-white"
                        id="3"
                        ref={member3}
                        sx={{
                            scrollMarginTop: '2rem'
                        }}>
                        <div className="container mx-auto px-7 pt-12 md:pt-20 -mt-11 relative">
                            <Box className="text-center mb-10">
                                <Typography variant="title">{Translate('question_qualifications_title3')}</Typography>
                            </Box>

                            <div className="p-5 md:p-12 shadow-2xl rounded-[10px] text-[24px] text-[#384250] bg-white">
                                {MemberShip3.map((item, index) => (
                                    <Stack flexDirection={'row'} key={index} gap="5px">
                                        <Box>
                                            <Typography variant="caption">{index + 1}.</Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="caption">{Translate(item)}</Typography>
                                        </Box>
                                    </Stack>
                                ))}
                            </div>
                        </div>

                        <div className="w-full -marginTOP">
                            <img src={BG_Question} alt={BG_Question} className="w-full" />
                        </div>
                    </Box>

                    <Box
                        className="bg-[#E5EBF1]"
                        id="4"
                        ref={member4}
                        sx={{
                            scrollMarginTop: '2rem'
                        }}>
                        <div className="container mx-auto px-7 py-20">
                            <div className="text-center mb-14">
                                <Box className="text-center mb-1">
                                    <Typography variant="title">{Translate('question_qualifications_title4')}</Typography>
                                </Box>

                                <div className="text-[24px] text-[#384250]">{Translate('question_qualifications_subtitle4')}</div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-5 xl:gap-12">
                                <div className="w-full h-full bg-white rounded-t px-5 xl:px-8 py-5 text-center flex items-center border-b-8 border-[#FFBF3A] rounded shadow-lg">
                                    <div>
                                        <div className="flex justify-center mb-3">
                                            <img src={IconMan} alt="IconMan" />
                                        </div>
                                        <div className="mb-2 sm:mb-2 text-[24px] font-medium">{Translate('m_honorary_member')}</div>
                                        <div className="mb-2 sm:mb-3 text-[#707783] text-[16px] h-full flex items-center">{Translate('m_honorary_member_detail')}</div>
                                    </div>
                                </div>

                                <div className="w-full h-full bg-white rounded-t px-5 xl:px-8 py-5 text-center flex items-center border-b-8 border-[#B7E250] rounded shadow-lg">
                                    <div>
                                        <div className="flex justify-center mb-3">
                                            <img src={IconMember} alt="IconMan" />
                                        </div>
                                        <div className="mb-2 sm:mb-2 text-[24px] font-medium">{Translate('m_ordinary_member')}</div>
                                        <div className="mb-2 sm:mb-3 text-[#707783] text-[16px] h-full flex items-center">{Translate('m_ordinary_member_detail')}</div>
                                    </div>
                                </div>

                                <div className="w-full h-full bg-white rounded-t px-5 xl:px-8 py-5 text-center flex items-center border-b-8 border-[#B7E250] rounded shadow-lg">
                                    <div>
                                        <div className="flex justify-center mb-3">
                                            <img src={IconMember} alt="IconMan" />
                                        </div>
                                        <div className="mb-2 sm:mb-2 text-[24px] font-medium">{Translate('m_extraordinary')}</div>
                                        <div className="mb-2 sm:mb-3 text-[#707783] text-[16px] h-full flex items-center">{Translate('m_extraordinary_detail')}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-14">
                                <div className="mb-14">
                                    <Box mb="20px">
                                        <Typography variant="title4">{Translate('membership_4_title')}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography variant="caption">{Translate('membership_4_detail')}</Typography>
                                    </Box>
                                </div>

                                <div className="mb-14">
                                    <Box mb="20px">
                                        <Typography variant="title4">{Translate('membership_5_title')}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography variant="caption">{Translate('membership_5_detail')}</Typography>
                                    </Box>
                                </div>

                                <div className="mb-14">
                                    <Box mb="20px">
                                        <Typography variant="title4">{Translate('membership_6_title')}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography variant="caption">{Translate('membership_6_detail')}</Typography>
                                    </Box>
                                </div>

                                <div>
                                    <Box mb="20px">
                                        <Typography variant="title4">{Translate('membership_7_title')}</Typography>
                                    </Box>

                                    <Box mb="20px">
                                        <Typography variant="label">{Translate('membership_7_subtitle')}</Typography>
                                    </Box>

                                    <Box mb="10px">
                                        {MemberShip7.map((item, index) => (
                                            <Stack flexDirection={'row'} key={index} gap="5px">
                                                <Box>
                                                    <Typography variant="caption">{index + 1}.</Typography>
                                                </Box>

                                                <Box>
                                                    <Typography variant="caption">{Translate(item)}</Typography>
                                                </Box>
                                            </Stack>
                                        ))}
                                    </Box>

                                    <Box ml="20px">
                                        {MemberShip7Detail.map((item, index) => (
                                            <Stack flexDirection={'row'} key={index} gap="5px">
                                                <Box>
                                                    <Typography variant="caption">•</Typography>
                                                </Box>

                                                <Box>
                                                    <Typography variant="caption">{Translate(item)}</Typography>
                                                </Box>
                                            </Stack>
                                        ))}
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </Box>

                    <Box
                        className="bg-white"
                        id="5"
                        ref={member5}
                        sx={{
                            scrollMarginTop: '2rem'
                        }}>
                        <div className="container mx-auto px-7 py-20">
                            <Box textAlign="center" mb="30px">
                                <Typography variant="title">{Translate('membership_8_title')}</Typography>
                            </Box>

                            {MemberShip8.map((item, index) => (
                                <Stack flexDirection={'row'} key={index} gap="5px">
                                    <Box>
                                        <Typography variant="caption">{index + 1}.</Typography>
                                    </Box>

                                    <Box>
                                        <Typography variant="caption">{Translate(item)}</Typography>
                                    </Box>
                                </Stack>
                            ))}
                        </div>
                    </Box>

                    <Styled.BackgroundWrapper>
                        <Box
                            className="container mx-auto px-7"
                            id="6"
                            ref={member6}
                            sx={{
                                scrollMarginTop: '2rem'
                            }}>
                            <div className="grid grid-cols-1 md:grid-cols-2 py-22 gap-4 xl:gap-8">
                                <div className="bg-white bg-opacity-80 py-10 px-3 xl:px-5 text-center rounded">
                                    <div className="text-[32px] xl:text-[36px] mb-4 font-medium">{Translate('question_form_apply')}</div>
                                    <ButtonFull onClick={() => DownloadPDF(ApplyTDSA_file, ApplyTDSA_name)}>{Translate('download')}</ButtonFull>
                                </div>

                                <div className="bg-white bg-opacity-80 py-10 px-3 xl:px-5 text-center rounded">
                                    <div className="text-[32px] xl:text-[36px] mb-4 font-medium">{Translate('question_articles_association')}</div>

                                    <ButtonFull onClick={() => DownloadPDF(TDSA_Constitution_file, TDSA_Constitution_name)}>{Translate('download')}</ButtonFull>
                                </div>
                            </div>
                        </Box>
                    </Styled.BackgroundWrapper>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

const BoxMember = styled(Stack)`
    background: #e8f0fe;
    padding: 40px;
    border-radius: 7px;

    @media (max-width: 428px) {
        padding: 24px;
    }
`

export default Question
