import { TemplateConfigs } from 'templates/TemplateConfigs'
import { Translate } from 'utils/helper'
import { Box, ThemeProvider, Typography, Stack } from '@mui/material'

const History = () => {
    const Wrapper = {
        margin: '0 auto',
        padding: '80px 28px',

        '@media (max-width: 767px)': {
            padding: '40px 32px'
        }
    }

    const Section1 = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '60px',

        '@media (max-width: 767px)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
        }
    }

    const BorderCustom = {
        background: '#FFBF3A',
        borderRadius: '10px',
        width: '7px',
        height: 'auto',
        marginRight: '24px',
        marginLeft: '24px',

        '@media (max-width: 767px)': {
            marginRight: '24px',
            marginLeft: '0'
        }
    }

    const TitleHistoryCustom = {
        display: 'flex',
        textAlign: 'right',
        alignItems: 'center',

        '@media (max-width: 767px)': {
            textAlign: 'center',
            justifyContent: 'center'
        }
    }

    const TitleHistoryCustom2 = {
        '@media (max-width: 767px)': {
            justifyContent: 'center'
        }
    }

    const styleCustom = {
        textAlign: 'center',
        '@media (max-width: 428px)': {
            textAlign: 'left'
        }
    }

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <Stack className="container" sx={Wrapper}>
                <Typography variant="title" sx={styleCustom} mb="20px">
                    {Translate('about_us_history')}
                </Typography>

                <Typography variant="caption" sx={styleCustom}>
                    {Translate('about_us_history_detail')}
                </Typography>

                <Box sx={Section1}>
                    <Box sx={TitleHistoryCustom}>
                        <Stack gap="10px">
                            <Typography variant="title2">{Translate('home_history_title_1')}</Typography>

                            <Typography variant="title2">{Translate('home_history_title_2')}</Typography>
                        </Stack>
                    </Box>

                    <Stack height="100%" flexDirection="row" sx={TitleHistoryCustom2}>
                        <Box sx={BorderCustom} />
                        <Stack gap="10px">
                            <span>
                                <Typography variant="label" color={'#000000'} fontWeight={500} mr="15px">
                                    {Translate('home_history_label_1_1')}
                                </Typography>

                                <Typography variant="label">{Translate('home_history_label_1_2')}</Typography>
                            </span>

                            <span>
                                <Typography variant="label" color={'#000000'} fontWeight={500} mr="15px">
                                    {Translate('home_history_label_2_1')}
                                </Typography>

                                <Typography variant="label">{Translate('home_history_label_2_2')}</Typography>
                            </span>

                            <span>
                                <Typography variant="label" color={'#000000'} fontWeight={500} mr="15px">
                                    {Translate('home_history_label_3_1')}
                                </Typography>

                                <Typography variant="label">{Translate('home_history_label_3_2')}</Typography>
                            </span>
                        </Stack>
                    </Stack>
                </Box>

                {/* vision */}

                <Stack textAlign={'center'} mt="60px" gap="15px">
                    <Typography variant="title2" color={'#000000'} fontWeight={500}>
                        {Translate('home_vision_title')}
                    </Typography>

                    <Typography variant="label">{Translate('home_vision_1')}</Typography>

                    <Typography variant="label">{Translate('home_vision_2')}</Typography>

                    <Typography variant="label">{Translate('home_vision_3')}</Typography>

                    <Typography variant="label">{Translate('home_vision_4')}</Typography>
                </Stack>
            </Stack>
        </ThemeProvider>
    )
}

export default History
