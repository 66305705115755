import React, { useState } from "react";
import * as Styled from "./LayoutStyled.js";
import { Link, useNavigate } from "react-router-dom";
import logoTDSA from "../../assets/svg/tdsa_logo_2.svg";
import phone from "../../assets/svg/icon/phone.svg";
import { Translate } from "../../utils/helper.js";
import CookieConsent from "react-cookie-consent";
import cookie_tdsa_1 from "../../assets/images/Cookie-policies-tdsa-01.jpg";
import cookie_tdsa_2 from "../../assets/images/Cookie-policies-tdsa-02.jpg";
import cookie_tdsa_3 from "../../assets/images/Cookie-policies-tdsa-03.jpg";
import ICON_QUEST from "../../assets/svg/icon/ic_quest.svg";
import { Image } from "antd";
import ICON_FACEBOOK from "../../assets/svg/icon/icon_facebook.svg";
import ICON_MAIL from "../../assets/svg/icon/icon_mail.svg";
import ICON_MAP from "../../assets/svg/icon/icon_map.svg";
import ICON_LINK from "../../assets/svg/icon/icon_link.svg";
import { useMediaQuery } from "react-responsive";
import logoWFDSA from "../../assets/svg/wfdsa.svg";

const listMenuFooter = [
    {
        key: "menu_apply_tdsa",
        path: "/membership",
    },
    {
        key: "menu_web_links",
        path: "/web-links",
    },
    {
        key: "menu_wfdsa",
        link: "https://www.wfdsa.org/",
    },
    {
        key: "menu_contact_us",
        path: "/contact-us",
    },
];

const Footer = () => {
    const [visible, setVisible] = useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
    const isTablet = useMediaQuery({ query: "(max-width: 1280px)" });

    let navigate = useNavigate();

    const contactIcon = [
        {
            src: ICON_FACEBOOK,
            name: "icon_facebook",
            link: Translate("tdsa_facebook"),
            target: true,
        },
        {
            src: ICON_MAIL,
            name: "icon_mail",
            mail: Translate("tdsa_email"),
        },
        {
            src: ICON_MAP,
            name: "icon_map",
            link: "https://www.google.com/maps/place/%E0%B8%AA%E0%B8%A1%E0%B8%B2%E0%B8%84%E0%B8%A1%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B8%95%E0%B8%A3%E0%B8%87%E0%B9%84%E0%B8%97%E0%B8%A2+TDSA/@13.8969154,100.5618794,17z/data=!3m1!4b1!4m5!3m4!1s0x30e28319d302b8d7:0x1e82ed64e6ee29d8!8m2!3d13.8969179!4d100.5640306",
            target: true,
        },
        {
            src: ICON_LINK,
            name: "icon_link",
            link: "/web-links",
            target: false,
        },
    ];

    return (
        <>
            <Styled.FooterWrapper>
                <Styled.FooterSectionOne>
                    <div className="flex gap-7">
                        <Styled.ImageLogo
                            src={logoTDSA}
                            alt="Logo TDSA"
                            className="cursor-pointer"
                            onClick={() => navigate("/")}
                        />

                        <a
                            href="https://www.wfdsa.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Styled.ImageLogoWFDSA
                                src={logoWFDSA}
                                alt="Logo WFDSA"
                                className="cursor-pointer"
                            />
                        </a>
                    </div>
                    <Styled.MenuFooterSectionOne>
                        {listMenuFooter.map((item, index) => (
                            <div key={index}>
                                {item.path ? (
                                    <Link key={index} to={item.path}>
                                        {Translate(item.key)}
                                    </Link>
                                ) : (
                                    <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {Translate(item.key)}
                                    </a>
                                )}
                            </div>
                        ))}
                    </Styled.MenuFooterSectionOne>
                </Styled.FooterSectionOne>
                <Styled.FooterSectionTwo>
                    <div className="flex">
                        <img src={phone} alt="icon_phone" />
                        <div className="ml-2">{Translate("tdsa_phone")}</div>
                    </div>
                    <div className="flex gap-x-5 xl:gap-x-7">
                        {contactIcon.map((item, index) => (
                            <div key={index}>
                                {item.link ? (
                                    <>
                                        <a
                                            href={item.link}
                                            target={item.target ? "_blank" : ""}
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={item.src}
                                                alt={item.name}
                                            />
                                        </a>
                                    </>
                                ) : (
                                    <a href={`mailto:${item.mail}`}>
                                        <img src={item.src} alt={item.name} />
                                    </a>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="flex gap-x-4">
                        <Link
                            to="/site-map"
                            className="text-[18px] xl:text-[20px]"
                        >
                            {Translate("menu_site_map")}
                        </Link>
                        <div className="border-l-2 h-[23px] rounded-md" />
                        <Link to="/faq" className="text-[18px] xl:text-[20px]">
                            {Translate("menu_faqs")}
                        </Link>
                        <div className="-mt-2">
                            <img src={ICON_QUEST} alt="icon_quest" />
                        </div>
                    </div>
                </Styled.FooterSectionTwo>
                <Styled.FooterSectionThree>
                    {Translate("footer_text")}
                </Styled.FooterSectionThree>

                <div
                    style={{
                        display: "none",
                    }}
                >
                    <Image.PreviewGroup
                        preview={{
                            visible,
                            onVisibleChange: (vis) => setVisible(vis),
                        }}
                    >
                        <Image src={cookie_tdsa_1} />
                        <Image src={cookie_tdsa_2} />
                        <Image src={cookie_tdsa_3} />
                    </Image.PreviewGroup>
                </div>

                <div className="fixed w-full bottom-0">
                    <div>
                        <CookieConsent
                            location="bottom"
                            buttonText="Accept Cookies"
                            cookieName="tdsa-accept-cookie-consent"
                            style={{
                                background: "#24232A",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: isMobile ? "232px" : isTablet ? "170px" : "80px",
                                flexDirection: isTablet ? "column" : "row",
                            }}
                            buttonStyle={{
                                background: "#FF820E",
                                color: "white",
                                fontSize: "18px",
                                borderRadius: "28px",
                                padding: "5px 15px",
                            }}
                            disableStyles={true}
                            expires={150}
                        >
                            <div className="px-[42px] xl:px-0 flex flex-col xl:flex-row text-center">
                                <span className="text-white text-[18px] xl:mr-10 mb-2 xl:mb-0">
                                    {Translate("cookie_policy_detail")}
                                </span>
                                <span
                                    onClick={() => setVisible(true)}
                                    className="cursor-pointer text-[#9EA9B9] text-[18px] underline underline-offset-1 xl:mr-14 mb-6 xl:mb-0"
                                >
                                    {Translate("cookie_policy")}
                                </span>
                            </div>
                        </CookieConsent>
                    </div>
                </div>
            </Styled.FooterWrapper>
        </>
    );
};

export default Footer;
