import styled from "styled-components";

export const BannerWrapper = styled.div`
    background-image: url("./img/member_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 650px;

    @media (min-width: 1600px) {
        height: 760px;
    }

    @media (max-width: 834px) {
        background-image: url("./img/member_desktop.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 370px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/member_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 400px;
    }
`;

export const BannerWrapperSkeleton = styled.div`
    width: 100%;
    height: 650px;
    background-color: rgb(203 213 225);
    background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 60%
        )
        rgb(203 213 225);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1.5s loading ease-in-out infinite;

    @keyframes loading {
        to {
            background-position-x: -20%;
        }
    }

    @media (min-width: 1600px) {
        width: 100%;
        height: 760px;
        background-color: rgb(203 213 225);
        background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%
            )
            rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }

    @media (max-width: 428px) {
        width: 100%;
        height: 400px;
        background-color: rgb(203 213 225);
        background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%
            )
            rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }
`;

export const Title1 = styled.div`
    h1 {
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #384250;
        margin-bottom: 30px;
    }

    p {
        font-weight: 400;
        font-size: 36px;
        line-height: 49px;
    }
`;

export const Title2 = styled.div`
    h2 {
        font-weight: 400;
        font-size: 36px;
        line-height: 49px;
        margin-bottom: 60px;
        text-align: center;
    }

    h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 20px;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #384250;
    }

    div {
        margin-bottom: 40px;
    }
`;
