import styled from "styled-components";

export const BannerWrapper = styled.div`
    background-image: url("./img/question_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media (min-width: 1600px) {
        height: 760px;
    }

    @media (max-width: 834px) {
        background-image: url("./img/question_desktop.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 370px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/question_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 400px;
    }
`;

export const BannerWrapperSkeleton = styled.div`
    width: 100%;
    height: 650px;
    background-color: rgb(203 213 225);
    background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 60%
        )
        rgb(203 213 225);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1.5s loading ease-in-out infinite;

    @keyframes loading {
        to {
            background-position-x: -20%;
        }
    }

    @media (min-width: 1600px) {
        width: 100%;
        height: 760px;
        background-color: rgb(203 213 225);
        background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%
            )
            rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }

    @media (max-width: 428px) {
        width: 100%;
        height: 400px;
        background-color: rgb(203 213 225);
        background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%
            )
            rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }
`;

export const BackgroundWrapper = styled.div`
    background-image: url("./img/bg_question_1_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 410px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 428px) {
        background-image: url("./img/complaint_2_desktop.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 609px;
    }
`;

export const BackgroundWrapper2 = styled.div`
    background-image: url("./img/bg_question_2_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 1756px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 834px) {
        height: 2300px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/bg_question_2_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 2400px;
    }
`;

export const Content1 = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
`;

export const Content2 = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;

    h2 {
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #384250;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #384250;
    }
`;

export const Content3 = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
`;

export const Content4 = styled.div`
    h1 {
        font-weight: 400;
        font-size: 32px;
        line-height: 44px;
        color: #000000;
        margin-bottom: 30px;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #384250;
    }
`;

export const Content5 = styled.div`
    h1 {
        font-weight: 400;
        font-size: 32px;
        line-height: 44px;
        color: #000000;
        margin-bottom: 30px;
    }

    h2 {
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #384250;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #384250;
    }

    div {
        margin-top: 30px;
    }
`;

export const Content6 = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #384250;
    }
`;
