import { Translate } from '../../utils/helper'
import TH from '../../assets/svg/th.svg'
import PH from '../../assets/svg/ph.svg'
import NZ from '../../assets/svg/nz.svg'
import SG from '../../assets/svg/sg.svg'
import MY from '../../assets/svg/my.svg'
import IDN from '../../assets/svg/INDONESIA 1.png'
import ButtonFull from '../../components/common/ButtonFull'
import { FilterImage } from '../../utils/helper'
import { DownloadPDF } from '@Components/common/DownloadPDF'
import { TemplateConfigs } from 'templates/TemplateConfigs'
import { Box, ThemeProvider, Typography } from '@mui/material'

const Investment = () => {
    const investments = [
        {
            logo: TH,
            name: 'country_th',
            fileName: 'investment_th',
            link: FilterImage('pdf_investment_th')
        },
        {
            logo: MY,
            name: 'country_my',
            fileName: 'investment_ml',
            link: FilterImage('pdf_investment_ml')
        },
        {
            logo: PH,
            name: 'country_ph',
            fileName: 'investment_ph',
            link: FilterImage('pdf_investment_ph')
        },
        {
            logo: IDN,
            name: 'country_idn',
            fileName: 'investment_id',
            link: FilterImage('pdf_investment_id')
        },
        {
            logo: NZ,
            name: 'country_nz',
            fileName: 'investment_nz',
            link: FilterImage('pdf_investment_nz')
        },
        {
            logo: SG,
            name: 'country_sg',
            fileName: 'investment_sg',
            link: FilterImage('pdf_investment_sg')
        }
    ]

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <div className="bg-white">
                <div className="container mx-auto px-8 md:px-7 py-10 md:py-20 text-center">
                    <Box mb="40px">
                        <Typography variant="title">{Translate('direct_selling_investment')}</Typography>
                    </Box>

                    <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px]">
                        {investments.map((item, index) => (
                            <div className="bg-[#E8F0FE] rounded-[5px] py-[40px] px-5" key={index}>
                                <div className="flex justify-center items-center mb-10">
                                    <img src={item.logo} alt={item.logo} />
                                    <div className="ml-5 text-[24px]">{Translate(item.name)}</div>
                                </div>

                                <ButtonFull onClick={() => DownloadPDF(item.link, item.fileName)}>{Translate('download_pdf')}</ButtonFull>
                            </div>
                        ))}
                    </Box>
                </div>
            </div>
        </ThemeProvider>
    )
}
export default Investment
