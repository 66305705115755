export const ManagerBoard = [
    {
        name: 'name_manager_1',
        role: 'position_manager_1',
        image: 'image_board_1'
    },
    {
        name: 'name_manager_2',
        role: 'position_manager_2',
        image: 'image_board_2'
    },
    {
        name: 'name_manager_3',
        role: 'position_manager_3',
        image: 'image_board_3'
    },
    {
        name: 'name_manager_4',
        role: 'position_manager_4',
        image: 'image_board_4'
    },
    {
        name: 'name_manager_5',
        role: 'position_manager_5',
        image: 'image_board_5'
    },
    {
        name: 'name_manager_6',
        role: 'position_manager_6',
        image: 'image_board_6'
    },
    {
        name: 'name_manager_7',
        role: 'position_manager_7',
        image: 'image_board_7'
    },
    {
        name: 'name_manager_8',
        role: 'position_manager_8',
        image: 'image_board_8'
    },
    {
        name: 'name_manager_9',
        role: 'position_manager_9',
        image: 'image_board_9'
    }
]
