import { useState } from 'react'
import * as Styled from './LayoutStyled.js'
import { Link } from 'react-router-dom'
import './styles.css'
import logoTDSA from '../../assets/svg/tdsa_logo_2.svg'
import logoTH from '../../assets/svg/logo_th.svg'
import logoUSA from '../../assets/svg/logo_usa.svg'
import Close from '../../assets/svg/close.svg'
import ArrowRight from '../../assets/svg/icon/arrow-right.svg'
import ICON_SEARCH from '../../assets/svg/icon/icon_search.svg'
import ICON_CHECK from '../../assets/svg/icon/check.svg'
import { Translate } from '../../utils/helper.js'
import { Box } from '@mui/material'

const Navbar = ({ language, setLanguage, collapsed, toggle }) => {
    const [search, setSearch] = useState('')

    const listMenuMobile = [
        {
            key: Translate('menu_about_us'),
            path: '/'
        },
        {
            key: Translate('menu_direct_selling'),
            path: '/direct-selling'
        },

        {
            key: Translate('menu_tdsa'),
            path: '/global-code-of-ethic'
        },
        {
            key: Translate('menu_question'),
            path: '/member'
        },
        {
            key: Translate('menu_apply_tdsa'),
            path: '/membership'
        },
        {
            key: Translate('menu_web_links'),
            path: '/web-links'
        },
        {
            key: Translate('menu_wfdsa'),
            link: 'https://www.wfdsa.org/'
        },
        {
            key: Translate('menu_contact_us'),
            path: '/contact-us'
        },
        {
            key: Translate('menu_site_map'),
            path: '/site-Map'
        },
        {
            key: Translate('menu_faqs'),
            path: '/faq'
        }
    ]

    const filteredData = listMenuMobile.filter(el => {
        if (search === '') {
            return el
        } else {
            return el.key.toLowerCase().includes(search)
        }
    })

    return (
        <>
            <div className="md:hidden">
                <Styled.SiderWrapper trigger={null} collapsible collapsed={collapsed} width="" collapsedWidth="">
                    <div className="flex justify-between bg-[#c7dcfb] h-[65px] items-center px-[35px]">
                        <div onClick={toggle}>
                            <img src={Close} alt="icon_close" />
                        </div>

                        <img className="w-[82px] h-[45px]" src={logoTDSA} alt="logo_tdsa_mobile" />
                    </div>

                    <div className="h-[76px] flex items-center">
                        <div className="relative w-full flex justify-center">
                            <div className="absolute top-3 left-12 md:left-[90px]">
                                <img src={ICON_SEARCH} alt="icon_search" />
                            </div>

                            <Styled.SearchInput type="text" onChange={e => setSearch(e.target.value)} placeholder="Search About TDSA" />
                        </div>
                    </div>

                    <Styled.MenuMobile className="py-4 overflow-auto h-full">
                        {filteredData.map((item, index) => (
                            <div key={index}>
                                {item.path ? (
                                    <Link to={item.path} key={index} className="flex justify-between py-3 px-[35px] items-center" onClick={toggle}>
                                        <Box sx={{ lineHeight: '25px' }}>{item.key}</Box>
                                        <img src={ArrowRight} alt="icon_arrow_right" />
                                    </Link>
                                ) : (
                                    <a href={item.link} className="flex justify-between py-3 px-[35px] items-center">
                                        <Box sx={{ lineHeight: '25px' }}>{item.key}</Box>
                                        <img src={ArrowRight} alt="icon_arrow_right" />
                                    </a>
                                )}
                            </div>
                        ))}
                    </Styled.MenuMobile>

                    <Styled.MenuMobilFooter className="bg-[#F5F5F5]">
                        <div className="flex items-center gap-2" onClick={() => setLanguage('th')}>
                            <img src={logoTH} alt="logo_th_mobile" />
                            <span>ภาษาไทย</span>
                            {language === 'th' ? <img src={ICON_CHECK} alt="icon_check" /> : ''}
                        </div>

                        <div className="flex items-center gap-2" onClick={() => setLanguage('en')}>
                            {language === 'en' ? <img src={ICON_CHECK} alt="icon_check" /> : ''}
                            <img src={logoUSA} alt="logo_usa_mobile" />
                            <span>English</span>
                        </div>
                    </Styled.MenuMobilFooter>
                </Styled.SiderWrapper>
            </div>
        </>
    )
}

export default Navbar
