import { useState } from "react";
import Service from "../../../service";
import logoTDSA from "../../../assets/svg/logo_TDSA.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const Login = () => {
    const [showPassword, setShowpassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    let navigate = useNavigate();

    const handleLogin = (data) => {
        setIsLoading(true);
        Service.login(data)
            .then(({ data }) => {
                setIsLoading(false);
                localStorage.setItem("TDSA_TOKEN", data.token);
                localStorage.setItem("TDSA_ROLE", data.role);
                localStorage.setItem("TDSA_NAME", data.displayName);
                navigate("/admin");
            })
            .catch((err) => {
                setIsLoading(false);
                Swal.fire({
                    title: "Incorrect Password",
                    icon: "error",
                });
            });
    };

    const handleChangeIcon = () => {
        setShowpassword(!showPassword);
    };

    return (
        <>
            <div className="px-5">
                <div className="h-screen w-full flex flex-col items-center justify-center">
                    <div className="mb-5">
                        <img src={logoTDSA} alt="logoTDSA" width={130} />
                    </div>

                    <div className="border w-full md:w-[30rem] p-5 rounded-md">
                        <form onSubmit={handleSubmit(handleLogin)}>
                            <div className="mb-6">
                                <div className="text-[14px] mb-2">Username</div>
                                <input
                                    type="text"
                                    className="w-full border rounded-full py-2 px-5 focus:border-[#0da9ef] focus:outline-none"
                                    {...register("username", {
                                        required: true,
                                    })}
                                />
                                {errors.username?.type === "required" && (
                                    <span className="text-red-500">
                                        Username is required
                                    </span>
                                )}
                            </div>
                            <div>
                                <div className="text-[14px] mb-2">Password</div>
                                <div className="relative flex items-center">
                                    <input
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        className="w-full border rounded-full py-2 px-5 focus:border-[#0da9ef] focus:outline-none"
                                        {...register("password", {
                                            required: true,
                                        })}
                                    />

                                    <div className="absolute right-4">
                                        <i
                                            className={`cursor-pointer ${
                                                showPassword
                                                    ? "fa fa-eye"
                                                    : "fa fa-eye-slash"
                                            }`}
                                            onClick={handleChangeIcon}
                                        ></i>
                                    </div>
                                </div>
                                {errors.password?.type === "required" && (
                                    <span className="text-red-500">
                                        Password is required
                                    </span>
                                )}
                            </div>

                            <div className="flex justify-center mt-5">
                                <button
                                    type="submit"
                                    className="flex items-center justify-center bg-[#0da9ef] hover:bg-[#0a6d9b] text-[14px] w-[9rem] h-10 text-white rounded-full"
                                >
                                    {isLoading && (
                                        <span className="lds-dual-ring mr-2"></span>
                                    )}
                                    <span>LOGIN</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Login;
