import { TemplateConfigs } from 'templates/TemplateConfigs'
import { FilterImage, Translate } from 'utils/helper'
import { Box, ThemeProvider, Typography, Stack } from '@mui/material'
import IconMan from '../../assets/svg/icon/icon_man.svg'
import IconMember from '../../assets/svg/icon/icon_member.svg'
import { MemberCompany } from '../../components/common/memberCompany'
import { BrandImage } from '../../components/common/brandImage.js'
import { useState } from 'react'

const Member = () => {
    const [showMoreMember, setShowMoreMember] = useState(false)
    const Wrapper = {
        margin: '0 auto',
        padding: '80px 28px',

        '@media (max-width: 767px)': {
            padding: '40px 32px'
        }
    }

    const BoxSubTitle = {
        maxWidth: '800px',
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto'
    }

    const handleShowMoreMember = () => {
        setShowMoreMember(!showMoreMember)
    }

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <Stack className="container" sx={Wrapper}>
                <Stack textAlign="center" mb="40px" gap="20px">
                    <Typography variant="title">{Translate('member_company_tdsa')}</Typography>

                    <Box sx={BoxSubTitle}>
                        <Typography variant="label">{Translate('member_company_tdsa_detail')}</Typography>
                    </Box>
                </Stack>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-5 xl:gap-12">
                    <div className="w-full h-full bg-white rounded-t px-5 xl:px-8 py-5 text-center flex items-center border-b-8 border-[#FFBF3A] rounded shadow-lg">
                        <div>
                            <div className="flex justify-center mb-3">
                                <img src={IconMan} alt="IconMan" />
                            </div>
                            <div className="mb-2 sm:mb-2 text-[24px] font-medium">{Translate('honorary_member')}</div>
                            <div className="mb-2 sm:mb-3 text-[#707783] text-[16px] h-full flex items-center">{Translate('honorary_member_detail')}</div>
                        </div>
                    </div>

                    <div className="w-full h-full bg-white rounded-t px-5 xl:px-8 py-5 text-center flex items-center border-b-8 border-[#B7E250] rounded shadow-lg">
                        <div>
                            <div className="flex justify-center mb-3">
                                <img src={IconMember} alt="IconMan" />
                            </div>
                            <div className="mb-2 sm:mb-2 text-[24px] font-medium">{Translate('ordinary_member')}</div>
                            <div className="mb-2 sm:mb-3 text-[#707783] text-[16px] h-full flex items-center">{Translate('ordinary_member_detail')}</div>
                        </div>
                    </div>

                    <div className="w-full h-full bg-white rounded-t px-5 xl:px-8 py-5 text-center flex items-center border-b-8 border-[#B7E250] rounded shadow-lg">
                        <div>
                            <div className="flex justify-center mb-3">
                                <img src={IconMember} alt="IconMan" />
                            </div>
                            <div className="mb-2 sm:mb-2 text-[24px] font-medium">{Translate('extraordinary')}</div>
                            <div className="mb-2 sm:mb-3 text-[#707783] text-[16px] h-full flex items-center">{Translate('extraordinary_detail')}</div>
                        </div>
                    </div>
                </div>

                <div className="mt-10">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 xl:gap-7 mb-3 xl:mb-7">
                        <div className="bg-white shadow-lg rounded-md h-full px-3 py-8 text-center mb-3 md:mb-0 flex items-center justify-center">
                            <div className="">
                                <div className="flex justify-center mb-5">
                                    <img src={IconMan} alt={IconMan} />
                                </div>
                                <div className="text-[24px] md:text-[20px] xl:text-[24px]">{Translate('honorary_member_home')}</div>
                            </div>
                        </div>

                        <div className="bg-[#F9F5E9] shadow-lg rounded-md px-6 md:px-2 xl:px-6 py-8 flex items-center h-full">
                            <div>
                                <div className="text-[18px] md:text-[16px] xl:text-[18px] mb-6 font-medium flex items-center">
                                    <div className="w-7">1.</div>
                                    <div className="ml-1">{Translate('honorary_name_3')}</div>
                                </div>
                                <div className="text-[24px] md:text-[22px] xl:text-[24px] text-[#707783] px-8 md:px-2 xl:px-8">{Translate('honorary_member')}</div>
                            </div>
                        </div>

                        <div className="bg-[#F9F5E9] shadow-lg rounded-md px-6 md:px-2 xl:px-6 py-8 flex items-center h-full">
                            <div>
                                <div className="text-[18px] md:text-[16px] xl:text-[18px] mb-6 font-medium flex">
                                    <div className="w-7">2.</div>
                                    <div className="ml-1">{Translate('honorary_name_4')}</div>
                                </div>
                                <div className="text-[24px] md:text-[22px] xl:text-[24px] text-[#707783] px-8 md:px-2 xl:px-8">{Translate('honorary_member')}</div>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 xl:gap-7 mb-3 xl:mb-7">
                        <div className="bg-white shadow-lg rounded-md h-full px-3 text-center mb-3 md:mb-0 flex items-center justify-center">
                            <div>
                                <div className="flex justify-center mb-5">
                                    <img src={IconMember} alt={IconMember} />
                                </div>
                                <div className="text-[24px] md:text-[20px] xl:text-[24px] mb-1">{Translate('ordinary_member_home')}</div>
                                <div>
                                    <button onClick={handleShowMoreMember} className="cursor-pointer text-[#3375E5] text-[18px] ">
                                        {showMoreMember ? Translate('show_less') : Translate('show_more')}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {MemberCompany.map((item, index) => (
                            <div key={index} className={`bg-[#E5FBEE] shadow-lg rounded-md flex h-[180px] ${showMoreMember ? '' : index >= 2 ? 'hidden' : ''}`}>
                                <div className="px-6 md:px-5 xl:px-6 py-6 md:py-5 xl:py-6 w-full">
                                    <div className="text-[18px] md:text-[16px] xl:text-[18px] mb-6 font-medium">
                                        <span className="w-7">{index + 1}.</span>
                                        <span className="ml-1 md:ml-0 xl:ml-1 break-words">{Translate(item.name)}</span>
                                    </div>
                                    <div className="text-[16px] md:text-[14px] xl:text-[16px]">
                                        <div className="text-[#707783] mb-3">
                                            {Translate(item.phone) === '' ? '' : 'Phone: '}
                                            {Translate(item.phone)}
                                        </div>

                                        <a href={`https://${Translate(item.web)}`} target="_blank" rel="noreferrer" className="text-[#3375E5]">
                                            {Translate(item.web)}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 xl:gap-7">
                        <div className="bg-white shadow-lg rounded-md h-full px-3 py-8 text-center mb-3 md:mb-0 flex items-center justify-center">
                            <div className="">
                                <div className="flex justify-center mb-5">
                                    <img src={IconMember} alt={IconMember} />
                                </div>
                                <div className="text-[24px] md:text-[20px] xl:text-[24px]">{Translate('extraordinary_home')}</div>
                            </div>
                        </div>

                        <div className={`bg-[#E5FBEE] shadow-lg rounded-md flex h-[180px]`}>
                            <div className="px-6 md:px-5 xl:px-6 py-6 md:py-5 xl:py-6 w-full">
                                <div className="text-[18px] md:text-[16px] xl:text-[18px] mb-6 font-medium">
                                    <span className="w-7">1.</span>
                                    <span className="ml-1 md:ml-0 xl:ml-1 break-words">{Translate('name_company_29')}</span>
                                </div>
                                <div className="text-[16px] md:text-[14px] xl:text-[16px]">
                                    <div className="text-[#707783] mb-3">
                                        {Translate('phone_company_29') === '' ? '' : 'Phone: '}
                                        {Translate('phone_company_29')}
                                    </div>

                                    <a href={`https://${Translate('web_company_29')}`} target="_blank" rel="noreferrer" className="text-[#3375E5]">
                                        {Translate('web_company_29')}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className={`bg-[#E5FBEE] shadow-lg rounded-md flex h-[180px]`}>
                            <div className="px-6 md:px-5 xl:px-6 py-6 md:py-5 xl:py-6 w-full">
                                <div className="text-[18px] md:text-[16px] xl:text-[18px] mb-6 font-medium">
                                    <span className="w-7">2.</span>
                                    <span className="ml-1 md:ml-0 xl:ml-1 break-words">{Translate('name_company_30')}</span>
                                </div>
                                <div className="text-[16px] md:text-[14px] xl:text-[16px]">
                                    <div className="text-[#707783] mb-3">
                                        {Translate('phone_company_30') === '' ? '' : 'Phone: '}
                                        {Translate('phone_company_30')}
                                    </div>

                                    <a href={`https://${Translate('web_company_30')}`} target="_blank" rel="noreferrer" className="text-[#3375E5]">
                                        {Translate('web_company_30')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-[140px]">
                    <div className="grid grid-cols-3 md:grid-cols-8 gap-x-3 gap-y-5 md:gap-x-4 md:gap-y-6">
                        {BrandImage.map((items, index) => (
                            <Box key={index}>
                                <img src={FilterImage(items.image)} alt={items.image} className="h-full w-full" />
                            </Box>
                        ))}
                    </div>
                </div>
            </Stack>
        </ThemeProvider>
    )
}

export default Member
