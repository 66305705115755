import React, { useState, useEffect } from 'react'
import logoTDSA from '../../assets/svg/tdsa_logo_2.svg'
import { ReactComponent as DownSvg } from '../../assets/svg/icon/chevron-down.svg'
import { ReactComponent as AccountSvg } from '../../assets/svg/icon/account.svg'
import * as Styled from './LayoutStyled.js'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'antd'
import Service from '../../service'

const { Content } = Layout

const Admin = ({ children }) => {
    const [showMenu, setShowMenu] = useState(false)
    let navigate = useNavigate()

    const userName = localStorage.getItem('TDSA_NAME')
    const token = localStorage.getItem('TDSA_TOKEN')

    useEffect(() => {
        if (token) {
            async function fetchData() {
                try {
                    const { data } = await Service.refreshToken(token)
                    if (data) {
                        localStorage.setItem('TDSA_TOKEN', data.token)
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        navigate('/login')
                    }
                }
            }
            fetchData()
        }
    }, [navigate, token])

    const logout = () => {
        localStorage.removeItem('TDSA_TOKEN')
        navigate('/login')
    }

    const onChangePassword = () => {
        setShowMenu(!showMenu)
        navigate('/admin/change-password')
    }

    const onChangePage = () => {
        setShowMenu(!showMenu)
        navigate('/admin/page')
    }

    const onChangeDictionary = () => {
        setShowMenu(!showMenu)
        navigate('/admin')
    }

    return (
        <>
            <Layout>
                <Layout className="site-layout">
                    <Styled.HeaderWrapperAdmin className="border">
                        <div className="flex items-center justify-between container mx-auto p-7 h-full">
                            <div>
                                <img src={logoTDSA} alt="logoTDSA" />
                            </div>

                            <div className="flex items-center gap-5 relative ml-5">
                                <div className="cursor-pointer flex" onClick={() => setShowMenu(!showMenu)}>
                                    <AccountSvg />

                                    <span className="ml-2 w-[100px]">{userName && userName.toUpperCase()}</span>

                                    <DownSvg
                                        className={`transition duration-150 ease-in-out
                                            ${showMenu ? '-rotate-180' : 'rotate-0'}
                                        `}
                                    />
                                </div>
                                {showMenu && (
                                    <div className="absolute top-9 border w-full rounded-sm bg-white">
                                        <div className="w-full text-right text-[14px] rounded-sm">
                                            <div className="border-b p-2 cursor-pointer hover:text-blue-700" onClick={onChangeDictionary}>
                                                DICTIONARY
                                            </div>
                                            <div className="border-b p-2 cursor-pointer hover:text-blue-700" onClick={onChangePage}>
                                                PAGE SETTING
                                            </div>
                                            <div className="border-b p-2 cursor-pointer hover:text-blue-700" onClick={onChangePassword}>
                                                CHANGE PASSWORD
                                            </div>
                                            <div className="p-2 cursor-pointer hover:text-blue-700" onClick={logout}>
                                                LOGOUT
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Styled.HeaderWrapperAdmin>

                    <Content>
                        <div className="h-full bg-white">{children}</div>
                    </Content>
                </Layout>
            </Layout>
        </>
    )
}

export default Admin
