export const MemberCompany = [
    {
        name: 'name_company_1',
        phone: 'phone_company_1',
        web: 'web_company_1'
    },
    {
        name: 'name_company_2',
        phone: 'phone_company_2',
        web: 'web_company_2'
    },
    {
        name: 'name_company_3',
        phone: 'phone_company_3',
        web: 'web_company_3'
    },
    {
        name: 'name_company_4',
        phone: 'phone_company_4',
        web: 'web_company_4'
    },
    {
        name: 'name_company_5',
        phone: 'phone_company_5',
        web: 'web_company_5'
    },
    {
        name: 'name_company_6',
        phone: 'phone_company_6',
        web: 'web_company_6'
    },
    {
        name: 'name_company_7',
        phone: 'phone_company_7',
        web: 'web_company_7'
    },
    {
        name: 'name_company_8',
        phone: 'phone_company_8',
        web: 'web_company_8'
    },
    {
        name: 'name_company_9',
        phone: 'phone_company_9',
        web: 'web_company_9'
    },
    {
        name: 'name_company_10',
        phone: 'phone_company_10',
        web: 'web_company_10'
    },
    {
        name: 'name_company_11',
        phone: 'phone_company_11',
        web: 'web_company_11'
    },
    {
        name: 'name_company_12',
        phone: 'phone_company_12',
        web: 'web_company_12'
    },
    {
        name: 'name_company_13',
        phone: 'phone_company_13',
        web: 'web_company_13'
    },
    {
        name: 'name_company_14',
        phone: 'phone_company_14',
        web: 'web_company_14'
    },
    {
        name: 'name_company_15',
        phone: 'phone_company_15',
        web: 'web_company_15'
    },
    {
        name: 'name_company_16',
        phone: 'phone_company_16',
        web: 'web_company_16'
    },
    {
        name: 'name_company_17',
        phone: 'phone_company_17',
        web: 'web_company_17'
    },
    //
    {
        name: 'name_company_18',
        phone: 'phone_company_18',
        web: 'web_company_18'
    },
    {
        name: 'name_company_19',
        phone: 'phone_company_19',
        web: 'web_company_19'
    },
    {
        name: 'name_company_20',
        phone: 'phone_company_20',
        web: 'web_company_20'
    },
    {
        name: 'name_company_21',
        phone: 'phone_company_21',
        web: 'web_company_21'
    },
    {
        name: 'name_company_22',
        phone: 'phone_company_22',
        web: 'web_company_22'
    },
    {
        name: 'name_company_23',
        phone: 'phone_company_23',
        web: 'web_company_23'
    },
    {
        name: 'name_company_24',
        phone: 'phone_company_24',
        web: 'web_company_24'
    },
    {
        name: 'name_company_25',
        phone: 'phone_company_25',
        web: 'web_company_25'
    },
    {
        name: 'name_company_26',
        phone: 'phone_company_26',
        web: 'web_company_26'
    },
    {
        name: 'name_company_27',
        phone: 'phone_company_27',
        web: 'web_company_27'
    },
    {
        name: 'name_company_28',
        phone: 'phone_company_28',
        web: 'web_company_28'
    }
]
