export const BrandImage = [
    {
        image: 'image_logo_company_1'
    },
    {
        image: 'image_logo_company_2'
    },
    {
        image: 'image_logo_company_3'
    },
    {
        image: 'image_logo_company_4'
    },
    {
        image: 'image_logo_company_5'
    },
    {
        image: 'image_logo_company_6'
    },
    {
        image: 'image_logo_company_7'
    },
    {
        image: 'image_logo_company_8'
    },
    {
        image: 'image_logo_company_9'
    },
    {
        image: 'image_logo_company_10'
    },
    {
        image: 'image_logo_company_11'
    },
    {
        image: 'image_logo_company_12'
    },
    {
        image: 'image_logo_company_13'
    },
    {
        image: 'image_logo_company_14'
    },
    {
        image: 'image_logo_company_15'
    },
    {
        image: 'image_logo_company_16'
    },
    {
        image: 'image_logo_company_17'
    },
    {
        image: 'image_logo_company_18'
    },
    {
        image: 'image_logo_company_19'
    },
    {
        image: 'image_logo_company_20'
    },
    {
        image: 'image_logo_company_21'
    },
    {
        image: 'image_logo_company_22'
    },
    {
        image: 'image_logo_company_23'
    },
    {
        image: 'image_logo_company_24'
    },
    {
        image: 'image_logo_company_25'
    },
    {
        image: 'image_logo_company_26'
    },
    {
        image: 'image_logo_company_27'
    },
    {
        image: 'image_logo_company_28'
    },
    {
        image: 'image_logo_company_29'
    },
    {
        image: 'image_logo_company_30'
    },
    {
        image: 'image_logo_company_31'
    }
]
