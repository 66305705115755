import { useEffect } from 'react'
import * as Styled from './styled'
import { Translate } from '../../utils/helper'

const directSelling = ['question_tdsa_1', 'question_tdsa_2', 'question_tdsa_3', 'question_tdsa_4', 'question_tdsa_5', 'question_tdsa_6', 'question_tdsa_7', 'question_tdsa_8', 'question_tdsa_9', 'question_tdsa_10']

const pyramid = ['pyramid_system_1', 'pyramid_system_2', 'pyramid_system_3', 'pyramid_system_4', 'pyramid_system_5', 'pyramid_system_6', 'pyramid_system_7', 'pyramid_system_8', 'pyramid_system_9', 'pyramid_system_10']

const AllList = [
    {
        title: 'question_tdsa_1',
        index: 1,
        group: 1
    },

    {
        title: 'pyramid_system_1',
        index: 1,
        group: 2
    },

    {
        title: 'question_tdsa_2',
        index: 2,
        group: 1
    },
    {
        title: 'pyramid_system_2',
        index: 2,
        group: 2
    },
    {
        title: 'question_tdsa_3',
        index: 3,
        group: 1
    },
    {
        title: 'pyramid_system_3',
        index: 3,
        group: 2
    },
    {
        title: 'question_tdsa_4',
        index: 4,
        group: 1
    },
    {
        title: 'pyramid_system_4',
        index: 4,
        group: 2
    },
    {
        title: 'question_tdsa_5',
        index: 5,
        group: 1
    },
    {
        title: 'pyramid_system_5',
        index: 5,
        group: 2
    },
    {
        title: 'question_tdsa_6',
        index: 6,
        group: 1
    },
    {
        title: 'pyramid_system_6',
        index: 6,
        group: 2
    },
    {
        title: 'question_tdsa_7',
        index: 7,
        group: 1
    },
    {
        title: 'pyramid_system_7',
        index: 7,
        group: 2
    },
    {
        title: 'question_tdsa_8',
        index: 8,
        group: 1
    },
    {
        title: 'pyramid_system_8',
        index: 8,
        group: 2
    },
    {
        title: 'question_tdsa_9',
        index: 9,
        group: 1
    },
    {
        title: 'pyramid_system_9',
        index: 9,
        group: 2
    },
    {
        title: 'question_tdsa_10',
        index: 10,
        group: 1
    },
    {
        title: 'pyramid_system_10',
        index: 10,
        group: 2
    }
]
const ComparisonTable = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Styled.BannerWrapper />
            <div className="bg-white py-10 md:py-20">
                <div className="container mx-auto px-5 text-center mb-10 md:mb-20">
                    <div className="text-[36px] md:text-[48px] font-medium">{Translate('comparison_table')}</div>
                </div>

                <div className="hidden md:block">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="bg-[#E5FBEE] text-[32px] text-center py-4 mb-3">{Translate('question_tdsa')}</div>
                        <div className="bg-[#F7F1E9] text-[32px] text-center py-4 mb-3">{Translate('pyramid_system')}</div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        {AllList.map((item, index) => (
                            <div className={`text-[24px] text-[#384250] flex ${item.group === 1 ? 'bg-[#E5FBEE] pl-[65px] xl:pl-[110px] pr-6 py-3 ml-4' : 'bg-[#F7F1E9] pr-[65px] xl:pr-[110px] pl-6 py-3 mr-4'}`} key={index}>
                                <div>{item.index}.</div>
                                <div className="ml-3">{Translate(item.title)}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="md:hidden">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div className="px-5 md:px-0 mb-7 md:mb-0">
                            <div className="bg-[#E5FBEE] text-[32px] text-center py-4 mb-3">{Translate('question_tdsa')}</div>

                            <div className="grid grid-cols-1 gap-3">
                                {directSelling.map((item, index) => (
                                    <div className="bg-[#E5FBEE] text-[24px] text-[#384250] p-5 flex" key={index}>
                                        <div>{index + 1}.</div>
                                        <div className="ml-3">{Translate(item)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="px-5">
                            <div className="bg-[#F7F1E9] text-[32px] text-center py-4 mb-3">{Translate('pyramid_system')}</div>

                            <div className="grid grid-cols-1 gap-3">
                                {pyramid.map((item, index) => (
                                    <div className="bg-[#F7F1E9] text-[24px] text-[#384250] p-5 flex" key={index}>
                                        <div>{index + 1}.</div>
                                        <div className="ml-3">{Translate(item)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ComparisonTable
