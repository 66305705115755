import * as Styled from './styled'
import OCPD from '../../assets/svg/ocpb-logo.svg'
import FDA from '../../assets/images/fda.png'
import MOC from '../../assets/images/moc.png'
import RD from '../../assets/images/rd.png'
import WFDSA from '../../assets/svg/wfdsa.svg'
import { Translate } from '../../utils/helper'

const Weblinks = () => {
    return (
        <div className="bg-white">
            <Styled.BannerWrapper />

            <div className="container mx-auto px-7 py-10 md:py-20">
                <div className="text-center">
                    <div className="text-[48px] font-medium">{Translate('wl_web_links')}</div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-5 xl:gap-8 text-center py-10">
                    <div className="md:col-span-2 bg-[#E5EBF1] py-10 px-5 rounded">
                        <div className="flex justify-center mb-6">
                            <img src={OCPD} alt={OCPD} width={'100'} height={'100'} />
                        </div>
                        <div className="text-[24px] mb-4">{Translate('wl_ocbp')}</div>
                        <div className="text-[18px] text-[#384250]">
                            <div>{Translate('wl_ocbp_address')}</div>
                            <div>
                                {Translate('wl_ocbp_hotline')} : {Translate('wl_ocbp_phone_value')}
                            </div>
                            <div>
                                {Translate('wl_email')} :{' '}
                                <a href={`mailto:${Translate('wl_ocbp_email')}`} className="text-[#3375E5]">
                                    {Translate('wl_ocbp_email')}
                                </a>
                            </div>
                            <div>
                                {Translate('wl_url')} :{' '}
                                <a href={Translate('wl_ocbp_url')} className="text-[#3375E5]" target={'_blank'} rel="noreferrer noopener">
                                    {Translate('wl_ocbp_url')}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="md:col-span-2 bg-[#E5EBF1] py-10 px-5 rounded">
                        <div className="flex justify-center mb-6">
                            <img src={FDA} alt={FDA} width={'100'} height={'100'} />
                        </div>
                        <div className="text-[24px] mb-4">{Translate('wl_fda')}</div>
                        <div className="text-[18px] text-[#384250]">
                            <div>{Translate('wl_fda_address')}</div>
                            <div>
                                {Translate('wl_phone')} : {Translate('wl_fda_phone')}
                            </div>
                            <div>
                                {Translate('wl_email')} :{' '}
                                <a href={`mailto:${Translate('wl_fda_email')}`} className="text-[#3375E5]">
                                    {Translate('wl_fda_email')}
                                </a>
                            </div>
                            <div>
                                {Translate('wl_url')} :{' '}
                                <a href={Translate('wl_fda_url')} className="text-[#3375E5]" target={'_blank'} rel="noreferrer noopener">
                                    {Translate('wl_fda_url')}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="md:col-span-2 bg-[#E5EBF1] py-10 px-5 rounded">
                        <div className="flex justify-center mb-6">
                            <img src={MOC} alt={MOC} width={'100'} height={'100'} />
                        </div>
                        <div className="text-[24px] mb-4">{Translate('wl_moc')}</div>
                        <div className="text-[18px] text-[#384250]">
                            <div>{Translate('wl_moc_address')}</div>
                            <div>
                                {Translate('wl_phone')} : {Translate('wl_moc_phone')}
                            </div>
                            <div>
                                {Translate('wl_email')} :{' '}
                                <a href={`mailto:${Translate('wl_moc_email')}`} className="text-[#3375E5]">
                                {Translate('wl_moc_email')}
                                </a>
                            </div>
                            <div>
                                {Translate('wl_url')} :{' '}
                                <a href={Translate('wl_moc_url')} className="text-[#3375E5]" target={'_blank'} rel="noreferrer noopener">
                                    {Translate('wl_moc_url')}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="md:col-span-2 bg-[#E5EBF1] py-10 px-5 rounded">
                        <div className="flex justify-center mb-6">
                            <img src={RD} alt={RD} width={'100'} height={'100'} />
                        </div>
                        <div className="text-[24px] mb-4">{Translate('wl_rd')}</div>
                        <div className="text-[18px] text-[#384250]">
                            <div>{Translate('wl_rd_address')}</div>
                            <div>
                                {Translate('wl_phone')} : {Translate('wl_rd_phone')}
                            </div>
                            <div>
                                {Translate('wl_email')} :{' '}
                                <a href={`mailto:${Translate('wl_rd_email')}`} className="text-[#3375E5]">
                                {Translate('wl_rd_email')}
                                </a>
                            </div>
                            <div>
                                {Translate('wl_url')} :{' '}
                                <a href={Translate('wl_rd_url')} className="text-[#3375E5]" target={'_blank'} rel="noreferrer noopener">
                                    {Translate('wl_rd_url')}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="md:col-start-2 md:col-end-4 bg-[#E5EBF1] py-10 px-5 rounded">
                        <div className="flex justify-center mb-6">
                            <img src={WFDSA} alt={WFDSA} width={'100'} height={'100'} />
                        </div>
                        <div className="text-[24px] mb-4">{Translate('wl_wfdsa')}</div>
                        <div className="text-[18px] text-[#384250]">
                            <div>{Translate('wl_wfdsa_address')}</div>
                            <div>
                                {Translate('wl_phone')} : {Translate('wl_wfdsa_phone')}
                            </div>
                            <div>
                                {Translate('wl_email')} :{' '}
                                <a href={`mailto:${Translate('wl_wfdsa_email')}`} className="text-[#3375E5]">
                                {Translate('wl_wfdsa_email')}
                                </a>
                            </div>
                            <div>
                                {Translate('wl_url')} :{' '}
                                <a href={Translate('wl_wfdsa_url')} className="text-[#3375E5]" target={'_blank'} rel="noreferrer noopener">
                                    {Translate('wl_wfdsa_url')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Weblinks
