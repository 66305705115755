import React, { useState, useEffect } from 'react'
import { ReactComponent as EditSvg } from '../../assets/svg/icon/edit-regular.svg'
import { ReactComponent as DeleteSvg } from '../../assets/svg/icon/trash-alt-regular.svg'
import { ReactComponent as SaveSvg } from '../../assets/svg/icon/cil_save.svg'
import { ReactComponent as CloseSvg } from '../../assets/svg/icon/carbon_close-outline.svg'
import Swal from 'sweetalert2'
import Highlighter from 'react-highlight-words'
import Service from '../../service'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Box, Stack } from '@mui/material'

const CardDictionary = ({ items, getDictionary, tags, role, pageOptions }) => {
    const [languageEnglish, setLanguageEnglish] = useState('')
    const [languageThai, setLanguageThai] = useState('')
    const [dictionaryKey, setDictionaryKey] = useState('')
    const [editMode, setEditMode] = useState(false)
    const [titleLanguageEnglish, setTitleLanguageEnglish] = useState('')
    const [titleLanguageThai, setTitleLanguageThai] = useState('')
    const [page, setPage] = useState('')
    const [pageValue, setPageValue] = useState(0)

    let navigate = useNavigate()

    const token = localStorage.getItem('TDSA_TOKEN')

    useEffect(() => {
        if (pageOptions) {
            const filter = pageOptions.filter(item => item.id === items.pageId)
            setPage(filter[0])
            setPageValue(filter[0].id)
        }

        setLanguageEnglish(items.dictionaries[0].content)
        setLanguageThai(items.dictionaries[1].content)
        setTitleLanguageEnglish(items.dictionaries[0].language)
        setTitleLanguageThai(items.dictionaries[1].language)
        setDictionaryKey(items.dictionaryKey)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    const handleDelete = async data => {
        const confirmed = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete ${data.dictionaryKey}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (confirmed.isConfirmed) {
            try {
                await Service.removeDictionary(token, data.dictionaryKey, data.pageId)
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
                getDictionary()
            } catch (error) {
                if (error.response.status === 401) {
                    navigate('/login')
                } else {
                    console.error(error)
                }
            }
        }
    }
    const handleEdit = async data => {
        try {
            let newData = {
                headerId: items.headerId,
                dictionaryKey: data.dictionaryKey,
                isArchive: items.isArchive,
                pageId: Number(pageValue),
                dictionaries: [
                    {
                        languageCode: 'en',
                        countryCode: 'US',
                        content: languageEnglish
                    },

                    {
                        languageCode: 'th',
                        countryCode: 'TH',
                        content: languageThai
                    }
                ]
            }

            let res = await Service.editDictionary(token, newData)
            if (res) {
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Successfully',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    setEditMode(false)
                    setTimeout(() => {
                        getDictionary()
                    }, 500)
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleUndo = data => {
        setLanguageEnglish(data.dictionaries[0].content)
        setLanguageThai(data.dictionaries[1].content)
    }

    const handleOnChange = e => {
        console.log(e.target.value)
        setPage(e.target.value)
        setPageValue(e.target.value)
    }

    return (
        <div className={`w-full md:w-[30%] lg:w-[24%] leading-loose text-sm rounded-md ${editMode ? 'border border-yellow-400 shadow shadow-yellow-400' : 'border'}`}>
            <div className="bg-[#f5f5f5] py-2 text-center px-4 rounded-t-md">
                <Highlighter highlightClassName="" searchWords={tags} autoEscape={true} textToHighlight={dictionaryKey} />
            </div>

            {editMode ? (
                <>
                    <Box sx={{ maxHeight: '21rem', minHeight: '21rem' }} className="overflow px-3">
                        <Stack flexDirection="row" gap="15px" alignItems="center" mt={1}>
                            <Box>Page</Box>

                            <select
                                disabled={role === 'develop' ? false : true}
                                className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-blue-500 block w-full p-1"
                                onChange={e => handleOnChange(e)}
                                value={page.id}>
                                {pageOptions.map((value, index) => (
                                    <option value={value.id} key={index}>
                                        {value.pageName}
                                    </option>
                                ))}
                            </select>
                        </Stack>
                        <div className="mt-3">
                            <Stack flexDirection="row" justifyContent="space-between">
                                <Box>Language</Box>
                                <Box className="mb-2">{titleLanguageEnglish}</Box>
                            </Stack>

                            <textarea
                                value={languageEnglish}
                                onChange={e => setLanguageEnglish(e.target.value)}
                                className="border text-[14px] border-[#6B7280] h-24 w-full rounded-lg text-center p-[10px] overflow focus:border-[#0da9ef] focus:outline-none"
                            />
                        </div>

                        <div className="mt-[2.5px]">
                            <Stack flexDirection="row" justifyContent="space-between">
                                <Box>Language</Box>
                                <Box className="mb-2">{titleLanguageThai}</Box>
                            </Stack>

                            <textarea
                                value={languageThai}
                                onChange={e => setLanguageThai(e.target.value)}
                                className="border text-[14px] border-[#6B7280] h-24 w-full rounded-lg text-center p-[10px] overflow focus:border-[#0da9ef] focus:outline-none"
                            />
                        </div>
                    </Box>
                </>
            ) : (
                <Box sx={{ maxHeight: '21rem', minHeight: '21rem' }} className="overflow px-3">
                    <Stack flexDirection="row" gap="15px" alignItems="center" mt={1}>
                        <Box>Page</Box>
                        <Highlighter highlightClassName="" searchWords={tags} autoEscape={true} textToHighlight={page.pageName} />
                    </Stack>

                    <div className="mt-3">
                        <Stack flexDirection="row" justifyContent="space-between" mb="10px">
                            <Box>Language</Box>
                            <Box>{titleLanguageEnglish}</Box>
                        </Stack>

                        <div className="border text-[14px] border-[#6B7280] h-24 w-full rounded-lg text-center p-2 overflow focus:outline-none resize-y">
                            <Highlighter highlightClassName="" searchWords={tags} autoEscape={true} textToHighlight={languageEnglish} />
                        </div>
                    </div>

                    <div className="mt-3">
                        <Stack flexDirection="row" justifyContent="space-between" mb="10px">
                            <Box>Language</Box>
                            <Box>{titleLanguageThai}</Box>
                        </Stack>
                        <div className="border text-[14px] border-[#6B7280] h-24 w-full rounded-lg text-center p-2 overflow focus:outline-none resize-y">
                            <Highlighter highlightClassName="" searchWords={tags} autoEscape={true} textToHighlight={languageThai} />
                        </div>
                    </div>
                </Box>
            )}

            <div className={`flex px-3 py-5 ${role === 'develop' ? 'justify-between' : editMode ? 'justify-between' : 'justify-center'}`}>
                {editMode ? (
                    <>
                        <div className="flex items-center">
                            <button onClick={() => handleEdit(items)} className="border border-[#357d4e] hover:bg-[#357d4e] w-9 h-9 md:w-10 md:h-10 flex items-center justify-center rounded-full focus:outline-none">
                                <IconButton className="text-[#357d4e] hover:text-[white]">
                                    <SaveSvg />
                                </IconButton>
                            </button>

                            {(items.dictionaries[0].content !== languageEnglish || items.dictionaries[1].content !== languageThai) && (
                                <div onClick={() => handleUndo(items)} className="ml-5 flex items-center cursor-pointer">
                                    <i className="fa fa-undo text-[30px]"></i>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <button onClick={() => setEditMode(true)} className="border border-[#4dc6eb] hover:bg-[#4dc6eb] w-9 h-9 md:w-10 md:h-10 flex items-center justify-center rounded-full focus:outline-none">
                        <IconButton className="text-[#4dc6eb] hover:text-[white]">
                            <EditSvg />
                        </IconButton>
                    </button>
                )}

                {editMode ? (
                    <>
                        <button className="text-[white] hover:text-[white] hover:bg-[#eb584d] w-9 h-9 md:w-10 md:h-10 rounded-full focus:outline-none" onClick={() => setEditMode(false)}>
                            <IconButton className="text-[#eb584d] hover:text-[white]">
                                <CloseSvg />
                            </IconButton>
                        </button>
                    </>
                ) : (
                    <>
                        {role === 'develop' ? (
                            <button className="border border-[#eb584d] text-[white] hover:text-[white] hover:bg-[#eb584d] w-9 h-9 md:w-10 md:h-10 rounded-full focus:outline-none" onClick={() => handleDelete(items)}>
                                <IconButton className="text-[#eb584d] hover:text-[white]">
                                    <DeleteSvg />
                                </IconButton>
                            </button>
                        ) : (
                            ''
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

const IconButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

export default CardDictionary
