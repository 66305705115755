import { useState } from 'react'
import ICON_PLUS from '../../assets/svg/icon/icon_plus.svg'
import ICON_MINUS from '../../assets/svg/icon/icon_minus.svg'
import { Translate } from '../../utils/helper'
import * as Styled from './styled'
import { ThemeProvider, Typography } from '@mui/material'
import { TemplateConfigs } from 'templates/TemplateConfigs'

const Answer2 = () => {
    const [data, setData] = useState([
        {
            id: 1,
            isShow: false,
            title: 'faqs2_ask_1',
            content: 'faqs2_answer_1'
        },
        {
            id: 2,
            isShow: false,
            title: 'faqs2_ask_2',
            content: 'faqs2_answer_2'
        },
        {
            id: 3,
            isShow: false,
            title: 'faqs2_ask_3',
            content: 'faqs2_answer_3'
        },
        {
            id: 4,
            isShow: false,
            title: 'faqs2_ask_4',
            content: 'faqs2_answer_4'
        },
        {
            id: 5,
            isShow: false,
            title: 'faqs2_ask_5',
            content: 'faqs2_answer_5'
        },
        {
            id: 6,
            isShow: false,
            title: 'faqs2_ask_6',
            content: 'faqs2_answer_6'
        },
        {
            id: 7,
            isShow: false,
            title: 'faqs2_ask_7',
            content: 'faqs2_answer_7'
        }
    ])

    const handleIsShow = id => {
        let updatedIsShow = data.map(item => {
            if (item.id === id) {
                item.isShow = !item.isShow
            }
            return item
        })
        setData(updatedIsShow)
    }

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <div className="bg-white">
                <div className="md:container md:mx-auto md:px-7 pb-10 md:pb-20">
                    <div className="text-center mb-7">
                        <Typography variant="title">{Translate('ethics_toolkit_menu_5')}</Typography>
                    </div>

                    <div className="text-[#384250] mb-7 md:mb-14 text-center px-7 md:px-0">
                        <Styled.Answer2
                            dangerouslySetInnerHTML={{
                                __html: Translate('faqs2_title_detail')
                            }}
                        />
                    </div>

                    <div>
                        {data.map((item, index) => (
                            <div key={index}>
                                <div className="bg-[#E8F0FE] p-6 md:p-7 mb-4 md:rounded ">
                                    <div className="flex justify-between">
                                        <div className="text-[24px] w-[90%]">
                                            <div className="font-semibold mb-2">
                                                {Translate('situation')} {index + 1}
                                            </div>
                                            <div className="font-normal">{Translate(item.title)}</div>
                                        </div>
                                        <div onClick={() => handleIsShow(item.id)} className="cursor-pointer pt-2 md:pt-0">
                                            {item.isShow ? <img src={ICON_MINUS} alt="" className="w-6" /> : <img src={ICON_PLUS} alt="" className="w-6" />}
                                        </div>
                                    </div>
                                    <div>
                                        {item.isShow && (
                                            <div className="bg-white p-4 md:p-5 text-[24px] rounded mt-6 md:mt-7">
                                                <div>{Translate(item.content)}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}
export default Answer2
