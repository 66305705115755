import { TemplateConfigs } from 'templates/TemplateConfigs'
import { FilterImage, Translate } from 'utils/helper'
import { Box, ThemeProvider, Typography, Stack } from '@mui/material'
import { ManagerBoard } from '../../components/common/ManagerBoard'
import ButtonFull from '@Components/common/ButtonFull'
import { useNavigate } from 'react-router-dom'

const Board = () => {
    const history = useNavigate()

    const Wrapper = {
        margin: '0 auto',
        padding: '80px 28px',

        '@media (max-width: 767px)': {
            padding: '40px 32px'
        }
    }

    const SubTitle = {
        height: '75px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <Stack className="container" sx={Wrapper}>
                <Stack textAlign="center" mb="40px" gap="20px">
                    <Typography variant="title">{Translate('honorary_advisor_directors')}</Typography>

                    <Typography variant="label">{Translate('manager_period')}</Typography>
                </Stack>

                <div className="grid grid-cols-1 md:grid-cols-6 gap-7 md:gap-3 xl:gap-7">
                    <div className="bg-white rounded-md pt-7 px-7 md:px-0 xl:px-7 text-center md:col-start-2 md:col-end-4">
                        <Typography variant="title3">{Translate('honorary_name_1')}</Typography>
                        <Box sx={SubTitle}>
                            <Typography variant="caption" color={'#707783'}>
                                {Translate('honorary_advisor')}
                            </Typography>
                        </Box>
                        <Stack flexDirection={'row'} justifyContent="center" mt="10px">
                            <img src={FilterImage('image_adviser_1')} alt={FilterImage('image_adviser_1')} width="240" height="240" />
                        </Stack>
                    </div>

                    <div className="bg-white rounded-md pt-7 px-7 md:px-0 xl:px-7 text-center md:col-start-4 md:col-end-6">
                        <Typography variant="title3">{Translate('honorary_name_2')}</Typography>
                        <Box sx={SubTitle}>
                            <Typography variant="caption" color={'#707783'}>
                                {Translate('honorary_advisor')}
                            </Typography>
                        </Box>
                        <Stack flexDirection={'row'} justifyContent="center" mt="10px">
                            <img src={FilterImage('image_adviser_2')} alt={FilterImage('image_adviser_2')} width="240" height="240" />
                        </Stack>
                    </div>

                    {ManagerBoard.map((item, index) => (
                        <div className="bg-white rounded-md pt-7 px-7 md:px-0 xl:px-7 text-center md:col-span-2" key={index}>
                            <Typography variant="title3">{Translate(item.name)}</Typography>
                            <Box sx={SubTitle}>
                                <Typography variant="caption" color={'#707783'}>
                                    {Translate(item.role)}
                                </Typography>
                            </Box>
                            <Stack flexDirection={'row'} justifyContent="center" mt="10px">
                                <img src={FilterImage(item.image)} alt={item.image} width="240" height="240" />
                            </Stack>
                        </div>
                    ))}
                </div>

                <div className="text-center mt-12">
                    <ButtonFull onClick={() => history('/former-presidents')}>{Translate('list_association_president')}</ButtonFull>
                </div>
            </Stack>
        </ThemeProvider>
    )
}

export default Board
