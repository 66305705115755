import styled from "styled-components";

export const BannerWrapper = styled.div`
    background-image: url("./img/faqs_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media (min-width: 1600px) {
        height: 760px;
    }

    @media (max-width: 834px) {
        background-image: url("./img/faqs_desktop.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 370px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/faqs_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 400px;
    }
`;

export const Answer2 = styled.div`
    font-size: 18px;
    display: flex;
    h1 {
        font-weight: 500;
    }
`;
