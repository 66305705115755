import styled from "styled-components";

export const BannerWrapper = styled.div`
    background-image: url("./img/code_admin_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 650px;

    @media (min-width: 1600px) {
        height: 760px;
    }

    @media (max-width: 834px) {
        background-image: url("./img/code_admin_desktop.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 370px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/code_admin_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 400px;
    }
`;

export const LogoHeader = styled.img`
    width: 218px;
    height: 120px;
    @media (max-width: 428px) {
        width: 146px;
        height: 80px;
    }
`;

export const Title = styled.div`
    text-align: center;
    font-size: 48px;
    color: #384250;
    margin-top: 50px;

    @media (max-width: 428px) {
        margin-top: 20px;
        font-size: 45px;
    }
`;

export const BannerWrapperSkeleton = styled.div`
    width: 100%;
    height: 650px;
    background-color: rgb(203 213 225);
    background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 60%
        )
        rgb(203 213 225);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1.5s loading ease-in-out infinite;

    @keyframes loading {
        to {
            background-position-x: -20%;
        }
    }

    @media (min-width: 1600px) {
        width: 100%;
        height: 760px;
        background-color: rgb(203 213 225);
        background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%
            )
            rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }

    @media (max-width: 428px) {
        width: 100%;
        height: 400px;
        background-color: rgb(203 213 225);
        background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%
            )
            rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }
`;
