import { createTheme } from '@mui/material'

export const TemplateConfigs = createTheme({
    typography: {
        caption: {
            fontFamily: 'IBM Plex Sans Thai',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '18px',
            color: '#384250',
            lineHeight: '30px'
        },

        caption2: {
            fontFamily: 'IBM Plex Sans Thai',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '20px',
            color: '#000000',
            lineHeight: 'normal',
            '@media (min-width: 768px)': {
                fontSize: '24px'
            }
        },

        label: {
            fontFamily: 'IBM Plex Sans Thai',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '24px',
            color: '#384250',
            lineHeight: '30px'
        },

        title: {
            fontFamily: 'IBM Plex Sans Thai',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '42px',
            color: '#000000',
            lineHeight: '50px',
            '@media (max-width: 1024px)': {
                fontSize: '38px'
            },
            '@media (max-width: 767px)': {
                fontSize: '30px'
            }
        },

        title2: {
            fontFamily: 'IBM Plex Sans Thai',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '36px',
            color: '#000000',
            lineHeight: '45px',
            '@media (max-width: 767px)': {
                fontSize: '30px'
            }
        },

        title3: {
            fontFamily: 'IBM Plex Sans Thai',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '20px',
            color: '#000000',
            lineHeight: '30px'
        },

        title4: {
            fontFamily: 'IBM Plex Sans Thai',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '32px',
            color: '#000000',
            lineHeight: '40px',

            '@media (max-width: 767px)': {
                fontSize: '28px'
            }
        }
    }
})
