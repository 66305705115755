import * as Styled from './styled'
import { Translate } from '../../utils/helper'
import { Box, ThemeProvider, Typography } from '@mui/material'
import { TemplateConfigs } from 'templates/TemplateConfigs'

const SiteMap = () => {
    const listMenu = [
        {
            name: 'sitemap_enroll_member',
            link: '/membership'
        },
        {
            name: 'menu_web_links',
            link: '/web-links'
        },
        {
            name: 'menu_wfdsa',
            link: 'https://www.wfdsa.org/'
        },
        {
            name: 'menu_contact_us',
            link: '/contact-us'
        },
        {
            name: 'menu_site_map',
            link: '/site-map'
        },
        {
            name: 'menu_faqs',
            link: '/faq'
        }
    ]

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <div className="bg-white">
                <Styled.BannerWrapper />

                <div className="container mx-auto px-7 py-10 md:py-20">
                    <Box textAlign="center">
                        <Typography variant="title">{Translate('sitemap')}</Typography>
                    </Box>

                    <div className="grid grid-cols-1 md:grid-cols-3 border-b border-[#9EA9B9] py-10">
                        <div className="col-span-1 text-[36px] text-[#384250] mb-4 md:mb-0">{Translate('sm_menu_about_us')}</div>
                        <div className="md:col-span-2 text-[18px] text-[#3375E5]">
                            <div className="mb-4">
                                <a href="/#1">{Translate('message_pm')}</a>
                            </div>
                            <div className="mb-4">
                                <a href="/#2">{Translate('sm_about_us_history')}</a>
                            </div>
                            <div className="mb-4">
                                <a href="/#3">{Translate('sm_about_us_policy_tdsa')}</a>
                            </div>
                            <div className="mb-4">
                                <a href="/#4">{Translate('sm_honorary_advisor_directors')}</a>
                            </div>
                            <div className="mb-4">
                                <a href="/#5">{Translate('sm_member_company_tdsa')}</a>
                            </div>
                            <div className="mb-4">
                                <a href="/#6">{Translate('sm_about_us_wfdsa_title')}</a>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 border-b border-[#9EA9B9] py-10">
                        <div className="col-span-1 text-[36px] text-[#384250] mb-4 md:mb-0">{Translate('sm_menu_direct_selling')}</div>
                        <div className="md:col-span-2 text-[18px] text-[#3375E5]">
                            <div className="mb-4">
                                <a href="/direct-selling#1">{Translate('sm_direct_selling_knowledge')}</a>
                            </div>

                            <div className="mb-4">
                                <a href="/direct-selling#2">{Translate('sm_question_knowledge_statistics')}</a>
                            </div>

                            <div className="mb-4">
                                <a href="/direct-selling#3">{Translate('sm_ponzi_scheme')}</a>
                            </div>

                            <div className="mb-4">
                                <a href="/direct-selling#4">{Translate('sm_direct_selling_investment')}</a>
                            </div>

                            <div>
                                <a href="/direct-selling#5">{Translate('sm_direct_selling_act_title2')}</a>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 border-b border-[#9EA9B9] py-10">
                        <div className="col-span-1 text-[36px] text-[#384250] mb-4 md:mb-0">{Translate('sm_menu_tdsa')}</div>
                        <div className="md:col-span-2 text-[18px] text-[#3375E5]">
                            <div className="mb-4">
                                <a href="/global-code-of-ethic">{Translate('sm_global_code')}</a>
                            </div>
                            <div className="flex flex-wrap gap-x-4 md:gap-x-4">
                                <div className="mb-4 md:mb-0">
                                    <a href="/global-code-of-ethic">{Translate('sm_direct_selling_ethics')}</a>
                                </div>

                                <div className="mb-4 md:mb-0">
                                    <a href="/ethicstoolkit">{Translate('sm_ethics_toolkit')}</a>
                                </div>

                                <div className="mb-4 md:mb-0">
                                    <a href="/code-administrator">{Translate('sm_code_administrator')}</a>
                                </div>

                                <div className="mb-4">
                                    <a href="/complaint">{Translate('sm_complaint')}</a>
                                </div>
                            </div>
                            <div>
                                <a href="/global-code-of-ethic">{Translate('sm_about_direct_selling')}</a>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 border-b border-[#9EA9B9] py-10">
                        <div className="col-span-1 text-[36px] text-[#384250] mb-4 md:mb-0">{Translate('sm_menu_question')}</div>

                        <div className="md:col-span-2 text-[18px] text-[#3375E5]">
                            <div className="mb-4">
                                <a href="/member#1">{Translate('sm_question_qualifications_detail')}</a>
                            </div>

                            <div className="mb-4">
                                <a href="/member#2">{Translate('sm_question_qualifications_title2')}</a>
                            </div>

                            <div className="mb-4">
                                <a href="/member#3">{Translate('sm_question_qualifications_title3')}</a>
                            </div>

                            <div className="mb-4">
                                <a href="/member#4">{Translate('sm_question_qualifications_title4')}</a>
                            </div>

                            <div className="mb-4">
                                <a href="/member#5">{Translate('sm_membership_8_title')}</a>
                            </div>

                            <div className="flex flex-wrap gap-x-4 md:mb-4 md:gap-x-8">
                                <div className="mb-4">
                                    <a href="/member#6">{Translate('sm_question_form_apply')}</a>
                                </div>

                                <div className="mb-4">
                                    <a href="/member#6">{Translate('sm_question_articles_association')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-4 md:justify-between py-10">
                        {listMenu.map((item, index) => (
                            <div className="text-[18px] text-[#3375E5]" key={index}>
                                <a href={item.link}>{Translate(item.name)}</a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}
export default SiteMap
