import React, { useState } from 'react'
import Service from '../../service'
import { ReactComponent as UploadIcon } from '../../assets/svg/icon/clarity_upload-cloud-line.svg'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

const ModalUpload = ({ imageSrc, keyImage, setModal, handleGetImage, action, type }) => {
    const [imageKey, setImageKey] = useState(keyImage ? keyImage : '')
    const [uploadFiles, setUploadFiles] = useState([])
    const [preview, setPreview] = useState(imageSrc ? imageSrc : '')
    const [isLoading, setIsloading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [error, setError] = useState('')
    const [size, setSize] = useState('')

    let token = localStorage.getItem('TDSA_TOKEN')

    const handleClickUpload = async () => {
        setIsloading(true)
        const [file = null] = uploadFiles || []
        try {
            if (file) {
                if (action === 'create') {
                    const res = await Service.uploadUrl({
                        token,
                        fileName: file.name,
                        imageKey: type === 'image' ? `image_${imageKey}` : `pdf_${imageKey}`,
                        type
                    })
                    if (res) {
                        if (type === 'pdf') {
                            await Service.uploadFilePDF({
                                uploadUrl: res.data.uploadUrl,
                                file
                            })
                        } else {
                            await Service.uploadFile({
                                uploadUrl: res.data.uploadUrl,
                                file
                            })
                        }
                        let save = await Service.saveData({
                            token,
                            imageKey: type === 'image' ? `image_${imageKey}` : `pdf_${imageKey}`,
                            src: res.data.src
                        })

                        if (save) {
                            setIsloading(false)
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Successfully',
                                showConfirmButton: false,
                                timer: 1500
                            }).then(() => {
                                handleGetImage()
                                setModal(false)
                            })
                        }
                    }
                }

                if (action === 'edit') {
                    await Service.deleteImage(token, keyImage)

                    const res = await Service.uploadUrl({
                        token,
                        fileName: file.name,
                        imageKey
                    })
                    if (res) {
                        if (type === 'pdf') {
                            await Service.uploadFilePDF({
                                uploadUrl: res.data.uploadUrl,
                                file
                            })
                        } else {
                            await Service.uploadFile({
                                uploadUrl: res.data.uploadUrl,
                                file
                            })
                        }

                        Service.saveData({
                            token,
                            imageKey,
                            src: res.data.src
                        }).then(() => {
                            setIsloading(false)
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Successfully',
                                showConfirmButton: false,
                                timer: 1500
                            }).then(() => {
                                handleGetImage()
                                setModal(false)
                            })
                        })
                    }
                }
            }
        } catch (error) {
            console.log(error)
            setIsloading(false)
        }
    }

    const handleUpload = e => {
        setUploadFiles(e.target.files)
        const fileSize = e.target.files[0]?.size
        const sizeKB = fileSize / 1024
        const sizeMB = sizeKB / 1024
        setSize(sizeMB.toFixed(2))

        if (type === 'pdf') {
            if (e.target.files[0]?.size < 5242880) {
                setPreview(URL.createObjectURL(e.target.files[0]))
            } else {
                setError('ขนาดไฟล์ต้องไม่เกิน 5MB')
            }
        } else if (type === 'image') {
            if (e.target.files[0]?.size < 2097152) {
                const reader = new FileReader()
                reader.addEventListener('load', () => {
                    setPreview(reader.result)
                })
                reader.readAsDataURL(e.target.files[0])
            } else {
                setError('ขนาดไฟล์ต้องไม่เกิน 2MB')
            }
        }
    }

    const onDocumentLoadSuccess = ({ numPage }) => {
        setPageNumber(numPage)
        setPageNumber(1)
    }

    return (
        <>
            <div className="fixed top-0 left-0 right-0 flex justify-center items-center w-full overflow-x-hidden overflow-y-auto inset-0 h-full bg-black bg-opacity-80" style={{ zIndex: 1 }}>
                <div className="relative w-full h-auto max-w-md p-4">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button
                            type="button"
                            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                            onClick={() => setModal(false)}>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"></path>
                            </svg>
                        </button>
                        <div className="px-6 py-6 lg:px-8">
                            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">New</h3>
                            <div>
                                <label htmlFor="dictionaryKey" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    {type === 'pdf' ? 'PDF Key' : 'Image Key'}
                                </label>
                                <input
                                    type="text"
                                    name="image_key"
                                    value={imageKey}
                                    disabled={action === 'edit' ? true : false}
                                    onChange={e => setImageKey(e.target.value)}
                                    className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-blue-500 block w-full p-2.5"
                                />
                            </div>

                            <div className="mt-5">
                                <label htmlFor="dictionaryKey" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    {type === 'pdf' ? 'Upload PDF (ไฟล์ PDF เท่านั้น)' : 'Upload Image (ไฟล์ jpg, png เท่านั้น)'}
                                </label>

                                <label htmlFor="upload" className="relative">
                                    <input type="file" accept="image/*,application/pdf" className="h-10 w-full hidden" id="upload" onChange={handleUpload} />
                                    <div className="top-0 flex justify-center w-full cursor-pointer">
                                        <div className="">
                                            <div className="flex justify-center">
                                                <UploadIcon />
                                            </div>

                                            <div className="underline text-[#003B6F]">Upload file</div>
                                        </div>
                                    </div>
                                </label>

                                {type === 'pdf' ? (
                                    <>
                                        {preview && (
                                            <div className="flex justify-center mt-4 border py-2">
                                                <div>
                                                    <Document file={preview} onLoadSuccess={onDocumentLoadSuccess}>
                                                        <Page height={250} width={250} pageNumber={pageNumber} />
                                                    </Document>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {preview && (
                                            <div className="flex justify-center mt-4 border py-2">
                                                <div>
                                                    <ImageCard src={preview} alt={'previewImage'} />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                                {size && <div className="flex justify-center mt-2">Size {size} MB</div>}

                                {error && (
                                    <div className="flex justify-center">
                                        <TextError>{error}</TextError>
                                    </div>
                                )}

                                <ButtonCreate onClick={() => handleClickUpload()} disabled={imageKey && preview ? false : true} className={`mt-5 px-5 ${imageKey && preview ? 'bg-blue-700 hover:bg-blue-800' : 'bg-gray-500'} `}>
                                    {isLoading && <span className="lds-dual-ring mr-2"></span>}
                                    <span>CREATE</span>
                                </ButtonCreate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const ButtonCreate = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
    width: 100%;
    font-weight: 500;
    border-radius: 0.5rem;
    height: 2.5rem;
`

const ImageCard = styled.img`
    max-width: 180px;
`

const TextError = styled.span`
    font-size: 14px;
    color: red;
    text-align: center;
    margin-top: 20px;
`

export default ModalUpload
