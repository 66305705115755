import React, { useState, useEffect, useRef, useContext } from 'react'
import * as Styled from './styled'
import ButtonFull from '../../components/common/ButtonFull'
import { FilterImage, Translate } from '../../utils/helper'
import ImgTDSA_EN from '../../assets/svg/device_1.svg'
import ImgTDSA_TH from '../../assets/images/TDSA-new.png'
import { useLocation } from 'react-router-dom'
import ArrowBottom from '../../assets/svg/icon/arrow-bottom.svg'
import Answer from './answer'
import Answer2 from './answer2'
import { Box, Stack, ThemeProvider, Typography } from '@mui/material'
import { TemplateConfigs } from 'templates/TemplateConfigs'
import { LanguageContext } from 'contexts/LanguageContext'
import { DownloadPDF } from '@Components/common/DownloadPDF'

const menu = [
    {
        name: 'ethics_toolkit_menu_1',
        scroll: '#1'
    },
    {
        name: 'ethics_toolkit_menu_2',
        scroll: '#2'
    },
    {
        name: 'ethics_toolkit_menu_3',
        scroll: '#3'
    },
    {
        name: 'ethics_toolkit_menu_4',
        scroll: '#4'
    },
    {
        name: 'ethics_toolkit_menu_5',
        scroll: '#5'
    },
    {
        name: 'ethics_toolkit_menu_6',
        scroll: '#6'
    }
]

const menuMobile = [
    {
        name: 'ethics_toolkit_menu_2',
        scroll: '#2'
    },
    {
        name: 'ethics_toolkit_menu_3',
        scroll: '#3'
    },
    {
        name: 'ethics_toolkit_menu_4',
        scroll: '#4'
    },
    {
        name: 'ethics_toolkit_menu_5',
        scroll: '#5'
    },
    {
        name: 'ethics_toolkit_menu_6',
        scroll: '#6'
    }
]

const EthiceToolkit = () => {
    const [open, setOpen] = useState(false)
    // const { language } = useContext(LanguageContext);
    const path = useLocation()

    const ethics1 = useRef()
    const ethics2 = useRef()
    const ethics3 = useRef()
    const ethics4 = useRef()
    const ethics5 = useRef()
    const ethics6 = useRef()
    const ethics7 = useRef()
    const ethics8 = useRef()

    const { language } = useContext(LanguageContext)

    useEffect(() => {
        setTimeout(() => {
            if (path.hash === '#1') {
                ethics1.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#2') {
                ethics2.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#3') {
                ethics3.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#4') {
                ethics4.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#5') {
                ethics5.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#6') {
                ethics6.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#7') {
                ethics7.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#8') {
                ethics8.current.scrollIntoView({ behavior: 'smooth' })
            }
        }, 500)
    }, [path.hash])

    useEffect(() => {
        document.documentElement.style.scrollBehavior = 'smooth'
        return () => {
            document.documentElement.style.scrollBehavior = 'auto'
        }
    }, [])

    const handleArrow = () => {
        setOpen(!open)
    }

    const wfds_Ethics_th = FilterImage('pdf_wfds_ethics_th')
    const wfds_Ethics_en = FilterImage('pdf_wfds_ethics_en')

    const download_wfds_Ethics_file = language === 'th' ? FilterImage('pdf_wfds_ethics_th') : FilterImage('pdf_wfds_ethics_en')
    const download_wfds_Ethics_name = language === 'th' ? 'pdf_wfds_ethics_th' : 'pdf_wfds_ethics_en'

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <div className="bg-white">
                <Styled.BannerWrapper>
                    <div className="hidden xl:block">
                        <div className="px-12 mb-12">
                            <div className="grid grid-cols-6 gap-2 text-[20px] text-[#384250] text-center">
                                {menu.map((item, index) => (
                                    <a href={item.scroll} className="px-3 py-2 bg-white rounded flex items-center justify-center cursor-pointer hover:bg-[#3375E5] hover:text-white" key={index}>
                                        {Translate(item.name)}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="xl:hidden container mx-auto px-2 md:px-7 mb-8 text-center text-[20px] md:text-[24px]">
                        <div className="w-full bg-[#3375E5] px-2 md:px-5 py-4 rounded shadow-2xl text-white">
                            <div className="flex justify-center relative">
                                <a href="#1" className="hover:text-white">
                                    {Translate('ethics_toolkit_menu_1')}
                                </a>
                                <div className="flex items-center absolute right-0 h-full" onClick={handleArrow}>
                                    <img src={ArrowBottom} alt="" className={`transition duration-150 ease-in-out ${open && '-rotate-180'}`} />
                                </div>
                            </div>
                        </div>
                        {open && (
                            <div className="relative">
                                <div className="absolute w-full">
                                    {menuMobile.map((item, index) => (
                                        <div className="w-full bg-white px-2 md:px-5 py-4 mt-2 rounded shadow-2xl" key={index}>
                                            <a href={item.scroll} key={index}>
                                                {Translate(item.name)}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </Styled.BannerWrapper>

                <Box
                    className="container mx-auto px-7 py-10 md:py-20"
                    id="1"
                    ref={ethics1}
                    sx={{
                        scrollMarginTop: '2rem'
                    }}>
                    <Stack textAlign="center" mb="40px" gap="20px">
                        <Typography variant="title">{Translate('ethics_toolkit_menu_1')}</Typography>
                    </Stack>

                    <div className="bg-[#E8F0FE] px-5 md:px-[120px] py-6 rounded-lg">
                        <div className="flex flex-col md:flex-row mb-5 md:mb-3 gap-3">
                            <Box className="md:w-1/3 mb-2 md:mb-0">
                                <Typography variant="title4" fontWeight={500} fontSize="28px">
                                    {Translate('ethics_toolkit_defense')}
                                </Typography>
                            </Box>
                            <div className="flex text-[18px] gap-y-2 gap-x-20 flex-wrap md:gap-24 items-center">
                                <div className="flex">
                                    <div>•</div>
                                    <div className="ml-2">{Translate('ethics_toolkit_defense_detail1')}</div>
                                </div>
                                <div className="flex">
                                    <div>•</div>
                                    <div className="ml-2">{Translate('ethics_toolkit_defense_detail2')}</div>
                                </div>
                                <div className="flex">
                                    <div>•</div>
                                    <div className="ml-2">{Translate('ethics_toolkit_defense_detail3')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row mb-5 md:mb-3 gap-3">
                            <Box className="md:w-1/3 mb-2 md:mb-0">
                                <Typography variant="title4" fontWeight={500} fontSize="28px">
                                    {Translate('ethics_toolkit_confidence')}
                                </Typography>
                            </Box>
                            <div className="flex text-[18px] gap-24 items-center">
                                <div className="flex">
                                    <div>•</div>
                                    <div className="ml-2">{Translate('ethics_toolkit_confidence_detail1')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row mb-5 md:mb-3 gap-3">
                            <Box className="md:w-1/3 mb-2 md:mb-0">
                                <Typography variant="title4" fontWeight={500} fontSize="28px">
                                    {Translate('ethics_toolkit_establish')}
                                </Typography>
                            </Box>

                            <div className="flex text-[18px] gap-24 items-center">
                                <div className="flex">
                                    <div>•</div>
                                    <div className="ml-2">{Translate('ethics_toolkit_establish_detail1')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-3">
                            <Box className="md:w-1/3 mb-2 md:mb-0">
                                <Typography variant="title4" fontWeight={500} fontSize="28px">
                                    {Translate('ethics_toolkit_build_confidence')}
                                </Typography>
                            </Box>

                            <div className="flex text-[18px] flex-col">
                                <div className="flex">
                                    <div>•</div>
                                    <div className="ml-2">{Translate('ethics_toolkit_build_confidence_detail1')}</div>
                                </div>
                                <div className="flex">
                                    <div>•</div>
                                    <div className="ml-2">{Translate('ethics_toolkit_build_confidence_detail2')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>

                <Box
                    id="2"
                    ref={ethics2}
                    sx={{
                        scrollMarginTop: '6rem'
                    }}>
                    <div className="container mx-auto px-7 pb-10 md:pb-20 text-center">
                        <Stack mb="20px">
                            <Typography variant="title">{Translate('ethics_toolkit_title_name')}</Typography>
                        </Stack>

                        <div
                            className="text-[24px] text-[#384250] mb-4 sm:mb-6"
                            dangerouslySetInnerHTML={{
                                __html: Translate('ethics_toolkit_title')
                            }}></div>

                        <div className="text-[18px] text-[#384250] md:px-[150px]">{Translate('ethics_toolkit_title_detail')}</div>
                    </div>

                    <div className="md:container mx-auto md:px-7">
                        <div className="bg-[#C7DDFB] text-center py-10 px-5">
                            <Styled.Title1
                                dangerouslySetInnerHTML={{
                                    __html: Translate('ethics_toolkit_subtitle1')
                                }}
                            />
                        </div>
                    </div>

                    <div className="pt-8 container mx-auto px-7 md:mb-12">
                        <Styled.Content1
                            dangerouslySetInnerHTML={{
                                __html: Translate('ethics_toolkit_subtitle1_content_1')
                            }}
                        />
                    </div>

                    <div className="md:container mx-auto md:px-7">
                        <div className="bg-[#C7DDFB] text-center py-10 px-5">
                            <Styled.Title1
                                dangerouslySetInnerHTML={{
                                    __html: Translate('ethics_toolkit_subtitle2')
                                }}
                            />
                        </div>
                    </div>
                    <div className="container mx-auto px-7">
                        <div className="text-[18px] text-[#384250] text-left py-8">{Translate('ethics_toolkit_subtitle2_content')}</div>
                    </div>
                </Box>

                <Styled.BackgroundWrapper />

                <div className="container mx-auto px-7">
                    <Box
                        className="py-7 md:py-16"
                        id="3"
                        ref={ethics3}
                        sx={{
                            scrollMarginTop: '7rem'
                        }}>
                        <div className="py-5">
                            <Styled.Title5
                                dangerouslySetInnerHTML={{
                                    __html: Translate('ethics_toolkit_subtitle6')
                                }}
                            />
                        </div>

                        <Box
                            className="py-5"
                            id="4"
                            ref={ethics4}
                            sx={{
                                scrollMarginTop: '7rem'
                            }}>
                            <Styled.Title5
                                dangerouslySetInnerHTML={{
                                    __html: Translate('ethics_toolkit_subtitle5')
                                }}
                            />
                        </Box>

                        <div className="py-5">
                            <Styled.Title3
                                dangerouslySetInnerHTML={{
                                    __html: Translate('ethics_toolkit_subtitle3')
                                }}
                            />
                        </div>

                        <div className="py-5">
                            <Styled.Title4
                                dangerouslySetInnerHTML={{
                                    __html: Translate('ethics_toolkit_subtitle4')
                                }}
                            />
                        </div>
                    </Box>
                </div>

                <Box
                    id="5"
                    ref={ethics5}
                    sx={{
                        scrollMarginTop: '7rem'
                    }}>
                    <Answer2 />
                </Box>

                <Box
                    id="6"
                    ref={ethics6}
                    sx={{
                        scrollMarginTop: '7rem'
                    }}>
                    <Answer />
                </Box>

                <Box
                    className="container mx-auto px-7 pb-10"
                    id="7"
                    ref={ethics7}
                    sx={{
                        scrollMarginTop: '6rem'
                    }}>
                    <div className="text-center">
                        <Stack mb="20px">
                            <Typography variant="title">{Translate('code_conduct')}</Typography>
                        </Stack>

                        <ButtonFull onClick={() => DownloadPDF(download_wfds_Ethics_file, download_wfds_Ethics_name)}>{Translate('download')}</ButtonFull>
                    </div>

                    <div className="pt-16">
                        <a href={language === 'th' ? wfds_Ethics_th : wfds_Ethics_en} alt="wfds_Ethics" target="_blank" rel="noopener noreferrer">
                            <img src={language === 'th' ? ImgTDSA_TH : ImgTDSA_EN} alt={'ImgTDSA'} className="w-full" />
                        </a>
                    </div>
                </Box>

                <div className="bg-[#E5EBF1] py-10">
                    <div className="container mx-auto px-7">
                        <div className="flex justify-center flex-col sm:flex-row items-center">
                            <div className="text-left sm:text-right text-[34px] xl:text-[36px] px-5">
                                <div className="text-[#3375E5] mb-3">{Translate('ethics_toolkit_title_name')}</div>
                            </div>
                            <div className="flex mt-9 sm:mt-0 items-center">
                                <div className="border-[4px] border-[#FFBF3A] rounded-md sm:mx-6 h-32" />

                                <div className="bg-white p-12 text-[17px] ml-3 sm:text-[24px] rounded-xl">
                                    <div className="mb-3">
                                        <span className="font-bold mr-3">{Translate('ethics_toolkit_confidence2')}</span>
                                        <span>{Translate('ethics_toolkit_confidence2_detail')}</span>
                                    </div>
                                    <div className="mb-3">
                                        <span className="font-bold mr-3">{Translate('ethics_toolkit_create')}</span>
                                        <span>{Translate('ethics_toolkit_create_detail')}</span>
                                    </div>
                                    <div>
                                        <span className="font-bold mr-3">{Translate('ethics_toolkit_give')}</span>
                                        <span>{Translate('ethics_toolkit_give_detail')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}
export default EthiceToolkit
