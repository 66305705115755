export const MemberShip1 = ['membership_1_1', 'membership_1_2', 'membership_1_3', 'membership_1_4', 'membership_1_5']

export const MemberShip2 = ['membership_2_1', 'membership_2_2', 'membership_2_3', 'membership_2_4', 'membership_2_5', 'membership_2_6']

export const MemberShip3 = ['membership_3_1', 'membership_3_2', 'membership_3_3', 'membership_3_4', 'membership_3_5', 'membership_3_6', 'membership_3_7', 'membership_3_8', 'membership_3_9', 'membership_3_10']

export const MemberShip7 = ['membership_7_1', 'membership_7_2', 'membership_7_3', 'membership_7_4', 'membership_7_5']

export const MemberShip7Detail = ['membership_7_5_1', 'membership_7_5_2', 'membership_7_5_3', 'membership_7_5_4']

export const MemberShip8 = ['membership_8_1', 'membership_8_2', 'membership_8_3', 'membership_8_4', 'membership_8_5']
