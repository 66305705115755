import { useState, useEffect, useRef, useContext } from 'react'
import * as Styled from './styled'
import DirectImage from '../../assets/images/direct_selling_3.svg'
import ButtonFull from '../../components/common/ButtonFull'
import ArrowBottom from '../../assets/svg/icon/arrow-bottom.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { Translate } from '../../utils/helper'
import { LanguageContext } from '../../contexts/LanguageContext'
import Investment from './investment'
import { FilterImage } from '../../utils/helper'
import { DownloadPDF } from '@Components/common/DownloadPDF'
import { Box, Stack, ThemeProvider, Typography } from '@mui/material'
import { TemplateConfigs } from 'templates/TemplateConfigs'
import Act from './act'

const menu = [
    {
        name: 'direct_selling_knowledge',
        scroll: '#1'
    },
    {
        name: 'question_knowledge_statistics',
        scroll: '#2'
    },
    {
        name: 'ponzi_scheme',
        scroll: '#3'
    },
    {
        name: 'direct_selling_investment',
        scroll: '#4'
    },
    {
        name: 'direct_selling_act_title2',
        scroll: '#5'
    }
]

const menuMobile = [
    {
        name: 'question_knowledge_statistics',
        scroll: '#2'
    },
    {
        name: 'ponzi_scheme',
        scroll: '#3'
    },
    {
        name: 'direct_selling_investment',
        scroll: '#4'
    },
    {
        name: 'direct_selling_act_title2',
        scroll: '#5'
    }
]

const listOne = [
    {
        key: 'direct_selling_content_1'
    },
    {
        key: 'direct_selling_content_3'
    },
    {
        key: 'direct_selling_content_4'
    }
]

const GraphList = [
    {
        image: 'image_total_retail_sales'
    },
    {
        image: 'image_total_net_sales'
    },
    {
        image: 'image_total_direct_sellers'
    }
]

const DirectSelling = () => {
    const [open, setOpen] = useState(false)

    const path = useLocation()

    const direct1 = useRef()
    const direct2 = useRef()
    const direct3 = useRef()
    const direct4 = useRef()
    const direct5 = useRef()

    const history = useNavigate()

    const { language } = useContext(LanguageContext)

    const GlobalBusiness_TDSAStatistical = FilterImage('pdf_business_growth_statistics')
    const The_history_th = FilterImage('pdf_the_history_th')
    const The_history_en = FilterImage('pdf_the_history_en')

    useEffect(() => {
        setTimeout(() => {
            if (path.hash === '#1') {
                direct1.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#2') {
                direct2.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#3') {
                direct3.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#4') {
                direct4.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#5') {
                direct5.current.scrollIntoView({ behavior: 'smooth' })
            }
        }, 1000)
    }, [path.hash])

    useEffect(() => {
        setOpen(false)
    }, [path])

    useEffect(() => {
        document.documentElement.style.scrollBehavior = 'smooth'
        return () => {
            document.documentElement.style.scrollBehavior = 'auto'
        }
    }, [])

    const handleArrow = () => {
        setOpen(!open)
    }

    const handleDownload = () => {
        if (language === 'th') {
            DownloadPDF(The_history_th, 'The_history_th')
        } else {
            DownloadPDF(The_history_en, 'The_history_en')
        }
    }

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <div className="bg-[#F6F9FD]">
                <Styled.BannerWrapper>
                    <div className="hidden xl:block">
                        <div className="px-12 mb-12">
                            <div className="grid grid-cols-5 gap-4 text-[24px] text-[#384250] text-center">
                                {menu.map((item, index) => (
                                    <a href={item.scroll} className="px-5 py-2 bg-white rounded flex items-center justify-center cursor-pointer hover:bg-[#3375E5] hover:text-white" key={index}>
                                        {Translate(item.name)}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="xl:hidden container mx-auto px-2 md:px-7 mb-8 text-center text-[20px] md:text-[24px]">
                        <div className="w-full bg-[#3375E5] px-2 md:px-5 py-4 rounded shadow-2xl text-white">
                            <div className="flex justify-center relative">
                                <a href="#1" className="hover:text-white">
                                    {Translate('direct_selling_knowledge')}
                                </a>
                                <div className="flex items-center absolute right-0 h-full" onClick={handleArrow}>
                                    <img src={ArrowBottom} alt="" className={`transition duration-150 ease-in-out ${open && '-rotate-180'}`} />
                                </div>
                            </div>
                        </div>
                        {open && (
                            <div className="relative z-40">
                                <div className="absolute w-full">
                                    {menuMobile.map((item, index) => (
                                        <div key={index} className="w-full bg-white px-2 md:px-5 py-4 mt-2 rounded shadow-2xl">
                                            <a href={item.scroll} key={index}>
                                                {Translate(item.name)}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </Styled.BannerWrapper>

                <Box
                    className="container mx-auto px-8 md:px-7 py-10 md:py-20 text-[#384250] text-center"
                    id="1"
                    ref={direct1}
                    sx={{
                        scrollMarginTop: '2rem'
                    }}>
                    <Stack textAlign="center" mb="40px" gap="20px">
                        <Typography variant="title">{Translate('direct_selling_knowledge')}</Typography>

                        <Typography variant="caption">{Translate('direct_selling_detail')}</Typography>
                    </Stack>

                    <div className="flex justify-center mt-12">
                        <ButtonFull onClick={() => handleDownload()}>{Translate('download')}</ButtonFull>
                    </div>
                </Box>

                <div className="">
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-2">
                        <div className="bg-[#E5EBF1] flex items-center">
                            <Styled.Detail className="md:w-4/5 py-7 xl:py-0">
                                <div className="text-[32px] mb-5">{Translate('direct_selling_benefit')}</div>

                                <Styled.Content
                                    dangerouslySetInnerHTML={{
                                        __html: Translate('direct_selling_benefit_detail')
                                    }}
                                />
                            </Styled.Detail>
                        </div>

                        <Styled.Image className="bg-[#F6F9FD] pl-10 py-7 md:py-0">
                            <img src={DirectImage} alt={DirectImage} className="w-full" />
                        </Styled.Image>
                    </div>
                </div>

                <div className="container mx-auto px-8 md:px-7">
                    <div className="py-10 md:py-[30px]">
                        {listOne.map((item, index) => (
                            <div className="py-[30px]" key={index}>
                                <Styled.Content1
                                    dangerouslySetInnerHTML={{
                                        __html: Translate(item.key)
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="bg-white">
                    <Box
                        className="container mx-auto px-8 md:px-7 py-10 md:py-20 text-center"
                        ref={direct2}
                        id="2"
                        sx={{
                            scrollMarginTop: '2rem'
                        }}>
                        <Box textAlign="center">
                            <Box mb="30px">
                                <Typography variant="title">{Translate('question_knowledge_statistics')}</Typography>
                            </Box>

                            <ButtonFull onClick={() => DownloadPDF(GlobalBusiness_TDSAStatistical, 'GlobalBusiness_TDSAStatistical')}>{Translate('download')}</ButtonFull>
                        </Box>

                        {GraphList.map((item, index) => (
                            <div className="mt-14" key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        img: {
                                            width: '100%'
                                        },

                                        '@media (min-width: 1440px)': {
                                            img: {
                                                width: '85%'
                                            }
                                        }
                                    }}>
                                    <img src={FilterImage(item.image)} alt={item.image} />
                                </Box>
                            </div>
                        ))}
                    </Box>

                    <Styled.BackgroundWrapper2 className="pb-20">
                        <Box
                            className="container mx-auto px-7"
                            id="3"
                            ref={direct3}
                            sx={{
                                scrollMarginTop: '7rem'
                            }}>
                            <div className="text-center mb-10">
                                <Box mb="15px">
                                    <Typography variant="title">{Translate('ponzi_scheme')}</Typography>
                                </Box>

                                <Box mb="20px">
                                    <Typography variant="title4" color={'#3375E5'}>
                                        {Translate('comparison_table')}
                                    </Typography>
                                </Box>

                                <ButtonFull onClick={() => history('/pyramid-scheme')}>{Translate('click_detail')}</ButtonFull>
                            </div>

                            <div className="grid grid-cols-1 xl:grid-cols-2 py-4">
                                <div className="">
                                    <div
                                        className="wistia_responsive_padding"
                                        style={{
                                            padding: '56.25% 0 0 0',
                                            position: 'relative'
                                        }}>
                                        <div
                                            className="wistia_responsive_wrapper"
                                            style={{
                                                height: '100%',
                                                left: '0',
                                                position: 'absolute',
                                                top: '0',
                                                width: '100%'
                                            }}>
                                            <iframe
                                                src="https://fast.wistia.net/embed/iframe/9wbvjpoip2?videoFoam=true&muted=false"
                                                title="TDSA_Introduction Video"
                                                allow="autoplay; fullscreen"
                                                allowtransparency="true"
                                                frameBorder="0"
                                                scrolling="no"
                                                className="wistia_embed"
                                                name="wistia_embed"
                                                msallowfullscreen="true"
                                                width="100%"
                                                height="100%"></iframe>
                                        </div>
                                    </div>
                                    <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
                                </div>
                                <div className="xl:px-14 flex items-center pt-10 xl:pt-0">
                                    <div>
                                        <Styled.PonziScheme
                                            className="mb-4"
                                            dangerouslySetInnerHTML={{
                                                __html: Translate('ponzi_scheme_title_1')
                                            }}></Styled.PonziScheme>
                                        <Styled.PonziScheme2
                                            dangerouslySetInnerHTML={{
                                                __html: Translate('ponzi_scheme_title_2')
                                            }}></Styled.PonziScheme2>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Styled.BackgroundWrapper2>
                </div>

                <Box
                    id="4"
                    ref={direct4}
                    sx={{
                        scrollMarginTop: '2rem'
                    }}>
                    <Investment />
                </Box>

                <Box
                    id="5"
                    ref={direct5}
                    sx={{
                        scrollMarginTop: '7rem'
                    }}>
                    <Act />
                </Box>

                <Stack
                    className="container mx-auto px-7"
                    sx={{
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        width: '100%',
                        alignItems: 'center',
                        gap: '50px',
                        '@media (min-width: 768px)': {
                            paddingTop: '50px',
                            paddingBottom: '50px',
                        }
                    }}>
                    <Box sx={{
                        width: '100%',
                        textAlign: 'center',
                        '@media (min-width: 768px)': {
                            width: '75%'
                        }
                    }}>
                        <Box sx={{
                            marginBottom: '15px',
                            '@media (min-width: 768px)': {
                                marginBottom: '25px',
                            }
                        }}>
                            <Typography variant="title">{Translate('women_empowerment')}</Typography>
                        </Box>

                        <Box>
                            <Typography variant="caption2">
                                {Translate('women_empowerment_description')}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        video: {
                            width: '100%'
                        },
                        '@media (min-width: 768px)': {
                            video: {
                                width: '75%'
                            },
                        }
                    }}>
                        <video controls>
                            <source src={Translate('women_empowerment_video')} type="video/mp4" />
                        </video>
                    </Box>
                </Stack>
            </div>
        </ThemeProvider>
    )
}

export default DirectSelling
