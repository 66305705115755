import axios from 'axios'

const memberCall2 = 'https://member-calls2.unicity.com'

const toFileExtension = fileName => {
    const [extension = null] = /\w{3,4}($|\?)/.exec(fileName) || []
    return extension
}

const token = localStorage.getItem('TDSA_TOKEN')

const Service = {
    login: data => {
        const url = `${memberCall2}/tdsa/user/login?expiresIn=7d`

        return axios.post(url, data, {
            headers: {
                'Content-Type': 'applicaton/json'
            }
        })
    },

    changePassword: (token, data) => {
        const url = `${memberCall2}/tdsa/user/self`

        return axios.patch(url, data, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    refreshToken: token => {
        const url = `${memberCall2}/tdsa/user/refreshToken`

        return axios.get(url, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    getDictionaryAll: data => {
        const url = `${memberCall2}/tdsa/dictionary/groupKeyItems?isArchive=0${data}&limit=200`

        return axios.get(url, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    getDictionaryPage: () => {
        const url = `${memberCall2}/tdsa/dictionary/groupKeyItems?&limit=1000`

        return axios.get(url, {
            headers: {
                'Content-Type': 'applicaton/json'
            }
        })
    },

    createDictionary: (token, data) => {
        const url = `${memberCall2}/tdsa/dictionary/data`

        return axios.post(url, data, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    removeDictionary: (token, data, page) => {
        const url = `${memberCall2}/tdsa/dictionary/data/${data}?pageId=${page}`

        return axios.delete(url, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    editDictionary: (token, data) => {
        const url = `${memberCall2}/tdsa/dictionary/data`

        return axios.patch(url, data, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    uploadUrl: ({ token, fileName, imageKey, type }) => {
        const url = `${memberCall2}/tdsa/images/getUploadUrl`
        const data = {
            imageKey,
            fileName
        }
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    getImage: () => {
        const url = `${memberCall2}/tdsa/images/data`
        return axios.get(url, {
            headers: {
                'Content-Type': 'applicaton/json'
            }
        })
    },

    uploadFile: ({ uploadUrl, file }) => {
        const url = uploadUrl

        return axios.put(url, file, {
            headers: {
                'Content-Type': `image/${toFileExtension(file.name)}`
            }
        })
    },

    uploadFilePDF: ({ uploadUrl, file }) => {
        const url = uploadUrl

        return axios.put(url, file, {
            headers: {
                'Content-Type': `application/pdf`
            }
        })
    },

    saveData: ({ token, imageKey, src }) => {
        const url = `${memberCall2}/tdsa/images/data`
        const data = {
            imageKey,
            src
        }
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    deleteImage: (token, key) => {
        const url = `${memberCall2}/tdsa/images/data/${key}`

        return axios.delete(url, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    createPage: (token, data) => {
        const url = `${memberCall2}/tdsa/dictionary/pages`

        return axios.post(url, data, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    editPage: (token, data) => {
        const url = `${memberCall2}/tdsa/dictionary/pages`

        return axios.patch(url, data, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    getPage: token => {
        const url = `${memberCall2}/tdsa/dictionary/pages`

        return axios.get(url, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    },

    deletePage: (token, id) => {
        const url = `${memberCall2}/tdsa/dictionary/pages/${id}`

        return axios.delete(url, {
            headers: {
                'Content-Type': 'applicaton/json',
                Authorization: token
            }
        })
    }
}

export default Service
