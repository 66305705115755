import { useState, useEffect } from "react";
import ArrowTop from "../../assets/svg/icon/back_to_top.svg";
const BackToTop = () => {
    const [scrollY, setScrollY] = useState();

    // window.addEventListener("scroll", function () {
    //     setScrollY(window.scrollY);
    // });
    useEffect(() => {
        const onScroll = () => setScrollY(window.scrollY);

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    return (
        <>
            {scrollY > 800 && (
                <div
                    className="fixed bottom-0 right-0 mr-5 md:mr-8 mb-6 cursor-pointer"
                    onClick={() =>
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        })
                    }
                >
                    <img src={ArrowTop} alt="" />
                </div>
            )}
        </>
    );
};

export default BackToTop;
