import styled from "styled-components";

export const BannerWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 650px;

    @media (min-width: 1600px) {
        height: 760px;
    }

    @media (max-width: 834px) {
        height: 370px;
    }

    @media (max-width: 428px) {
        height: 100%;
        height: 400px;
    }
`;

export const BannerImage = styled.img`
    width: 1040px;
    height: 570px;

    @media (max-width: 428px) {
        height: 260px;
    }
`;

export const HeaderWrapper = styled.div`
    padding: 80px 150px;

    @media (max-width: 834px) {
        padding: 50px 28px;
    }

    @media (max-width: 428px) {
        padding: 40px 30px;
    }
`;

export const LogoHeader = styled.img`
    width: 218px;
    height: 120px;
    @media (max-width: 428px) {
        width: 146px;
        height: 80px;
    }
`;

export const Title = styled.div`
    text-align: center;
    font-size: 48px;
    color: #384250;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 834px) {
        font-size: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media (max-width: 428px) {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 45px;
    }
`;

export const AboutDirectSelling = styled.div`
    position: relative;
    background-image: url("./img/about_direct_learnmore_2.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 428px) {
        background-image: url("./img/bg_about_direct_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 320px;
    }
`;

export const MemberTH = styled.div`
    background-image: url("./img/bg_map_th_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;

    @media (max-width: 428px) {
        background-image: url("./img/bg_map_th_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }
`;

export const HomeTitle1 = styled.div`
    display: flex;

    h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: #000000;
        margin-right: 15px;
    }

    span {
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #384250;
    }

    @media (max-width: 834px) {
        h1 {
            font-size: 20px;
            margin-right: 5px;
        }

        span {
            font-size: 20px;
        }
    }

    @media (max-width: 428px) {
        h1 {
            font-size: 17px;
            margin-right: 5px;
        }

        span {
            font-size: 17px;
        }
    }
`;

export const Content = styled.div`
    h1 {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        width: 50%;
    }
    h2 {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        margin-bottom: 30px;
        width: 50%;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #707783;
        width: 50%;
    }

    div {
        margin-top: 30px;
    }

    div > p {
        width: 100%;
    }

    @media (max-width: 834px) {
        h1 {
            width: 100%;
        }
        h2 {
            width: 100%;
        }
        p {
            width: 100%;
        }
    }

    @media (max-width: 428px) {
        h1 {
            width: 100%;
        }
        h2 {
            width: 100%;
        }
        p {
            width: 100%;
        }
    }
`;
