import { useEffect, useState } from 'react'
import AdminModal from '../adminModal'
import { Box, Stack } from '@mui/material'
import styled from 'styled-components'
import { ReactComponent as EditSvg } from '../../../assets/svg/icon/edit-regular.svg'
import { ReactComponent as DeleteSvg } from '../../../assets/svg/icon/trash-alt-regular.svg'
import { ReactComponent as SaveSvg } from '../../../assets/svg/icon/cil_save.svg'
import { ReactComponent as CloseSvg } from '../../../assets/svg/icon/carbon_close-outline.svg'
import Swal from 'sweetalert2'
import Service from 'service'
import Loading from '@Components/loading'

const Page = () => {
    const [modal, setModal] = useState(false)
    const [type, setType] = useState('')
    const [pageList, setPageList] = useState([])
    const [loading, setLoading] = useState(false)

    const token = localStorage.getItem('TDSA_TOKEN')
    const role = localStorage.getItem('TDSA_ROLE')

    useEffect(() => {
        handleGetPageList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleGetPageList = async () => {
        setLoading(true)
        try {
            let res = await Service.getPage(token)
            if (res.data.items) {
                setPageList(res.data.items)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleOpenModal = value => {
        setModal(true)
        setType(value)
    }

    return (
        <>
            <div className="mt-[80px] md:mt-[85px]">
                <div className="text-center bg-[#f5f5f5] py-10 text-[24px]">PAGE SETTING</div>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {modal && <AdminModal setModal={setModal} type={type} handleGetPageList={handleGetPageList} />}

                        <div className="container mx-auto p-7">
                            {role === 'develop' && (
                                <Stack flexDirection="row" justifyContent="start">
                                    <Box>
                                        <button className="rounded-md py-3 px-10 text-center w-full bg-gray-200 hover:bg-gray-300" type="button" onClick={() => handleOpenModal('page')}>
                                            New
                                        </button>
                                    </Box>
                                </Stack>
                            )}

                            <Stack flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center" gap={4} width="100%" mt={5}>
                                {pageList.map((item, index) => (
                                    <CartPageList key={index} items={item} token={token} handleGetPageList={handleGetPageList} />
                                ))}
                            </Stack>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

const CartPageList = ({ items, token, handleGetPageList }) => {
    const [pageValue, setPageValue] = useState(items.pageValue)
    const [pageName, setPageName] = useState(items.pageName)
    const [editMode, setEditMode] = useState(false)

    const role = localStorage.getItem('TDSA_ROLE')

    useEffect(() => {
        setPageValue(items.pageValue)
        setPageName(items.pageName)
    }, [items.pageName, items.pageValue])

    const handleEdit = async data => {
        try {
            const newData = {
                id: data.id,
                pageValue,
                pageName
            }
            const res = await Service.editPage(token, newData)
            if (res) {
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
                setTimeout(() => {
                    handleGetPageList()
                    setEditMode(false)
                }, 500)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleDelete = async data => {
        const confirmed = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete ${data.pageName}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (confirmed.isConfirmed) {
            try {
                await Service.deletePage(token, data.id)
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
                setTimeout(() => {
                    handleGetPageList()
                    setEditMode(false)
                }, 500)
            } catch (error) {
                console.error(error)
            }
        }
    }

    const BorderEditMode = {
        border: '1px solid #fcd34d',
        boxShadow: '0 1px 2px 0 rgba(252, 211, 77, 0.5)'
    }

    const BorderMode = {
        border: '1px solid #d1d5db'
    }
    return (
        <Wrapper sx={editMode ? BorderEditMode : BorderMode} borderRadius="0.5rem" p={1}>
            {editMode ? (
                <>
                    <Box sx={{ maxHeight: '12rem', minHeight: '12rem' }} className="overflow px-3">
                        <Stack mb={1}>
                            <Stack flexDirection="row" gap="15px" alignItems="center" mt={1} mb={1}>
                                <Box>Page Value</Box>
                            </Stack>

                            <CustomInput value={pageValue} onChange={e => setPageValue(e.target.value)} />
                        </Stack>

                        <Stack>
                            <Stack flexDirection="row" gap="15px" alignItems="center" mt={1} mb={1}>
                                <Box>Page Name</Box>
                            </Stack>

                            <CustomInput value={pageName} onChange={e => setPageName(e.target.value)} />
                        </Stack>
                    </Box>
                </>
            ) : (
                <Box sx={{ maxHeight: '12rem', minHeight: '12rem' }} className="overflow px-3">
                    <Stack mb={1}>
                        <Stack flexDirection="row" gap="15px" alignItems="center" mt={1} mb={1}>
                            <Box>Page Value</Box>
                        </Stack>

                        <CustomInput value={pageValue} disabled />
                    </Stack>

                    <Stack>
                        <Stack flexDirection="row" gap="15px" alignItems="center" mt={1} mb={1}>
                            <Box>Page Name</Box>
                        </Stack>

                        <CustomInput value={pageName} disabled />
                    </Stack>
                </Box>
            )}

            {role === 'develop' && (
                <div className={`flex px-3 py-5 justify-between`}>
                    {editMode ? (
                        <div className="flex items-center">
                            <button onClick={() => handleEdit(items)} className="border border-[#357d4e] hover:bg-[#357d4e] w-9 h-9 md:w-10 md:h-10 flex items-center justify-center rounded-full focus:outline-none">
                                <IconButton className="text-[#357d4e] hover:text-[white]">
                                    <SaveSvg />
                                </IconButton>
                            </button>
                        </div>
                    ) : (
                        <button onClick={() => setEditMode(true)} className="border border-[#4dc6eb] hover:bg-[#4dc6eb] w-9 h-9 md:w-10 md:h-10 flex items-center justify-center rounded-full focus:outline-none">
                            <IconButton className="text-[#4dc6eb] hover:text-[white]">
                                <EditSvg />
                            </IconButton>
                        </button>
                    )}

                    {editMode ? (
                        <button className="text-[white] hover:text-[white] hover:bg-[#eb584d] w-9 h-9 md:w-10 md:h-10 rounded-full focus:outline-none" onClick={() => setEditMode(false)}>
                            <IconButton className="text-[#eb584d] hover:text-[white]">
                                <CloseSvg />
                            </IconButton>
                        </button>
                    ) : (
                        <button className="border border-[#eb584d] text-[white] hover:text-[white] hover:bg-[#eb584d] w-9 h-9 md:w-10 md:h-10 rounded-full focus:outline-none" onClick={() => handleDelete(items)}>
                            <IconButton className="text-[#eb584d] hover:text-[white]">
                                <DeleteSvg />
                            </IconButton>
                        </button>
                    )}
                </div>
            )}
        </Wrapper>
    )
}

const Wrapper = styled(Box)`
    @media (max-width: 428px) {
        width: 100%;
    }
`

const CustomInput = styled.input`
    text-align: center;
    background-color: #f9fafb;
    border: 1px solid #d1d5db;
    color: #4b5563;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    outline: none;
    &:focus {
        border-color: #3b82f6;
    }
    display: block;
    width: 100%;
    padding: 0.625rem;
`

const IconButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

export default Page
