import { useState } from 'react'
import Service from '../../../service'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'

const ChangePassword = () => {
    const [showPassword, setShowpassword] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm()

    let navigate = useNavigate()
    let token = localStorage.getItem('TDSA_TOKEN')

    const handleChangePassword = data => {
        setIsLoading(true)
        Service.changePassword(token, data)
            .then(res => {
                setIsLoading(false)
                Swal.fire({
                    title: 'Change Password',
                    text: 'Successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                }).then(res => {
                    localStorage.removeItem('TDSA_TOKEN')
                    navigate('/login')
                })
            })
            .catch(err => {
                setIsLoading(false)
                // Swal.fire({
                //     title: "Incorrect Password",
                //     icon: "error",
                // });
                console.log(err)
            })
    }

    const handleChangeIcon = () => {
        setShowpassword(!showPassword)
    }

    return (
        <>
            <div className="mt-[80px] md:mt-[85px]">
                <div className="text-center bg-[#f5f5f5] py-10 text-[24px]">CHANGE PASSWORD</div>

                <div className="w-full flex flex-col items-center justify-center px-5 h-full top-0 mt-12">
                    <div className="border w-full md:w-[30rem] p-8 rounded-md">
                        <form onSubmit={handleSubmit(handleChangePassword)}>
                            <div>
                                <div className="text-[14px] mb-4">New Password</div>
                                <div className="relative flex items-center">
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="w-full border rounded-full py-2 px-5 focus:border-[#0da9ef] focus:outline-none"
                                        {...register('password', {
                                            required: true
                                        })}
                                    />

                                    <div className="absolute right-4">
                                        <i className={`cursor-pointer ${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}`} onClick={handleChangeIcon}></i>
                                    </div>
                                </div>
                                {errors.password?.type === 'required' && <span className="text-red-500">Password is required</span>}
                            </div>

                            <div className="flex justify-center mt-5 gap-2">
                                <div className="flex items-center justify-center bg-[#ffffff] text-[14px] w-[7rem] h-8 border text-gray-500 rounded-full cursor-pointer" onClick={() => navigate(`/admin`)}>
                                    <span>BLACK</span>
                                </div>
                                <button type="submit" className="flex items-center justify-center bg-[#0da9ef] hover:bg-[#0a6d9b] text-[14px] w-[7rem] h-8 text-white rounded-full">
                                    {isLoading && <span className="lds-dual-ring-2 mr-1"></span>}
                                    <span>SUBMIT</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChangePassword
