import React, { useState, useEffect, useRef, useContext } from 'react'
import * as Styled from './styled'
import { useLocation } from 'react-router-dom'
import ArrowBottom from '../../assets/svg/icon/arrow-bottom.svg'
import { Translate } from '../../utils/helper'
import { FilterImage } from '../../utils/helper'
import { TemplateConfigs } from 'templates/TemplateConfigs'
import { Box, ThemeProvider } from '@mui/material'
import History from './history'
import MayorMessage from '../../assets/images/MayorMessage.png'
import { LanguageContext } from '../../contexts/LanguageContext'
import Policy from './policy'
import Wfdsa from './wfdsa'
import Board from './board'
import Member from './member'

const menu = [
    {
        name: 'about_us_history',
        scroll: '#2'
    },
    {
        name: 'about_us_policy_tdsa',
        scroll: '#3'
    },
    {
        name: 'about_us_advisor_board',
        scroll: '#4'
    },
    {
        name: 'about_us_member_company',
        scroll: '#5'
    },
    {
        name: 'about_us_wfdsa',
        scroll: '#6'
    }
]

const menuMobile = [
    {
        name: 'about_us_policy_tdsa',
        scroll: '#3'
    },
    {
        name: 'about_us_advisor_board',
        scroll: '#4'
    },
    {
        name: 'about_us_member_company',
        scroll: '#5'
    },
    {
        name: 'about_us_wfdsa',
        scroll: '#6'
    }
]

const AboutUs = () => {
    const [isReadMore, setIsReadMore] = useState(false)
    const [open, setOpen] = useState(false)

    const path = useLocation()

    const aboutUs1 = useRef()
    const aboutUs2 = useRef()
    const aboutUs3 = useRef()
    const aboutUs4 = useRef()
    const aboutUs5 = useRef()
    const aboutUs6 = useRef()

    const { language } = useContext(LanguageContext)

    useEffect(() => {
        setTimeout(() => {
            if (path.hash === '#1') {
                aboutUs1.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            } else if (path.hash === '#2') {
                aboutUs2.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            } else if (path.hash === '#3') {
                aboutUs3.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            } else if (path.hash === '#4') {
                aboutUs4.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            } else if (path.hash === '#5') {
                aboutUs5.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            } else if (path.hash === '#6') {
                aboutUs6.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        }, 500)
    }, [path.hash])

    useEffect(() => {
        setOpen(false)
    }, [path])

    useEffect(() => {
        document.documentElement.style.scrollBehavior = 'smooth'
        return () => {
            document.documentElement.style.scrollBehavior = 'auto'
        }
    }, [])

    const handleArrow = () => {
        setOpen(!open)
    }

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore)
    }

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <div className="bg-[#F6F9FD]">
                <Styled.BannerWrapper>
                    <div className="hidden xl:block">
                        <div className="px-12 mb-12">
                            <div className="grid grid-cols-5 gap-4 text-[24px] text-[#384250] text-center">
                                {menu.map((item, index) => (
                                    <a href={item.scroll} className="px-5 py-2 bg-white rounded flex items-center justify-center cursor-pointer hover:bg-[#3375E5] hover:text-white" key={index}>
                                        {Translate(item.name)}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="xl:hidden container mx-auto px-2 md:px-7 mb-8 text-center text-[20px] md:text-[24px]">
                        <div className="w-full bg-[#3375E5] px-2 md:px-5 py-4 rounded shadow-2xl text-white">
                            <div className="flex justify-center relative">
                                <a href="#1" className="hover:text-white">
                                    {Translate('about_us_history')}
                                </a>
                                <div className="flex items-center absolute right-0 h-full" onClick={handleArrow}>
                                    <img src={ArrowBottom} alt="" className={`transition duration-150 ease-in-out ${open && '-rotate-180'}`} />
                                </div>
                            </div>
                        </div>
                        {open && (
                            <div className="relative z-40">
                                <div className="absolute w-full">
                                    {menuMobile.map((item, index) => (
                                        <div className="w-full bg-white px-2 md:px-5 py-4 mt-2 rounded shadow-2xl" key={index}>
                                            <a href={item.scroll} key={index}>
                                                {Translate(item.name)}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </Styled.BannerWrapper>

                <Box
                    className="bg-white relative"
                    id="1"
                    ref={aboutUs1}
                    sx={{
                        scrollMarginTop: '2rem'
                    }}>
                    <div className="container mx-auto px-7 xl:px-12 pt-[80px] lg:pt-[80px] lg:pb-[100px]">
                        <div className="relative">
                            <div className="w-full xl:w-2/4">
                                {language === 'en' && <img src={MayorMessage} alt="mayorMessage" className="mb-5" />}
                                <div className="text-[36px] mb-1">{Translate('president_tdsa')}</div>
                                <div className="text-[24px] text-[#707783] mb-7">{Translate('president_tdsa_name')}</div>
                            </div>
                            <Styled.MayerMessage2>{Translate('mayor_message_2')}</Styled.MayerMessage2>
                            <Styled.MayerMessage3>{Translate('mayor_message_3')}</Styled.MayerMessage3>
                            <div className={`${isReadMore ? '' : 'hidden'}`}>
                                <Styled.MayerMessage4>{Translate('mayor_message_4')}</Styled.MayerMessage4>
                            </div>

                            <div className="text-[#3375E5] text-[18px] mt-4 cursor-pointer" onClick={toggleReadMore}>
                                {isReadMore ? Translate('read_less') : Translate('read_more')}
                            </div>

                            <div className="hidden lg:block ">
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0
                                    }}>
                                    <img src={FilterImage('image_president_home')} alt="president" width="380px" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 xl:mt-0 lg:hidden flex justify-center">
                        <div className="container mx-auto px-7 xl:px-12">
                            <img src={FilterImage('image_president_home')} alt="president" />
                        </div>
                    </div>
                </Box>

                {/* history */}

                <Box
                    id="2"
                    ref={aboutUs2}
                    sx={{
                        scrollMarginTop: '2rem'
                    }}>
                    <History />
                </Box>

                <Box
                    id="3"
                    ref={aboutUs3}
                    sx={{
                        scrollMarginTop: '2rem'
                    }}>
                    <Policy />
                </Box>

                <Box sx={{ background: '#C7DDFB' }}>
                    <Box
                        id="4"
                        ref={aboutUs4}
                        sx={{
                            scrollMarginTop: '2rem'
                        }}>
                        <Board />
                    </Box>

                    <Box
                        className="bg-[#E5EBF1]"
                        id="5"
                        ref={aboutUs5}
                        sx={{
                            scrollMarginTop: '2rem'
                        }}>
                        <Member />
                    </Box>

                    <Box sx={{ background: '#ffffff', scrollMarginTop: '2rem' }} id="6" ref={aboutUs6}>
                        <Wfdsa />
                    </Box>
                </Box>
            </div>
        </ThemeProvider>
    )
}
export default AboutUs
