import { useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Styled from './styled'
import logoTDSA from '../../assets/svg/logo_TDSA.svg'
import ButtonFull from '../../components/common/ButtonFull'
import IconToolkit from '../../assets/svg/icon/toolkit.svg'
import IconBook from '../../assets/svg/icon_book.svg'
import IconAdmin from '../../assets/svg/icon/admin.svg'
import IconComplaint from '../../assets/svg/icon/complaint.svg'
import { FilterImage, Translate } from '../../utils/helper.js'
import TDSA_1 from '../../assets/images/TDSA_1.jpg'
import logoWFDSA from '../../assets/svg/wfdsa.svg'
import { TemplateConfigs } from 'templates/TemplateConfigs'
import { Stack, ThemeProvider, Typography } from '@mui/material'
import { useContext } from 'react'
import { LanguageContext } from 'contexts/LanguageContext'
import { DownloadPDF } from '@Components/common/DownloadPDF'

const Home = () => {
    const history = useNavigate()
    const path = useLocation()

    const tdsa1 = useRef()
    const tdsa2 = useRef()

    const { language } = useContext(LanguageContext)

    useEffect(() => {
        setTimeout(() => {
            if (path.hash === '#1') {
                tdsa1.current.scrollIntoView({ behavior: 'smooth' })
            } else if (path.hash === '#2') {
                tdsa2.current.scrollIntoView({ behavior: 'smooth' })
            }
        }, 1000)
    }, [path.hash])

    const handelLearnMore = () => {
        history('/about-direct-selling')
    }

    const Ethics_th = FilterImage('pdf_ethics_th')
    const Ethics_en = FilterImage('pdf_ethics_en')

    const direct = [
        {
            icon: IconBook,
            titleKey: 'direct_selling_ethics',
            contentKey: 'direct_selling_code_conduct_detail',
            link: language === 'th' ? Ethics_th : Ethics_en
        },
        {
            icon: IconToolkit,
            titleKey: 'ethics_toolkit',
            contentKey: 'home_ethics_toolkit_detail',
            path: '/ethicstoolkit'
        },
        {
            icon: IconAdmin,
            titleKey: 'gc_code_administrator',
            contentKey: 'home_code_administrator_detail',
            path: '/code-administrator'
        },
        {
            icon: IconComplaint,
            titleKey: 'gc_complaint',
            contentKey: 'home_complaint_detail',
            path: '/complaint'
        }
    ]

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <div className="bg-white">
                <Styled.BannerWrapper>
                    <img src={TDSA_1} alt="" width={'100%'} className="object-cover" />
                </Styled.BannerWrapper>

                <Styled.HeaderWrapper ref={tdsa1}>
                    <div className="flex justify-center">
                        <Styled.LogoHeader src={logoTDSA} alt="logo_tdsa" />
                        <Styled.LogoHeader src={logoWFDSA} alt="logoWFDSA" />
                    </div>

                    <Stack mt="30px">
                        <Stack gap={'30px'}>
                            <Typography variant="title" textAlign={'center'} fontWeight={400}>
                                {Translate('tdsa_title')}
                            </Typography>

                            <Typography variant="caption" textAlign={'left'}>
                                {Translate('tdsa_title_detail')}
                            </Typography>
                        </Stack>

                        <Stack mt={3} gap={'10px'}>
                            <Stack flexDirection={'row'}>
                                <Typography variant="caption" mr={1}>
                                    •
                                </Typography>

                                <Typography variant="caption">{Translate('tdsa_title_detail_1')}</Typography>
                            </Stack>
                            <Stack flexDirection={'row'}>
                                <Typography variant="caption" mr={1}>
                                    •
                                </Typography>

                                <Typography variant="caption">{Translate('tdsa_title_detail_2')}</Typography>
                            </Stack>
                            <Stack flexDirection={'row'}>
                                <Typography variant="caption" mr={1}>
                                    •
                                </Typography>

                                <Typography variant="caption">{Translate('tdsa_title_detail_3')}</Typography>
                            </Stack>
                            <Stack flexDirection={'row'}>
                                <Typography variant="caption" mr={1}>
                                    •
                                </Typography>

                                <Typography variant="caption">{Translate('tdsa_title_detail_4')}</Typography>
                            </Stack>
                            <Stack flexDirection={'row'}>
                                <Typography variant="caption" mr={1}>
                                    •
                                </Typography>

                                <Typography variant="caption">{Translate('tdsa_title_detail_5')}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Styled.HeaderWrapper>

                <div className="bg-[#C7DDFB] py-10 sm:py-20 ">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 xl:gap-[20px] container mx-auto px-12">
                        {direct.map((item, index) => (
                            <div key={index}>
                                <div className="bg-white rounded text-center">
                                    <div className="flex justify-center pt-6 pb-2">
                                        <img src={item.icon} alt={item.titleKey} />
                                    </div>

                                    <div className="h-[180px] flex justify-center items-center px-7">
                                        <div>
                                            <div
                                                className="mb-4 text-[24px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: Translate(item.titleKey)
                                                }}
                                            />

                                            <div className="text-[18px]">{Translate(item.contentKey)}</div>
                                        </div>
                                    </div>

                                    <div className="pb-6 pt-2">
                                        {item.link ? <ButtonFull onClick={() => DownloadPDF(item.link, 'Ethics_th')}>{Translate('download')}</ButtonFull> : <ButtonFull onClick={() => history(item.path)}>{Translate('click')}</ButtonFull>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <Styled.AboutDirectSelling>
                    <div className="bg-gradient-to-r from-[#181863] to-[#1717c7] w-full h-full opacity-90"></div>
                    <div className="absolute">
                        <div className="text-[36px] text-white mb-8">{Translate('about_direct_selling')}</div>
                        <div className="text-center">
                            <button className="bg-[#3375E5] hover:bg-[#174493] rounded-full py-2 px-6 text-[24px] text-white" onClick={handelLearnMore}>
                                {Translate('learn_more')}
                            </button>
                        </div>
                    </div>
                </Styled.AboutDirectSelling>
            </div>
        </ThemeProvider>
    )
}
export default Home
