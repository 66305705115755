import { TemplateConfigs } from 'templates/TemplateConfigs'
import { Translate } from 'utils/helper'
import { Box, ThemeProvider, Typography, Stack, Link } from '@mui/material'
import AboutUs_WFDSA from '../../assets/images/wfdsa_desktop.png'

const Wfdsa = () => {
    const Wrapper = {
        margin: '0 auto',
        padding: '80px 28px',

        '@media (max-width: 767px)': {
            padding: '40px 32px'
        }
    }

    const Title = {
        textAlign: 'center',
        marginBottom: '80px',

        '@media (max-width: 767px)': {
            marginBottom: '30px'
        }
    }

    const paddingCustom = {
        padding: '48px 64px',
        gap: '20px',
        '@media (max-width: 767px)': {
            padding: '20px 0px'
        }
    }

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <Stack className="container" sx={Wrapper}>
                <Box sx={Title}>
                    <Typography variant="title">{Translate('about_us_wfdsa_title')}</Typography>
                </Box>

                <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
                    <div>
                        <img src={AboutUs_WFDSA} alt={AboutUs_WFDSA} />
                    </div>
                    <Stack sx={paddingCustom}>
                        <Typography variant="caption">{Translate('home_wfdsa_1')}</Typography>
                        <Typography variant="caption">{Translate('home_wfdsa_2')}</Typography>
                        <Typography variant="caption">{Translate('home_wfdsa_3')}</Typography>

                        <Box>
                            <Typography variant="caption">{Translate('website')}</Typography>
                            <span className="ml-2">
                                <Link href={`https://${Translate('website_wfdsa')}`} target="_blank">
                                    <Typography variant="caption" color="#3375E5">
                                        {Translate('website_wfdsa')}
                                    </Typography>
                                </Link>
                            </span>
                        </Box>
                    </Stack>
                </div>
            </Stack>
        </ThemeProvider>
    )
}

export default Wfdsa
