import { useState } from 'react'
import ICON_PLUS from '../../assets/svg/icon/icon_plus.svg'
import ICON_MINUS from '../../assets/svg/icon/icon_minus.svg'
import { Translate } from '../../utils/helper'
import { Stack, ThemeProvider, Typography } from '@mui/material'
import { TemplateConfigs } from 'templates/TemplateConfigs'

const Answer = () => {
    const [data, setData] = useState([
        {
            id: 1,
            isShow: false,
            title: 'faqs_ask_1',
            content: 'faqs_answer_1',
            type: 1
        },
        {
            id: 2,
            isShow: false,
            title: 'faqs_ask_2',
            content: 'faqs_answer_2',
            type: 1
        },
        {
            id: 3,
            isShow: false,
            title: 'faqs_ask_3',
            subTitle: 'faqs_answer_3_title',
            listIcon: ['faqs_answer_3_1', 'faqs_answer_3_2', 'faqs_answer_3_3', 'faqs_answer_3_4', 'faqs_answer_3_5', 'faqs_answer_3_6', 'faqs_answer_3_7', 'faqs_answer_3_8'],
            type: 2
        },
        {
            id: 4,
            isShow: false,
            title: 'faqs_ask_4',
            content: 'faqs_answer_4',
            type: 1
        },
        {
            id: 5,
            isShow: false,
            title: 'faqs_ask_5',
            content: 'faqs_answer_5',
            type: 1
        },
        {
            id: 6,
            isShow: false,
            title: 'faqs_ask_6',
            content: 'faqs_answer_6',
            type: 1
        },
        {
            id: 7,
            isShow: false,
            title: 'faqs_ask_7',
            content: 'faqs_answer_7',
            type: 1
        },
        {
            id: 8,
            isShow: false,
            title: 'faqs_ask_8',
            content: 'faqs_answer_8',
            type: 1
        },
        {
            id: 9,
            isShow: false,
            title: 'faqs_ask_9',
            list: ['faqs_answer_9_1', 'faqs_answer_9_2', 'faqs_answer_9_3', 'faqs_answer_9_4'],
            type: 3
        },
        {
            id: 10,
            isShow: false,
            title: 'faqs_ask_10',
            content: 'faqs_answer_10',
            type: 1
        },
        {
            id: 11,
            isShow: false,
            title: 'faqs_ask_11',
            list: ['faqs_answer_11_1', 'faqs_answer_11_2'],
            type: 3
        }
    ])

    const handleIsShow = id => {
        let updatedIsShow = data.map(item => {
            if (item.id === id) {
                item.isShow = !item.isShow
            }
            return item
        })
        setData(updatedIsShow)
    }

    return (
        <ThemeProvider theme={TemplateConfigs}>
            <div className="bg-white">
                <div className="md:container md:mx-auto md:px-7 pb-10 md:pb-20">
                    <Stack mb="40px">
                        <Typography variant="title" textAlign="center" mb="20px">
                            {Translate('ethics_toolkit_menu_6')}
                        </Typography>

                        <Typography variant="caption" textAlign="center">
                            {Translate('faqs_title_detail')}
                        </Typography>
                    </Stack>

                    <div>
                        {data.map((item, index) => (
                            <div key={index}>
                                <div className="bg-[#E8F0FE] p-6 md:p-7 mb-4 md:rounded ">
                                    <div className="flex justify-between">
                                        <div className="text-[24px] w-[90%]">
                                            <Stack flexDirection={'row'} key={index} gap="3px">
                                                <span>{index + 1}.</span>
                                                <span>{Translate(item.title)}</span>
                                            </Stack>
                                        </div>
                                        <div onClick={() => handleIsShow(item.id)} className="cursor-pointer pt-2 md:pt-0">
                                            {item.isShow ? <img src={ICON_MINUS} alt="" className="w-6" /> : <img src={ICON_PLUS} alt="" className="w-6" />}
                                        </div>
                                    </div>
                                    <div>
                                        {item.isShow && (
                                            <div className="bg-white p-4 md:p-5 text-[24px] rounded mt-6 md:mt-7">
                                                {item.type === 1 ? (
                                                    <div>{Translate(item.content)}</div>
                                                ) : item.type === 2 ? (
                                                    <div>
                                                        <div>{Translate(item.subTitle)}</div>
                                                        {item.listIcon.map((data, index) => (
                                                            <div key={index} className="flex pl-3">
                                                                <div>•</div>
                                                                <div className="ml-2">{Translate(data)}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {item.list.map((data, index) => (
                                                            <div key={index} className="flex pl-3">
                                                                <div>{index + 1}.</div>
                                                                <div className="ml-2">{Translate(data)}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}
export default Answer
