import styled from "styled-components";

export const BannerWrapper = styled.div`
    background-image: url("./img/ethice_toolkit_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media (min-width: 1600px) {
        height: 760px;
    }

    @media (max-width: 834px) {
        background-image: url("./img/ethice_toolkit_desktop.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 370px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/ethice_toolkit_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 400px;
    }
`;

export const BackgroundWrapper = styled.div`
    background-image: url("./img/ethice_toolkit_2_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 458px;
    @media (min-width: 1600px) {
        height: 760px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/ethice_toolkit_2_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 254px;
    }
`;

export const BackgroundWrapperTwo = styled.div`
    background-image: url("./img/ethice_toolkit_3_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 548px;
    display: flex;
    align-items: center;

    @media (min-width: 1600px) {
        height: 760px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/ethice_toolkit_3_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 609px;
    }
`;

export const BannerWrapperSkeleton = styled.div`
    width: 100%;
    height: 650px;
    background-color: rgb(203 213 225);
    background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 60%
        )
        rgb(203 213 225);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1.5s loading ease-in-out infinite;

    @keyframes loading {
        to {
            background-position-x: -20%;
        }
    }

    @media (min-width: 1600px) {
        width: 100%;
        height: 760px;
        background-color: rgb(203 213 225);
        background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%
            )
            rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }

    @media (max-width: 428px) {
        width: 100%;
        height: 400px;
        background-color: rgb(203 213 225);
        background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%
            )
            rgb(203 213 225);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1.5s loading ease-in-out infinite;
    }
`;

export const Title1 = styled.div`
    h1 {
        font-size: 36px;
        margin-bottom: 10px;
    }

    p {
        font-size: 24px;
        color: #384250;
    }
`;

export const Content1 = styled.div`
    div {
        margin-bottom: 30px;
    }

    h1 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        font-size: 18px;
        color: #384250;
    }
`;

export const Title3 = styled.div`
    h1 {
        font-size: 36px;
        margin-bottom: 40px;
        color: #000000;
        text-align: center;
    }

    div {
        margin-bottom: 20px;
    }

    p {
        font-size: 18px;
        color: #384250;
    }

    li {
        font-size: 18px;
        color: #384250;
        padding-left: 20px;
        list-style: none;
    }
`;

export const Title4 = styled.div`
    h1 {
        font-size: 36px;
        margin-bottom: 40px;
        color: #000000;
        text-align: center;
    }

    div {
        margin-bottom: 20px;
    }

    p {
        font-size: 18px;
        color: #384250;
    }
`;

export const Title5 = styled.div`
    h1 {
        font-size: 42px;
        margin-bottom: 20px;
        color: #000000;
        text-align: center;
        font-weight: 500;
    }

    h2 {
        font-size: 24px;
        margin-bottom: 40px;
        color: #707783;
        text-align: center;
    }

    div {
        margin-bottom: 20px;
    }

    p {
        font-size: 18px;
        color: #384250;
    }

    li {
        font-size: 18px;
        color: #384250;
        padding-left: 20px;
        list-style: none;
    }
`;

export const Title7 = styled.div`
    h1 {
        font-size: 36px;
        margin-bottom: 20px;
        color: #000000;
    }

    h2 {
        font-size: 24px;
        margin-bottom: 40px;
        color: #384250;
    }

    p {
        font-size: 24px;
        color: #384250;
        margin-bottom: 20px;
    }

    div {
        font-size: 36px;
        margin-bottom: 60px;
    }
`;

export const Title8 = styled.div`
    h1 {
        font-size: 24px;
        margin-bottom: 30px;
    }

    p {
        font-size: 18px;
        margin-bottom: 20px;
    }
`;

export const Answer2 = styled.div`
    font-size: 18px;
    display: flex;
    h1 {
        font-weight: 500;
    }
`;

export const BackgroundWrapper3 = styled.div`
    background-image: url("./img/direct_selling_5_desktop.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 1270px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1600px) {
        height: 1380px;
    }

    @media (max-width: 834px) {
        height: 1700px;
    }

    @media (max-width: 428px) {
        background-image: url("./img/direct_selling_5_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 2573px;
    }
`;
