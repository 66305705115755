import * as Styled from './LayoutStyled.js'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './styles.css'
import logoTDSA from '../../assets/svg/tdsa_logo_2.svg'
import logoTH from '../../assets/svg/logo_th.svg'
import logoUSA from '../../assets/svg/logo_usa.svg'
import Burger from '../../assets/svg/burger.svg'

import { Translate } from '../../utils/helper.js'

const listMenu = [
    {
        key: 'menu_about_us',
        path: '/'
    },
    {
        key: 'menu_direct_selling',
        path: '/direct-selling'
    },
    {
        key: 'menu_tdsa',
        path: '/global-code-of-ethic'
    },
    {
        key: 'menu_question',
        path: '/member'
    }
]

const Header = ({ language, setLanguage, collapsed, toggle }) => {
    let navigate = useNavigate()
    const history = useLocation()

    return (
        <>
            <Styled.HeaderWrapper collapsed={collapsed.toString()}>
                <div className="md:hidden" onClick={toggle}>
                    <img src={Burger} alt="burger" />
                </div>

                <div className="flex items-center gap-7">
                    <Styled.ImageLogo src={logoTDSA} alt="Logo TDSA footer" className="cursor-pointer" onClick={() => navigate('/')} />

                    <div className="hidden xl:block">
                        <div>สมาคมการขายตรงไทย</div>
                        <div>Thai Direct Selling Association</div>
                    </div>
                </div>
                <div className="flex items-center">
                    <span className="hidden md:block">
                        {listMenu.map((item, index) => (
                            <Link
                                key={index}
                                to={item.path}
                                className={`mr-[10px] xl:mr-[25px] ${history.pathname === item.path ? 'text-[#3375E5]' : ''}
                                               
                                            `}>
                                {Translate(item.key)}
                            </Link>
                        ))}
                    </span>

                    <div className="flex items-center ">
                        <Styled.LogoLanguage src={language === 'th' ? logoTH : logoUSA} alt="Logo TH" />
                        <select className="focus:outline-none hidden md:block select-bg cursor-pointer" defaultValue={language} onChange={e => setLanguage(e.target.value)}>
                            <option value="th">ภาษาไทย</option>
                            <option value="en">English</option>
                        </select>
                    </div>
                </div>
            </Styled.HeaderWrapper>
        </>
    )
}

export default Header
