import ReactGA from "react-ga";

const trackingID = "UA-236136322-1";

export const Tracking = () => {
    ReactGA.initialize(trackingID);
    ReactGA.pageview(window.location.pathname + window.location.search);
};

export const TrackingEvent = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};
