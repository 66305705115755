import * as Styled from './styled'
import { useContext } from 'react'
import Icon_1 from '../../assets/svg/icon/icon_1.svg'
import ButtonFull from '../../components/common/ButtonFull'
import IconPhoneGreen from '../../assets/svg/icon/icon_phone_green.svg'
import IconFaxBlue from '../../assets/svg/icon/icon_fax_blue.svg'
import IconMailOrange from '../../assets/svg/icon/icon_mail_orange.svg'
import { Translate, FilterImage } from '../../utils/helper'
import { LanguageContext } from '../../contexts/LanguageContext'

const CodeAdministrator = () => {
    const contact = [
        {
            icon: IconPhoneGreen,
            name: Translate('phone'),
            detail: Translate('tdsa_phone')
        },
        {
            icon: IconFaxBlue,
            name: Translate('fax'),
            detail: Translate('tdsa_fax')
        },
        {
            icon: IconMailOrange,
            name: Translate('email'),
            detail: Translate('code_admin_email')
        }
    ]

    const { language } = useContext(LanguageContext)
    return (
        <div className="bg-white">
            <Styled.BannerWrapper />

            <div className="container mx-auto px-7 py-10 md:py-20 text-center">
                <div className="flex justify-center mb-6">
                    <img src={Icon_1} alt={Icon_1} />
                </div>
                <div className="text-[48px] mb-4 sm:mb-6 px-10 font-medium">{Translate('code_administrator_title')}</div>
                <div className="text-[32px] text-[#3375E5] mb-4 sm:mb-6">{Translate('name_code_administrator')}</div>

                <a href={language === 'th' ? FilterImage('pdf_admin_profile_th') : FilterImage('pdf_admin_profile_en')} alt="admin profile" target="_blank" rel="noreferrer">
                    <ButtonFull>{Translate('click_view_profile')}</ButtonFull>
                </a>

                <div className="text-[18px] text-[#384250] mt-12">
                    <div>{Translate('code_admin_executive')}</div>
                    <div>{Translate('tdsa_address_2')}</div>
                </div>
            </div>
            <div className="bg-[#C7DDFB] py-10 md:py-20 px-12">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {contact.map((item, index) => (
                            <div className="bg-white py-8 px-5 rounded text-center" key={index}>
                                <div className="flex justify-center mb-4">
                                    <img src={item.icon} alt={item.icon} />
                                </div>
                                <div className="text-[24px] text-[#707783] mb-4">{item.name}</div>
                                <div className="text-[24px] md:text-[18px] xl:text-[24px] text-[#384250] break-words">{item.detail}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CodeAdministrator
