import React from 'react'
import * as Styled from './styled'
import { Translate } from '../../utils/helper'

const data = [
    {
        period: 'former_period_1',
        name: 'former_name_1',
        company: 'former_company_1'
    },
    {
        period: 'former_period_2',
        name: 'former_name_2',
        company: 'former_company_2'
    },
    {
        period: 'former_period_3',
        name: 'former_name_3',
        company: 'former_company_3'
    },
    {
        period: 'former_period_4',
        name: 'former_name_4',
        company: 'former_company_4'
    },
    {
        period: 'former_period_5',
        name: 'former_name_5',
        company: 'former_company_5'
    },
    {
        period: 'former_period_6',
        name: 'former_name_6',
        company: 'former_company_6'
    },
    {
        period: 'former_period_7',
        name: 'former_name_7',
        company: 'former_company_7'
    },
    {
        period: 'former_period_8',
        name: 'former_name_8',
        company: 'former_company_8'
    },
    {
        period: 'former_period_9',
        name: 'former_name_9',
        company: 'former_company_9'
    },
    {
        period: 'former_period_10',
        name: 'former_name_10',
        company: 'former_company_10'
    },
    {
        period: 'former_period_11',
        name: 'former_name_11',
        company: 'former_company_11'
    },
    {
        period: 'former_period_12',
        name: 'former_name_12',
        company: 'former_company_12'
    },
    {
        period: 'former_period_13',
        name: 'former_name_13',
        company: 'former_company_13'
    },
    {
        period: 'former_period_14',
        name: 'former_name_14',
        company: 'former_company_14'
    },
    {
        period: 'former_period_15',
        name: 'former_name_15',
        company: 'former_company_15'
    },
    {
        period: 'former_period_16',
        name: 'former_name_16',
        company: 'former_company_16'
    },
    {
        period: 'former_period_17',
        name: 'former_name_17',
        company: 'former_company_17'
    },
    {
        period: 'former_period_18',
        name: 'former_name_18',
        company: 'former_company_18'
    },
    {
        period: 'former_period_19',
        name: 'former_name_19',
        company: 'former_company_19'
    },
    {
        period: 'former_period_20',
        name: 'former_name_20',
        company: 'former_company_20'
    },
    {
        period: 'former_period_21',
        name: 'former_name_21',
        company: 'former_company_21'
    },
    {
        period: 'former_period_22',
        name: 'former_name_22',
        company: 'former_company_22'
    },
    {
        period: 'former_period_23',
        name: 'former_name_23',
        company: 'former_company_23'
    },
    {
        period: 'former_period_24',
        name: 'former_name_24',
        company: 'former_company_24'
    }
]

const Leadership = () => {
    return (
        <div className="bg-white">
            <Styled.BannerWrapper />

            <div className="container mx-auto px-5 md:px-7 py-10 md:py-20">
                <div className="text-center">
                    <div className="text-[48px] font-medium">{Translate('president_association')}</div>
                </div>

                <div className="pt-10 md:pt-20">
                    <div className="hidden md:block ">
                        <div className="grid grid-cols-4 gap-6 md:gap-2 xl:gap-6 text-center text-[24px] mb-7">
                            <div className="col-span-1 bg-[#97BFF6] py-4 rounded">{Translate('period')}</div>
                            <div className="col-span-1 bg-[#97BFF6] py-4 rounded">{Translate('name_president_association')}</div>
                            <div className="col-span-2 bg-[#97BFF6] py-4 rounded">{Translate('leadership_company')}</div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-3">
                        {data.map((item, index) => (
                            <div className="bg-[#E8F0FE] grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-2 xl:gap-4 md:text-center text-[18px] px-3 py-4 rounded" key={index}>
                                <div className="md:col-span-1">
                                    <span className="text-[#384250]">{Translate(item.period)}</span>
                                </div>
                                <div className="md:col-span-1">
                                    <span className="text-[#384250]">{Translate(item.name)}</span>
                                </div>
                                <div className="md:col-span-2">
                                    <span className="text-[#384250]">{Translate(item.company)}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Leadership
